import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordThunk } from '../../features/user/userSlice';
import { useParams, useNavigate } from 'react-router-dom';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import Account_bg from '../../assets/Images/Account/Account_bg.png';
import guidee_white_logo from '../../assets/Images/Account/guidee logo white.png';
import guidee_register from '../../assets/Images/Account/guidee logo.png';
import mobileBackground from '../../assets/Images/Account/mobile_bg.png';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams(); // Obtenez le jeton des paramètres URL
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const userStatus = useSelector(state => state.user.status);
  const errorMessage = useSelector(state => state.user.error);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(resetPasswordThunk({ token, password, confirmPassword }))
      .then(() => {
        navigate('/login'); // Rediriger vers la page de connexion après une réinitialisation réussie
      });
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {isMobile ? (
        <div className="min-h-screen flex flex-col items-center justify-center bg-cover bg-center" 
          style={{ backgroundImage: `url(${mobileBackground})` }}>
          <div className="pb-20">
            <img src={guidee_register} alt="Guidee Registration Logo"></img>
          </div>
          <form className="bg-gray-700/40 backdrop-blur-[2px] rounded-t-[50px] px-16 pt-6 pb-8 mb-4" onSubmit={handleSubmit}>
            <p className="flex justify-center text-2xl font-bold pb-6 text-white text-center">Réinitialiser Mot De Passe</p>
            <div className="mb-4">
              <label className="text-xs text-white">Nouveau mot de passe</label>
              <div className="relative">
                <input
                  type={passwordVisible ? "text" : "password"}
                  id="password"
                  name="password"
                  className="shadow appearance-none border rounded w-full h-9 py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-700 text-xs"
                  required
                  placeholder="********"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button type="button" onClick={togglePasswordVisibility} className="absolute inset-y-0 right-[5px] flex items-center ">
                  {passwordVisible ? <EyeOffIcon className="h-5 w-5 text-gray-400" /> : <EyeIcon className="h-5 w-5 text-gray-400" />}
                </button>
              </div>
            </div>

            <div className="mb-4">
              <label className="text-xs text-white">Confirmer mot de passe</label>
              <div className="relative">
                <input
                  type={passwordVisible ? "text" : "password"}
                  id="confirmPassword"
                  name="confirmPassword"
                  className="shadow appearance-none border rounded w-full h-9 py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-700 text-xs"
                  required
                  placeholder="********"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button type="button" onClick={togglePasswordVisibility} className="absolute inset-y-0 right-[5px] flex items-center ">
                  {passwordVisible ? <EyeOffIcon className="h-5 w-5 text-gray-400" /> : <EyeIcon className="h-5 w-5 text-gray-400" />}
                </button>
              </div>
            </div>
            <div className="flex items-center">
              <button className="bg-[rgb(49,77,179)] w-full text-xs text-white font-semibold py-3 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                Envoyer
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="flex justify-center items-center h-screen">
          <div className="w-1/2 bg-white justify-center ml-20 pl-20">
            <img src={guidee_white_logo} alt="Guidee Logo" className="pb-12 pt-20 w-auto"></img>
            <h2 className="text-4xl font-bold pb-8" style={{ fontFamily: 'Poppins, sans-serif' }}>Réinitialiser Mot De Passe</h2>
            <p className="text-base pb-12 text-[rgb(67,97,67)]" style={{ fontFamily: 'Poppins, sans-serif' }}>
              Entrez votre nouveau mot de passe.
            </p>
            <form onSubmit={handleSubmit} className="w-full max-w-md">
              <div className="flex flex-wrap -mx-3 mb-8">
                <div className="w-full px-3">
                  <div className="relative">
                    <input type={passwordVisible ? "text" : "password"} id="password" name="password" className="block w-[500px] px-3 py-[14px] border border-gray-700 rounded-[4px] focus:border-blue-500" required placeholder="********"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button type="button" onClick={togglePasswordVisibility} className="absolute inset-y-0 right-[-40px] flex items-center ">
                      {passwordVisible ? <EyeOffIcon className="h-5 w-5 text-gray-400" /> : <EyeIcon className="h-5 w-5 text-gray-400" />}
                    </button>
                    <span className="absolute top-[-12px] left-2 bg-white px-1 text-base text-[rgb(28,27,31)] font-normal">Nouveau mot de passe</span>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-8">
                <div className="w-full px-3">
                  <div className="relative">
                    <input type={passwordVisible ? "text" : "password"} id="confirmPassword" name="confirmPassword" className="block w-[500px] px-3 py-[14px] border border-gray-700 rounded-[4px] focus:border-blue-500" required placeholder="********"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <button type="button" onClick={togglePasswordVisibility} className="absolute inset-y-0 right-[-40px] flex items-center ">
                      {passwordVisible ? <EyeOffIcon className="h-5 w-5 text-gray-400" /> : <EyeIcon className="h-5 w-5 text-gray-400" />}
                    </button>
                    <span className="absolute top-[-12px] left-2 bg-white px-1 text-base text-[rgb(28,27,31)] font-normal">Confirmer mot de passe</span>
                  </div>
                </div>
              </div>
              <button type="submit" className="block w-[500px] bg-[rgb(0,35,161)] text-white px-4 py-3 rounded-[4px] mb-12 font-normal">Envoyer</button>
            </form>
          </div>
          <div className="w-1/2">
            <img src={Account_bg} alt="Background" className="w-full h-auto" />
          </div>
        </div>
      )}
    </>
  );
};

export default ResetPassword;
