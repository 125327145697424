import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

//signup
export const signupUser = createAsyncThunk(
  'user/signupUser',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post('http://localhost:8000/api/signup/user', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Success:', response.data); // Log successful response data
      return response.data;
    } catch (error) {
      console.log('Error:', error.response.data); // Log error response data
      return rejectWithValue(error.response.data);
    }
  }
);
// Activate account
export const activateAccount = createAsyncThunk(
  'user/activateAccount',
  async ({ email, activationCode }, { rejectWithValue }) => {
    try {
      const response = await axios.post('http://localhost:8000/api/activate-account/user', { email, activationCode });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Resend activation code
export const resendActivationCode = createAsyncThunk(
  'user/resendActivationCode',
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await axios.post('http://localhost:8000/api/resend-activation-code/user', { email });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//login
export const loginUser = createAsyncThunk(
  'user/login',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post('http://localhost:8000/api/login/user', { email, password });

      if (response.data && response.data.token && response.data.refreshToken && response.data.user) {
        const { token, refreshToken, user } = response.data;

        // Store user ID, auth token, refresh token, and user role in local storage
        localStorage.setItem('authToken', token);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('userRole', user.role);
        localStorage.setItem('userId', user._id);

        return { user, token, refreshToken };
      } else {
        return rejectWithValue(response.data || { message: 'Invalid response from server' });
      }
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message || 'An error occurred';
        return rejectWithValue({ message, status: error.response.status });
      } else {
        return rejectWithValue({ message: 'An error occurred' });
      }
    }
  }
);

//Profile
export const fetchUserProfile = createAsyncThunk(
  'user/fetchUserProfile',
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.get('http://localhost:8000/api/account/user', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


//Update profile
export const updateUserProfile = createAsyncThunk('user/updateUserProfile', async (updates, { getState, rejectWithValue }) => {
  try {
    const token = localStorage.getItem('authToken');
    const userId = getState().user.userInfo._id; // Suppose that user ID is stored in userInfo
    const response = await axios.put(`http://localhost:8000/api/update/user/${userId}`, updates, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});


// Refresh token
export const refreshToken = createAsyncThunk(
  'user/refreshToken',
  async (_, { rejectWithValue }) => {
    try {
      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken) {
        throw new Error('No refresh token available');
      }
      const response = await axios.post('http://localhost:8000/api/refresh-token', { token: refreshToken });
      const { accessToken } = response.data;

      localStorage.setItem('authToken', accessToken);

      return accessToken;
    } catch (error) {
      if (error.response && (error.response.status === 403 || error.response.status === 401)) {
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('userRole');
        localStorage.removeItem('userId');

        alert('Votre session a expiré. Veuillez vous reconnecter.');
        window.location.href = '/login'; 
      }
      return rejectWithValue(error.response ? error.response.data : { message: 'An error occurred' });
    }
  }
);

//logout
export const logoutUser = createAsyncThunk(
  'user/logout',
  async (_, { rejectWithValue }) => {
    const refreshToken = localStorage.getItem('refreshToken');
    try {
      await axios.post('http://localhost:8000/api/logout', { token: refreshToken });
    } catch (error) {
      return rejectWithValue(error.response.data);
    } finally {
      // Supprimer les tokens, userId et le userRole du localStorage même en cas d'erreur
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('userRole');
      localStorage.removeItem('userId'); 
    }
    return true;
  }
);
//Forget password
export const forgetPasswordThunk = createAsyncThunk(
  'user/forgetPassword',
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await axios.post('http://localhost:8000/api/forget-password', { email });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
//reset password
export const resetPasswordThunk = createAsyncThunk(
  'user/resetPassword',
  async ({ token, password, confirmPassword }, { rejectWithValue }) => {
    try {
      const response = await axios.post('http://localhost:8000/api/reset-password', {
        token,
        password,
        confirmPassword
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);



const userSlice = createSlice({
  name: 'user',
  initialState: {
    userInfo: null,
    token: localStorage.getItem('authToken') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    status: 'idle',
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.refreshToken = null;
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
    },
  },

  extraReducers: (builder) => {
    
    //SignUP 
    builder.addCase(signupUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(signupUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userInfo = action.payload.user;
        console.log('Signup Succeeded:', action.payload); 
        if (action.payload && action.payload.user && action.payload.user.role) {
          localStorage.setItem('userRole', action.payload.user.role);
        }
      })
      .addCase(signupUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload ? action.payload.message : 'An unknown error occurred';
      });

    //Activate Account
    builder
    .addCase(activateAccount.fulfilled, (state, action) => {
      state.user = action.payload;
      state.status = 'succeeded';
    })
    .addCase(activateAccount.rejected, (state, action) => {
      state.error = action.payload;
      state.status = 'failed';
    })
    .addCase(resendActivationCode.fulfilled, (state, action) => {
      state.status = 'succeeded';
    })
    .addCase(resendActivationCode.rejected, (state, action) => {
      state.error = action.payload;
      state.status = 'failed';
    });

     //Login
     builder.addCase(loginUser.fulfilled, (state, action) =>{
      state.status = 'login';
      state.userInfo = action.payload.user;
      console.log('login succeeded:', action.payload);
    })
    .addCase(loginUser.rejected, (state, action) => {
      state.status = 'login failed';
      state.error = action.payload ? action.payload.message : 'An unknown error occurred';
    });

     //Profile
     builder
      .addCase(fetchUserProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userInfo = action.payload;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });

      //update profile
      builder
      .addCase(updateUserProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.userInfo = action.payload;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload ? action.payload.message : 'An unknown error occurred';
      });

    builder
     //refresh token
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.token = action.payload;
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.status = 'refreshToken failed';
        state.error = action.payload;
      });

     //logout
     builder
      .addCase(logoutUser.fulfilled, (state) => {
        state.user = null;
        state.token = null;
        state.refreshToken = null;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.status = 'logout failed';
        state.error = action.payload;
      });

     //Forget Password
     builder.addCase(forgetPasswordThunk.fulfilled, (state, action) => {
      state.status = 'ForgetPassword';
      console.log('email sended:', action.payload);
    })
    .addCase(forgetPasswordThunk.rejected, (state, action) => {
      state.status = 'send password failed';
      state.error = action.payload ? action.payload.message : 'An unknown error occurred';
    })

    // Reset Password
    .addCase(resetPasswordThunk.pending, (state) => {
      state.status = 'resetting';
    })
    builder.addCase(resetPasswordThunk.fulfilled, (state, action) => {
      state.status = 'reset_success';
      console.log('Password reset succeeded:', action.payload);
    })
    .addCase(resetPasswordThunk.rejected, (state, action) => {
      state.status = 'reset_failed';
      state.error = action.payload ? action.payload.message : 'An unknown error occurred during password reset';
    });
  }
});

export const { logout } = userSlice.actions;
export default userSlice.reducer;
