import React from 'react';
import Nav from '../layout/Nav';
import Profile from '../common/ProfilePage/Profile';
import Subscribe from '../layout/Subscribe';
import Footer from '../layout/Footer';
function Home() {   
    return (
      <div>
       <Nav/>
       <Profile/>
       <Subscribe/>
       <Footer/>
      </div>
    );
}

export default Home;