import React from 'react';
import './utils/Auth'
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'tailwindcss/tailwind.css';
import { store } from './store';

import Home from './components/pages/HomePage';
import Tour from './components/pages/TourPage';
import Destination from './components/pages/DestinationPage';
import TourDetailsPage from './components/pages/TourDetailsPage';

import PersonalizedTour from './components/pages/PersonalizedTourPage';
import TourChoicesPage from './components/pages/PersonalizedTourChoicesPage';
import ChoiceDetails from './components/pages/PersonalizedTourChoiceDetailsPage';
import PersonalizedTourSubCategory from './components/pages/PersonalizedTourSubCategoryPage';
import SubCategoryChoices from './components/pages/PersonalizedTourSubCategoryChoicesPage';
import SubCategoryChoiceDetails from './components/pages/PersonalizedTourSubCategoryChoiceDetailsPage';

import ContactezNous from './components/pages/ContactUsPage';
import CreateAccount from './components/pages/CreateAccountPage';
import Login from './components/pages/LoginPage';
import ForgetPassword from './components/pages/ForgetPasswordPage';
import ActivateAccount from './components/pages/ActivateAccountPage';
import ResetPassword from './components/pages/ResetPasswordPage';
import Profile from './components/pages/ProfilePage';

const container = document.getElementById('root');
const root = createRoot(container); 

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <React.StrictMode>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/Accueil" element={<Home />} />
          <Route path="/Tour" element={<Tour />} />
          <Route path="/TourDetailsPage" element={<TourDetailsPage />} />
          <Route path="/Destination" element={<Destination />} />

          <Route path="/activité" element={<PersonalizedTour />} />
          <Route path="/PersonalizedTourChoices/:id" element={<TourChoicesPage />} />
          <Route path="/ChoiceDetails/:id" element={<ChoiceDetails />} />
          <Route path="/PersonalizedTourSubCategory/:tourId" element={<PersonalizedTourSubCategory />} />
          <Route path="/PersonalizedTourChoices/:tourId/:subCategoryId" element={<SubCategoryChoices />} />
          <Route path="/PersonalizedTourSubCategoryChoiceDetails/:tourId/:subCategoryId/:choiceId" element={<SubCategoryChoiceDetails />} />


          <Route path="/CreateAccount" element={<CreateAccount />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/ForgetPassword" element={<ForgetPassword />} />
          <Route path="/ActivateAccount" element={<ActivateAccount />} />
          <Route path="/ResetPassword/:token" element={<ResetPassword />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/ContactezNous" element={<ContactezNous />} />
        </Routes>
      </React.StrictMode>
    </BrowserRouter>
  </Provider>
);

reportWebVitals();
