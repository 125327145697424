import React from 'react';
import Custom from '../../../assets/Images/Profile/CustomReservation.png';
import location from '../../../assets/Images/Profile/Location.png'
const CustomTours = () => {
    return (
        <div className="flex flex-row bg-white p-2 m-3 rounded-lg shadow-lg space-x-3  ">
          <img
            src={Custom}
            alt="Karting Hergla Park"
            className="lg:w-45 lg:h-32 w-20 h-[120px] rounded-lg object-cover "
          />
          <div className="flex-1">
            <h3 className="lg:text-2xl font-bold text-black text-sm">Karting Hergla Park</h3>
            <div className="flex items-center mt-2">
                <img src={location} className="mr-1 w-2 h-2"></img>
              <p className="text-[rgb(45,169,197)] lg:text-xs text-[10px]">Sousse</p></div>
              <div>
                <p className="text-gray-500 text-[11px] mt-2">Date</p>
                <p className="font-semibold text-[10px]">Lundi, 22 Avril, 2024</p>
              </div>
            <div className="flex space-x-8 mt-2">
              <div>
                <p className="text-gray-500 text-[11px]">Nombre de personne</p>
                <p className="font-semibold text-[10px]">2 Adultes | 2 Enfants</p>
              </div>
              <div>
                <p className="text-gray-500 text-[11px]">Prix</p>
                <p className="font-semibold  text-[10px]">35 DT</p>
              </div>
            </div>
          </div>
          <div >
          <button className=" mt-12 bg-[rgb(49,77,179)] text-white font-semibold px-4 py-2 rounded text-[8px]">
            Supprimer
          </button>
        </div>
        </div>
      );
};

export default CustomTours;
