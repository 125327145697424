import React from 'react';
import Header from '../layout/Header';
import Details from '../common/PersonalizedTour/PersonalizedTourChoiceDetailsPage/Details';


function ChoiceDetails() {   
  
    return (
      <div>
       <Header page="activité" />
       <Details/>
       
      </div>
    );
}

export default ChoiceDetails;