import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import PopularCitiesBG from '../../../assets/Images/Home/PopularCitiesBG.png';
import PopularCitiesBGweb from '../../../assets/Images/Destination/popularCitiesBGWeb.png';
import searchicon from '../../../assets/Images/Home/li_search.png';

// Array of button data with labels
const buttonData = [
  { id: 'button-1', label: 'TOUT' },
  { id: 'button-2', label: 'BEST-SELLER' },
  { id: 'button-3', label: 'NATURE' },
  { id: 'button-6', label: 'CITE' },
  { id: 'button-4', label: 'SAISONNIAIRE' },
];

const MainContentPC = () => {
  // State variables
  const [showGradient, setShowGradient] = useState(window.innerWidth < 1024);
  const [bgStyle, setBgStyle] = useState();
  const containerRef = useRef(null);
  const [maxDragX, setMaxDragX] = useState(0);
  const widthOfOneItem = 100; 

  // Function to check if the screen is large
  const isLargeScreen = () => window.innerWidth >= 1024;
  
  // Effect hook to update background style based on screen size
  useEffect(() => {
    const updateBackgroundStyle = () => {
      if (isLargeScreen()) {
        setShowGradient(true);
        setBgStyle({
          backgroundImage: `url(${PopularCitiesBGweb})`,
          backgroundSize: 'cover', 
          backgroundPosition: 'cover',
          backgroundRepeat: 'no-repeat',
          height: '80vh'          
        });
      } else {
        setShowGradient(true);
        setBgStyle({
          backgroundImage: `url(${PopularCitiesBG})`,
          backgroundSize: '120% auto',
          backgroundPosition: 'top left',
          height: '40vh',
        });
      }
    };

    window.addEventListener('resize', updateBackgroundStyle);
    updateBackgroundStyle(); 

    return () => window.removeEventListener('resize', updateBackgroundStyle);
  }, []);
  // Effect hook to calculate max drag distance for button container
  useEffect(() => {
    const calculateMaxDrag = () => {
      const buttonMarginRight = 16; 
      const containerPadding = 90; 
      const totalButtonsWidth = buttonData.length * (widthOfOneItem + buttonMarginRight) - buttonMarginRight;
      const containerWidth = containerRef.current ? containerRef.current.offsetWidth : 0;
      const maxDrag = containerWidth - totalButtonsWidth - containerPadding;
      setMaxDragX(Math.min(maxDrag, 0));
    };

    calculateMaxDrag();
    window.addEventListener('resize', calculateMaxDrag);

    return () => window.removeEventListener('resize', calculateMaxDrag);
  }, [widthOfOneItem, buttonData.length]);

  return (
    <div>
      <div 
        className="flex flex-col justify-center items-center relative"
        style={{ 
          ...bgStyle,          
         
        }}
      >
        {showGradient && <div className="absolute bottom-0 left-0 right-0 h-1/4 bg-gradient-to-t from-white/100 to-transparent z-10" />}
        <div className="text-white text-center pl-4 pr-4 pt-2 z-20">
          <h1 className="sm:text-4xl md:text-5xl lg:text-7xl xl:text-7xl mb-8 sm:mb-10 md:mb-12 leading-none sm:leading-tight md:leading-loose">
            <span className="text-xs leading-loose lg:text-2xl lg:leading-loose">CHOISISSEZ VOTRE VILLE</span> 
            <span className="block sm:inline md:block font-bold text-2xl lg:text-6xl">VILLES <span className="text-[rgb(0,35,161)] lg:text-6xl">POPULAIRES</span></span>
          </h1>
        </div>
      </div>
      {/* Render search input only for small screens */}
      {!isLargeScreen() && (
        <div className="flex items-center bg-white rounded-2xl border-2 border-gray-300 w-60 mx-auto mb-4">
          <input
            className="rounded-l-full w-48 py-1 px-2 text-black focus:outline-none placeholder-gray-800 font-semibold text-sm"
            id="search"
            type="text"
            placeholder="Rechercher..."
          />
          <div>
            <button className="rounded-full text-black hover:bg-gray-100 focus:outline-none w-10 h-10 flex items-center justify-center">
              <img src={searchicon} className="w-4 h-4" alt="Search" />
            </button>
          </div>
        </div>
      )}
      {/* Render buttons grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 items-center justify-center">
        <div className="col-span-full md:col-span-2 lg:col-span-4">
          <div ref={containerRef} className="overflow-hidden mt-4 mb-8">
            {/* Render buttons differently based on screen size */}
            {isLargeScreen() ? (
              <div className='flex space-between '>
              <div className="flex cursor-grab select-none lg:mt-10 lg:ml-20">
                {buttonData.map((button, index) => (
                  <div
                    key={button.id}
                    className={`flex-none ${index === 0 ? 'ml-16' : 'ml-2'}`}
                    style={{ minWidth: '80px' }}
                  >
                    <button className="bg-[rgb(33,155,207)] hover:bg-[rgb(0,35,161)] text-white py-2 px-6 rounded-full text-xs">
                      {button.label}
                    </button>
                  </div>
                ))}
              </div>
              {/* Render search input for large screens */}
              <div className='mt-8 ml-60'> 
          <div className="flex items-center bg-white rounded-none border-b-2 border-gray-300 w-80 mx-auto mb-4"> 
              <input
                 className="rounded-none w-64 py-2 px-2 text-black focus:outline-none placeholder-gray text-sm border-none"
                 id="search"
                 type="text"
                 placeholder="Rechercher..."
              />
              <div>
                 <button className="rounded-full text-black hover:bg-gray-100 focus:outline-none w-10 h-10 flex items-center justify-center">
                 <img src={searchicon} className="w-4 h-4" alt="Search" />
                 </button>
              </div>
          </div>
          </div>
              </div>
            ) : (
              <motion.div
                drag="x"
                dragConstraints={{ right: 0, left: maxDragX }}
                className="flex cursor-grab select-none"
              >
                {buttonData.map((button, index) => (
                  <motion.div
                    key={button.id}
                    className={`flex-none ${index === 0 ? 'ml-16' : 'ml-2'}`}
                    style={{ minWidth: '80px' }}
                  >
                    <button className="bg-[rgb(33,155,207)] hover:bg-[rgb(0,35,161)] text-white py-2 px-6 rounded-full text-xs">
                      {button.label}
                    </button>
                  </motion.div>
                ))}
              </motion.div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainContentPC;
