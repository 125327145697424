import React, { useEffect, useRef, useState } from 'react';

const buttonTour = [
  { id: 'button-1', label: 'Sousse' },
  { id: 'button-2', label: 'Monastir' },
  { id: 'button-3', label: 'Djerba' },
  { id: 'button-4', label: 'Tabarka' },
  { id: 'button-5', label: 'Bizerte' },
  { id: 'button-6', label: 'Sud Tunisien' },
  { id: 'button-7', label: 'Mahdia' },


];

const SearchBarPersonalizedTour = () => {
  const containerRef = useRef(null); // Reference to the container div
  const [maxDragX, setMaxDragX] = useState(0); // State for maximum drag distance
  const widthOfOneItem = 100; // Width of one button item

  const isLargeScreen = () => window.innerWidth >= 1024; // Function to check if the screen size is large
  // Calculate the maximum drag distance
  useEffect(() => {
    const calculateMaxDrag = () => {
      const buttonMarginRight = 16;  // Margin between buttons
      const containerPadding = 90;   // Padding of the container
      // Total width of all buttons
      const totalButtonsWidth = buttonTour.length * (widthOfOneItem + buttonMarginRight) - buttonMarginRight; 
      // Width of the container
      const containerWidth = containerRef.current ? containerRef.current.offsetWidth : 0;
      // Maximum drag distance
      const maxDrag = containerWidth - totalButtonsWidth - containerPadding;
      // Set the maximum drag distance
      setMaxDragX(Math.min(maxDrag, 0));
    };

    calculateMaxDrag();
    window.addEventListener('resize', calculateMaxDrag);

    return () => window.removeEventListener('resize', calculateMaxDrag);
  }, [widthOfOneItem, buttonTour.length]);

  return (
    <div className="flex items-center justify-center mt-5">
      <p className="text-sm lg:text-3xl lg:mb-10 font-extrabold text-[rgb(49,77,179)] lg:mt-8 ">Choisir la ville à visiter</p>
      <div className="flex bg-white rounded-xl border-2 border-gray-200 w-40 ml-5 lg:w-80 lg:h-[60px]">
        <select
          className="rounded-full w-full py-2 px-2 text-black focus:outline-none font-semibold text-sm"
          id="search"
        >
          {buttonTour.map((option) => (
            <option value={option.label.toLowerCase()}>{option.label}</option>
          ))}
        </select>
      </div>
    </div>
  );
  
};

export default SearchBarPersonalizedTour;
