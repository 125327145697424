import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import star_icon from '../../../assets/Images/Tour/star.png';
import star_icon_empty from '../../../assets/Images/TourInformation/star_icon_empty.png';
import no_icon from '../../../assets/Images/TourInformation/no_icon.png';
import yes_icon from '../../../assets/Images/TourInformation/yes_icon.png';

const Information = () => {
    const [tourData, setTourData] = useState({ destinations: [] });
  
    useEffect(() => {
      const storedData = sessionStorage.getItem('tourData');
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        console.log("Parsed Data from Storage:", parsedData);
        setTourData(parsedData);
      }
    }, []);
  
    return (
      <div className="flex flex-col">
        <div className="flex items-center">
          <h1 className="pt-20 pb-1 mr-4 text-2xl lg:text-4xl font-bold lg:text-2xl mt-0 mb-0 lg:pt-40">{tourData.title}</h1>
          <span className="flex pt-20 lg:pt-40 text-xl lg:text-3xl font-medium text-[rgb(49,77,179)]">{tourData.price} Dt<span className="text-gray-400 pl-1">/</span>
            <span className="flex items-center text-gray-400 text-xs lg:text-base font-normal pl-1">Par Personne</span>
          </span>
        </div>
        <div className="flex items-center">
          <img src={star_icon} alt="star" className="mr-1 lg:w-4 lg:h-4" />
          <img src={star_icon} alt="star_icon" className="mr-1 lg:w-4 lg:h-4" />
          <img src={star_icon} alt="star_iconn" className="mr-1 lg:w-4 lg:h-4" />
          <img src={star_icon} alt="location_icon4" className="mr-1 lg:w-4 lg:h-4" />
          <img src={star_icon_empty} alt="star_icon_empty" className="mr-2 lg:w-4 lg:h-4" />
          <span className="text-xs text-gray-500 font-medium lg:text-base">(4 avis)</span>
        </div>
        <div className="flex items-center">
          <span className="text-gray-400 font-normal lg:font-semibold w-70 mt-10 lg:text-[rgb(0,35,161)]">
            {tourData.description}
          </span>
        </div>
        <div className="flex flex-row mt-10">
          <div className="flex-1 flex flex-col">
            <span className="text-[rgb(49,77,179)] font-bold text-xs lg:text-xl lg:pb-20 pb-7">Destinations</span>
            <span className="text-[rgb(49,77,179)] font-bold text-xs lg:text-xl lg:pb-10 pb-5">Heure De Départ</span>
            <span className="text-[rgb(49,77,179)] font-bold text-xs lg:text-xl lg:pb-10 pb-5">Heure De Retour</span>
            <span className="text-[rgb(49,77,179)] font-bold text-xs lg:text-xl lg:pb-10 pb-5">Non Inclus</span>
            <span className="text-[rgb(49,77,179)] font-bold text-xs lg:text-xl lg:pb-30">Inclus</span>
            <span className="text-[rgb(49,77,179)] font-bold text-xs lg:text-xl lg:pb-10 pt-20">Dress Code</span>
          </div> 
          <div className="flex-1 flex flex-col">
            <span className="text-gray-400 font-semibold text-[10px] lg:text-base lg:font-normal lg:text-black lg:pb-5 lg:mb-14 lg:mt-2  pb-4">
               {Array.isArray(tourData.destinations) && tourData.destinations.length > 0 ? tourData.destinations.map((destination, index) => (
                  <div key={index}>
                   : {destination.titre}
                  </div>
                )) : 'Aucune destination disponible'}
            </span>
            <span className="text-gray-400 font-semibold text-[10px] lg:text-base lg:font-normal lg:text-black lg:pb-12 pb-5">: {tourData.heureDepart} AM</span>
            <span className="text-gray-400 font-semibold text-[10px] lg:text-base lg:font-normal lg:text-black lg:pb-11 pb-6">: {tourData.heureArrivee} PM</span>
            

            <div className="non-inclus-list">
        {tourData.nonInclus && Array.isArray(tourData.nonInclus) ? (
          tourData.nonInclus.map((item, index) => (
            <div key={index} className="flex inline-flex items-center pb-6 lg:pb-12">
              <img className="mr-1 lg:w-auto lg:h-auto w-2 h-2" src={no_icon} alt="no_icon" />
              <span className="text-gray-400 font-semibold mr-1 text-[10px] lg:text-base lg:font-normal lg:text-black">
                {item}
              </span>
            </div>
          ))
        ) : (
          <p>Aucun élément non inclus.</p>
        )}
      </div>

      <div className="inclus-list">
        {tourData.inclus && Array.isArray(tourData.inclus) ? (
          tourData.inclus.map((item, index) => (
            <div className="flex inline-flex items-center pb-2">
              <img className="mr-1 lg:w-auto lg:h-auto w-2 h-2" src={yes_icon} alt="yes_icon" />
              <span className="text-gray-400 font-semibold mr-1 text-[10px] lg:text-base lg:font-normal lg:text-black lg:mr-5 mr-5">{item}</span>
              </div>
          ))
        ) : (
          <p>Aucun élément inclus.</p>
        )}
      </div>
            

            <span className="text-gray-400 font-semibold text-[10px] lg:text-base lg:font-normal lg:text-black lg:pt-0 pt-2 mx-auto">:{tourData.dressCode}</span>
          </div>
        </div>
      </div>
    );
  };
  
  export default Information;
  
  
  
  
  
  
