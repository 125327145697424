import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import arrow from '../../assets/Images/TourInformation/returnArrow.png';
import parameter from '../../assets/Images/TourInformation/ParameterIcon.png';
import heart from '../../assets/Images/TourInformation/heartIcon.png';
import hearticonfilled from '../../assets/Images/Tour/heartFilled.png';

const TourNavbar = () => {
    // Media query hook to detect mobile devices
    const isMobile = useMediaQuery({ maxWidth: 767 });
    // State to track whether the tour is liked or not
    const [isLiked, setIsLiked] = useState(false);
    // Function to toggle the like status
    const toggleLike = () => {
      setIsLiked(!isLiked);
    };
    

  return (
    <nav className={`fixed w-full z-50 transition duration-300 ease-in-out pt-2 pl-8 pb-6 mb-6`}>
      <div className="mx-auto px-5 sm:px-6 flex justify-between items-center h-16">
        {/* Link to navigate back to the Tour page */}
        <Link to="/Tour">
          <img src={arrow} alt="Logo" className="h-auto md:h-10" />
        </Link>
        <div className="md:hidden flex items-center">
          {/* Render parameter icon and heart icon based on mobile view */}
          {isMobile && (
            <>
              <img src={parameter} alt="parameter" className="h-auto w-auto mr-6" />
              {/* Conditional rendering for heart icon, changes to filled heart if liked */}
              <img  src={isLiked ? hearticonfilled : heart}  
              alt="heart" 
              className={`h-auto w-auto mr-6 ${isLiked ? 'w-6' : 'w-auto'}`}
              onClick={toggleLike} />
            </>
          )}
          
        </div>
      </div>
    </nav>
  );
};

export default TourNavbar;
