import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import starRating from '../../../assets/Images/Tour/star.png';

const MainContentTourInfo = () => {
  const storedData = sessionStorage.getItem('tourData');
  const [tourData, setTourData] = useState(() => storedData ? JSON.parse(storedData) : {});
  
  const [showGradient, setShowGradient] = useState(() => window.innerWidth < 1024);
  const [bgStyle, setBgStyle] = useState(() => {
    const initialBgStyle = {
      backgroundImage: `url(${tourData.imageSrc})`,
      backgroundSize: '100% auto',
      backgroundRepeat: 'no-repeat',
      height: '45vh'
    };
    if (window.innerWidth >= 1024) {
      initialBgStyle.backgroundPosition = 'center 10%';
      initialBgStyle.height = '100vh';
    } else {
      initialBgStyle.backgroundPosition = 'top left';
    }
    return initialBgStyle;
  });
  const location = useLocation();
  useEffect(() => {
    const updateBackgroundStyle = () => {
      if (window.innerWidth >= 1024) {
        setShowGradient(true);
        setBgStyle({
          backgroundImage: `url(${tourData.imageSrc})`,
          backgroundSize: '100% auto',
          backgroundPosition: 'center 10%',
          backgroundRepeat: 'no-repeat',
          height: '100vh'
        });
      } else {
        setShowGradient(false);
        setBgStyle({
          backgroundImage: `url(${tourData.imageSrc})`,
          backgroundSize: '100% auto',
          backgroundPosition: 'top left',
          backgroundRepeat: 'no-repeat',
          height: '45vh'
        });
      }
  
  const searchParams = new URLSearchParams(location.search);

  // Get data from session storage
 

  // Early return if tourData is not available yet
  if (!tourData) return <div>Loading...</div>;

  let rating = parseFloat(searchParams.get('rating'));
  if (rating === Math.floor(rating)) {
    rating = rating.toFixed(1);
  }

  
    };

    window.addEventListener('resize', updateBackgroundStyle);
    updateBackgroundStyle();

    return () => window.removeEventListener('resize', updateBackgroundStyle);
  }, [tourData.imageSrc]);

  return (
    <div>
      <div
        className="flex flex-col justify-center items-center relative"
        style={{
          ...bgStyle
        }}
      >
        {showGradient && <div className=" bottom-0 left-0 right-0 h-1/4 bg-gradient-to-t from-white/100 to-transparent z-10" />}
        <div className="text-white text-center pl-4 pr-4 z-20 flex flex-col justify-center items-center h-full mt-12 lg:mt-6">
          <span className="text-4xl font-bold lg:text-2xl lg:leading-loose lg:font-bold lg:text-7xl mb-5 lg:mb-2">{tourData.title}</span>
          <div className="flex justify-center items-center bg-white rounded-full w-20 h-8 lg:w-40 lg:h-12 mb-20">
            <span className="text-black font-bold text-sm lg:text-2xl ">rating</span>
            <img src={starRating} alt="starRating" className="w-3 h-3 ml-1 lg:w-5 lg:h-5 " />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainContentTourInfo;
