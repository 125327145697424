import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendar_icon from '../../../assets/Images/Tour/calendarIcon.png';
import person_icon from '../../../assets/Images/TourInformation/person_icon.png';

const BookingForm = () => {
    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const clearSelectedDate = () => {
        setSelectedDate(null);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Delete' || event.key === 'Backspace') {
            clearSelectedDate();
        }
    };

    const DatePickerInput = ({ value, onClick }) => (
        <div className="relative">
            <input
                type="text"
                value={value}
                onClick={onClick}
                placeholder="23/04/2024"
                className="mt-2 mb-2 px-4 py-3 border border-none rounded-xl text-sm lg:text-xl lg:placeholder-gray-400 lg:py-8 h-[48px] w-[250px] lg:w-[280px]"
                onKeyDown={handleKeyDown}
            />
            <img
                src={calendar_icon}
                alt="calendar"
                className="absolute top-1/2 right-1 transform -translate-y-1/2 w-auto h-auto cursor-pointer lg:w-5 lg:h-5"
                onClick={onClick}
            />
        </div>
    );

    return (
        <div>
            {/* Div for booking tour */}
            <div className="flex-1  lg:mt-40 mt-16 w-80 mx-auto lg:mt-3 lg:mr-10 ">
                <div className="relative bg-gray-100 lg:w-[350px] rounded-lg p-5 mx-auto">
                    <div className="absolute top-0 right-0 font-bold bg-[rgb(49,77,179)] text-white py-5 rounded-t-lg w-full lg:w-[350px] lg:py-7">
                        <h2 className="text-center lg:text-2xl">Réserver cette visite</h2>
                    </div>
                    <div className="mt-10 lg:mt-20">
                        <h3 className="flex flex-col justify-center items-center font-semibold mb-3 pt-5 text-[10px] lg:text-base lg:font-semibold text-center mx-auto">
                            <span className="pb-2">Préparez-vous à explorer la Tunisie comme jamais auparavant. Réservez votre escapade dès maintenant</span>
                        </h3>
                        <div className="flex flex-col items-center">
                            <DatePicker
                                selected={selectedDate}
                                onChange={handleDateChange}
                                customInput={<DatePickerInput />}
                                dateFormat="dd/MM/yyyy"
                                className="hidden"
                            />
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Nombre de personne"
                                    className="mt-2 mb-2 px-4 py-3 border border-none rounded-xl text-sm lg:text-xl lg:placeholder-gray-400 lg:py-8 h-[48px] w-[250px] lg:w-[280px]"
                                />
                                <img
                                    src={person_icon}
                                    alt="person_icon"
                                    className="absolute top-1/2 right-1 transform -translate-y-1/2 w-auto h-auto cursor-pointer lg:w-5 lg:h-5"
                                />
                            </div>
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Message"
                                    className="mt-2 mb-2 px-4 py-3 border border-none rounded-xl text-sm lg:text-xl lg:placeholder-gray-400 lg:py-8 h-[48px] w-[250px] lg:w-[280px]"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center items-center mt-5 mb-5 lg:mb-5">
                        <button className="bg-[rgb(49,77,179)] rounded-lg text-white px-7 py-3 text-sm lg:text-base lg:py-4 lg:px-5 font-semibold mr-5 lg:mr-5 lg:w-[120px] lg:w-auto">
                            Réserver
                        </button>
                        <button className="bg-[rgb(49,77,179)] rounded-lg text-white px-4 py-3 text-sm lg:text-base lg:py-4 lg:px-3 font-semibold lg:ml-2 lg:w-[120px] lg:w-auto">
                            Personnaliser
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookingForm;


