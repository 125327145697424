import React, { useState, useEffect } from 'react';

// Importing images for small screens
import GharElMelhImage from '../../../assets/Images/Destination/Ghar el melh.png';
import DjerbaImage from '../../../assets/Images/Destination/Djerba.png';
import TamerzaImage from '../../../assets/Images/Destination/Tameraza.png';
import ElMedinaImage from '../../../assets/Images/Destination/Tunis.png';
import Dougga from '../../../assets/Images/Destination/Dougga.png';
import Monastir from '../../../assets/Images/Destination/Monastir.png';
import Tabarka from '../../../assets/Images/Destination/Tabarka.png';
import Sousse from '../../../assets/Images/Destination/Sousse.png';
import Jemm from '../../../assets/Images/Destination/El jemm.png';
import Douz from '../../../assets/Images/Destination/Douz.png';

// Importing images for large screens
import GharElMelhImageWeb from '../../../assets/Images/Destination/Ghar el melh web.png';
import DjerbaImageWeb from '../../../assets/Images/Destination/Djerba web.png';
import TamerzaImageWeb from '../../../assets/Images/Destination/Tamerza web.png';
import ElMedinaImageWeb from '../../../assets/Images/Destination/Tunis web.png';
import DouggaWeb from '../../../assets/Images/Destination/Dougga web.png';
import MonastirWeb from '../../../assets/Images/Destination/MonastirWeb.png';
import TabarkaWeb from '../../../assets/Images/Destination/TabarkaWeb.png';
import SousseWeb from '../../../assets/Images/Destination/SousseWeb.png';
import DouzWeb from '../../../assets/Images/Destination/DouzWeb.png';

// Array of images for small screens
const smallScreenImages = [
  { image: GharElMelhImage },
  { image: DjerbaImage },
  { image: TamerzaImage },
  { image: ElMedinaImage },
  { image: Dougga },
  { image: Monastir },
  { image: Tabarka },
  { image: Sousse },
  { image: Jemm },
  { image: Douz },
];

// Array of images for large screens
const largeScreenImages = [
  { image: GharElMelhImageWeb },
  { image: DjerbaImageWeb },
  { image: TamerzaImageWeb },
  { image: ElMedinaImageWeb },
  { image: DouggaWeb },
  { image: MonastirWeb },
  { image: TabarkaWeb },
  { image: DouzWeb, name: 'Douz' }, // 'name' property added for the 'Douz' city
];

// Gallery component
const Gallery = () => {
  const [currentImages, setCurrentImages] = useState(smallScreenImages);

  useEffect(() => {
    // Setting up a window resizing manager
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        // Updating images for large screens
        setCurrentImages(largeScreenImages);
      } else {
        // Updating images for small screens
        setCurrentImages(smallScreenImages);
      }
    };

    handleResize(); // Initial call for component loading

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Function to determine the span class based on city, index, and length
  const getImageSpanClass = (city, index, length) => {
    if (window.innerWidth >= 1024) {
      if (city.name === 'Douz') {
        return 'lg:col-span-2 lg:row-span-1 lg:row-start-4'; // Douz city specific styling for large screens
      } else if (index === length - 2) {
        return 'lg:col-span-1'; // Second to last image styling for large screens
      } else if (index === 1) {
        return 'lg:col-span-2'; // Second image styling for large screens
      }
    }
    return 'lg:col-span-1'; // Default styling for large screens
  };

  return (
    <div className="p-8 lg:p-40">
      <h2 className="text-2xl font-bold text-center mb-10 lg:text-5xl">
        <span className="text-[rgb(0,35,161)]">GALERIE</span> 
        <span className="text-[rgb(45,169,197)]"> DES VILLES</span>
      </h2>
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 lg:gap-10">
         {currentImages.map((city, index) => {
           const spanClass = getImageSpanClass(city, index, currentImages.length);
           return (
           <div key={index} className={`bg-white rounded-2xl overflow-hidden group ${spanClass}`}>
             <img src={city.image} alt={city.name} className="w-full h-auto object-cover" />
           </div>
           );
          })}
      </div>
    </div>
  );
};

export default Gallery;
