import React from 'react';
import Header from '../layout/Header';
import SubCategory from '../common/PersonalizedTour/PersonalizedTourSubCategoryPage/subCategory';
import Footer from '../layout/Footer';
import { useParams } from 'react-router-dom';

function PersonalizedTourSubCategory() {   
  const { id } = useParams(); 
    return (
      <div>
       <Header page="activité" />
       <SubCategory tourId={id}/>
       <Footer/>
      </div>
    );
}

export default PersonalizedTourSubCategory;