import React, { useState } from 'react';
import info_icon from '../../../assets/Images/TourInformation/info_icon.png';
import plan_icon from '../../../assets/Images/TourInformation/plan_icon.png';
import location_icon from '../../../assets/Images/TourInformation/location_icon.png';
import gallery_icon from '../../../assets/Images/TourInformation/gallery_icon.png';

import BookingForm from '../TourDetailsPage/BookingForm';
import Information from '../TourDetailsPage/TourInformation';
import Plan from '../TourDetailsPage/TourPlan';
import Location from '../TourDetailsPage/TourLocation';
import Gallery from '../TourDetailsPage/TourGallery';

const GlobalContent = () => {
    const [activeSection, setActiveSection] = useState('information');

    return (
        <div className="max-w-screen-lg mx-auto bg-white rounded-t-3xl lg:rounded-none p-8 relative top-[-40px] left-0 right-0 mb-5 flex flex-col lg:flex-row lg:shadow-2xl">
            <div className="flex-1 lg:mr-5">
                <div className="flex absolute top-0 left-0 w-full lg:w-full">
                    {/* Button 1 */}
                    <button 
                        className={`flex inline-flex items-center bg-gray-100 hover:bg-white text-black font-bold py-6 px-5 rounded-tl-3xl text-[9px] lg:text-xl lg:px-10 lg:rounded-none ${activeSection === 'information' && 'bg-white'}`}
                        onClick={() => setActiveSection('information')}
                    >
                        <img src={info_icon} alt="info_icon" className="w-2 h-2 lg:w-auto lg:h-auto mr-3" />
                        Information
                    </button>
                    {/* Button 2 */}
                    <button 
                        className={`flex inline-flex items-center bg-gray-100 hover:bg-white text-black font-bold py-6 px-3 text-[9px] lg:text-xl lg:px-20 lg:rounded-none ${activeSection === 'plan' && 'bg-white'}`}
                        onClick={() => setActiveSection('plan')}
                    >
                        <img src={plan_icon} alt="plan_icon" className="w-2 h-2 lg:w-auto lg:h-auto mr-2" />
                        Votre Plan
                    </button>
                    {/* Button 3 */}
                     <button className={`flex inline-flex items-center bg-gray-100 hover:bg-white text-black font-bold py-6 px-3 text-[9px] lg:text-xl lg:px-20 lg:rounded-none ${activeSection === 'emplacement' && 'bg-white'}`}
                     onClick={() => setActiveSection('emplacement')}
                     >
                     <img src={location_icon} alt="location_icon" className="w-2 h-2 lg:w-auto lg:h-auto mr-3" />
                      Emplacement
                    </button>
                   {/* Button 4 */}
                   <button className={`flex inline-flex items-center bg-gray-100 hover:bg-white text-black font-bold py-6 px-5 rounded-tr-3xl text-[9px] lg:text-xl lg:px-20 lg:rounded-none ${activeSection === 'gallerie' && 'bg-white'}`}
                     onClick={() => setActiveSection('gallerie')}
                     >
                    <img src={gallery_icon} alt="gallery_icon" className="w-2 h-2 lg:w-auto lg:h-auto mr-3" />
                    Galerie
                  </button>
                </div>
                {/* Conditional rendering of components based on activeSection */}
                {activeSection === 'information' && <Information/>}
                {activeSection === 'plan' && <Plan/>}
                {activeSection === 'emplacement' && <Location/>}
                {activeSection === 'gallerie' && <Gallery/>}
            </div>
            {/* booking tour */}
            <BookingForm/>
        </div>
    );
};

export default GlobalContent;
