import React from 'react';
import Header from '../layout/Header';
import SearchBarPersonalizedTour from '../common/PersonalizedTour/PersonalizedTourPage/SearchBar';
import TourChoices from '../common/PersonalizedTour/PersonalizedTourChoicesPage/choices';
import { useParams } from 'react-router-dom';

function TourChoicesPage() {
    const { id } = useParams(); 

    return (
        <div>
            <Header page="activité" />
            <SearchBarPersonalizedTour />
            <TourChoices tourId={id} /> 
        </div>
    );
}

export default TourChoicesPage;
