import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const createContact = createAsyncThunk(
    'contact/createContact', async (contactData) => {
    const response = await axios.post('http://localhost:8000/api/contact/user', contactData);
    return response.data;
});

const contactSlice = createSlice({
    name: 'contact',
    initialState: {
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createContact.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createContact.fulfilled, (state) => {
                state.status = 'succeeded';
            })
            .addCase(createContact.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export default contactSlice.reducer;
