import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calendar_icon from '../../../../assets/Images/Tour/calendarIcon.png';
import person_icon from '../../../../assets/Images/TourInformation/person_icon.png';
import popup from '../../../../assets/Images/PersonalizedTour/equitationDetails/popup image.png';
import location from '../../../../assets/Images/PersonalizedTour/equitationDetails/Location.png';
import Trash from '../../../../assets/Images/PersonalizedTour/equitationDetails/Trash.png';

const BookingPersonalizedTour = () => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [adultCount, setAdultCount] = useState(0);
    const [childCount, setChildCount] = useState(0);
    const [studentCount, setStudentCount] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalPeople, setTotalPeople] = useState(0);
    const [showCounters, setShowCounters] = useState(false);
    const [showModal, setShowModal] = useState(false); 

    // Calculate the total price and number of people
    useEffect(() => {
        const total = (adultCount * 70) + (childCount * 30) + (studentCount * 30);
        setTotalPrice(total);
        setTotalPeople(adultCount + childCount + studentCount);
    }, [adultCount, childCount, studentCount]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const clearSelectedDate = () => {
        setSelectedDate(null);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Delete' || event.key === 'Backspace') {
            clearSelectedDate();
        }
    };

    const increment = (countSetter, count) => {
        countSetter(count + 1);
    };

    const decrement = (countSetter, count) => {
        countSetter(Math.max(0, count - 1));
    };

    const DatePickerInput = ({ value, onClick }) => (
        <div className="relative">
            <input
                type="text"
                value={value}
                onClick={onClick}
                placeholder="23/04/2024"
                className="mt-2 mb-2 px-4 py-3 border border-none rounded-xl text-sm lg:text-xl lg:placeholder-gray-400 lg:py-8 h-[48px] w-[250px] lg:w-[280px]"
                onKeyDown={handleKeyDown}
            />
            <img
                src={calendar_icon}
                alt="calendar"
                className="absolute top-1/2 right-1 transform -translate-y-1/2 w-auto h-auto cursor-pointer lg:w-5 lg:h-5"
                onClick={onClick}
            />
        </div>
    );
    const textColorClass = (value) => value > 0 ? "text-black" : "text-gray-400";
    const Modal = () => (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-80 backdrop-blur-sm flex justify-center items-center">
            <div className="bg-white p-10 rounded-2xl lg:w-[600px]">
                <h1 className="text-4xl font-semibold pb-10">Vos favoris</h1>
                <div className="flex justify-center pb-4">
                    <hr className="h-[2px] w-[520px] bg-gray-200" />
                </div>
                <div className="flex items-center">
                    <img src={popup} className="rounded-lg mb-auto"></img>
                    <div className="flex flex-col ml-4 ">
                        <p className="text-2xl font-bold">Karting Hergla Park</p>
                        <div className="flex items-center mt-4">
                            <img src={location}></img>
                        <p className="text-xl text-[rgb(45,169,197)] ml-2">Sousse</p>
                        </div>
                        <p className="text-2xl text-[rgb(157,158,162)] font-semibold ml-2 mt-4">2 Adultes 2 Enfants</p>
                        <p className="text-2xl text-[rgb(157,158,162)] font-normal ml-2 mt-4">Lundi, 22 Avril, 2024</p>
                        <div className="flex justify-center pt-8 pb-6">
                           <hr className="h-[1px] w-[320px] bg-gray-200" />
                         </div>
                    </div>
                    <img src={Trash} className=" flex ml-auto mb-auto"></img> 
                </div>
                
                <div className="flex items-center">
                    <p className="text-2xl text-[rgb(157,158,162)] font-semibold ml-[130px]">Total</p>
                    <p className="flex ml-auto text-2xl font-semibold">140 DT</p>
                </div>
                <div className="flex justify-center pt-4">
                    <hr className="h-[1px] w-[520px] bg-gray-200" />
                </div>
                <div className="flex items-center mt-8 mb-8">
                    <p className="text-2xl font-semibold">TOTAL</p>
                    <p className="flex ml-auto text-3xl text-[rgb(45,169,197)] font-semibold">140 DT</p>
                </div>
                <div className="flex justify-center">
                    <hr className="h-[1px] w-[520px] bg-gray-200" />
                </div>
                <button className="bg-white rounded-full border border-[rgb(49,77,179)] border border-4 text-[rgb(49,77,179)] text-base lg:text-xl font-bold py-5 lg:w-full mt-5 ">
                    Retour
                </button>
                <button className="bg-[rgb(49,77,179)] rounded-full border border-[rgb(49,77,179)] border border-4 text-white text-base lg:text-xl font-bold py-5 lg:w-full mt-5 ">
                    Réserver maintenant
                </button>
            </div>
        </div>
    );
    return (
        <div>
            <div className="flex-1 lg:mt-[137px] mt-16 w-80  lg:mt-3 lg:mr-10 ">
            {showModal && <Modal />}
                <div className="relative bg-gray-100 lg:w-[350px] rounded-lg p-5 mx-auto">
                    <div className="absolute top-0 right-0 font-bold bg-[rgb(49,77,179)] text-white py-5 rounded-t-lg w-full lg:w-[350px] lg:py-7">
                        <h2 className="text-center lg:text-2xl">Réserver cette visite</h2>
                    </div>
                    <div className="mt-10 lg:mt-20">
                        <h3 className="flex flex-col justify-center items-center font-semibold mb-3 pt-5 text-[10px] lg:text-base lg:font-semibold text-center mx-auto">
                            <span className="pb-2">Préparez-vous à explorer la Tunisie comme jamais auparavant. Réservez votre escapade dès maintenant</span>
                        </h3>
                        <div className="flex flex-col items-center">
                            <DatePicker
                                selected={selectedDate}
                                onChange={handleDateChange}
                                customInput={<DatePickerInput />}
                                dateFormat="dd/MM/yyyy"
                                className="hidden"
                            />
                            <div className="relative" onClick={() => setShowCounters(!showCounters)}>
                                <input
                                    type="text"
                                    value={`${totalPeople} ${totalPeople === 1 ? 'Personne' : 'Personnes'}`}
                                    readOnly
                                    className={`mt-2 mb-2 px-4 py-3 border border-none rounded-xl text-sm lg:text-xl lg:py-8 h-[48px] w-[250px] lg:w-[280px] ${textColorClass(totalPeople)}`}                                />
                                <img
                                    src={person_icon}
                                    alt="person_icon"
                                    className="absolute top-1/2 right-1 transform -translate-y-1/2 w-auto h-auto cursor-pointer lg:w-5 lg:h-5"
                                />
                            </div>
                            {showCounters && (
                            <div className="lg:w-[280px] w-[250px] bg-white rounded-lg ">
                                <div className="flex items-center justify-between p-4 rounded-xl w-full">
                                  <div>
                                    <p className="lg:text-base text-sm font-bold">Adulte (18+)</p>
                                    <p className="text-[rgb(45,169,197)] font-bold lg:text-sm text-xs mt-2">{adultCount * 70} DT</p>
                                  </div>
                                 <div className="flex items-center">
                                    <button className="text-xl" onClick={() => decrement(setAdultCount, adultCount)}>-</button>
                                    <span className="mx-4 px-3 py-1 bg-gray-300 rounded-full">{adultCount}</span>
                                    <button className="text-xl" onClick={() => increment(setAdultCount, adultCount)}>+</button>
                                 </div>
                                </div>

                                <div className="flex justify-center">
                                  <hr className="h-[1px] w-60 bg-gray-200" />
                                </div>

                                <div className="flex items-center justify-between p-4 rounded-xl w-full">
                                  <div>
                                    <p className="lg:text-base text-sm font-bold">Enfant (6–17)</p>
                                    <p className="text-[rgb(45,169,197)] font-bold lg:text-sm text-xs mt-2">{childCount * 30} DT</p>
                                  </div>
                                 <div className="flex items-center">
                                    <button className="text-xl" onClick={() => decrement(setChildCount, childCount)}>-</button>
                                    <span className="mx-4 px-3 py-1 bg-gray-300 rounded-full">{childCount}</span>
                                    <button className="text-xl" onClick={() => increment(setChildCount, childCount)}>+</button>
                                 </div>
                                </div>

                                <div className="flex justify-center">
                                  <hr className="h-[1px] w-60 bg-gray-400" />
                                </div>

                                <div className="flex items-center justify-between p-4 rounded-xl w-full">
                                  <div>
                                    <p className="lg:text-base text-sm font-bold">Étudiants</p>
                                    <p className="text-[rgb(45,169,197)] font-bold lg:text-sm text-xs mt-2">{studentCount * 30} DT</p>
                                  </div>
                                 <div className="flex items-center">
                                    <button className="text-xl" onClick={() => decrement(setStudentCount, studentCount)}>-</button>
                                    <span className="mx-4 px-3 py-1 bg-gray-300 rounded-full">{studentCount}</span>
                                    <button className="text-xl" onClick={() => increment(setStudentCount, studentCount)}>+</button>
                                 </div>
                                </div>
                            </div>
                        )}
                            <div className="relative">
                                <input
                                    type="text"
                                    value={totalPrice + ' DT'}
                                    readOnly
                                    className={`mt-2 mb-2 px-4 py-3 border border-none rounded-xl text-sm lg:text-xl lg:py-8 h-[48px] w-[250px] lg:w-[280px] ${textColorClass(totalPrice)}`}

                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center items-center mt-5 mb-5 lg:mb-5">
                    <button className="bg-[rgb(49,77,179)] rounded-lg text-white px-7 py-3 text-sm lg:text-xl lg:py-4 lg:px-5 font-semibold mr-5 lg:mr-5 lg:w-[120px] lg:w-auto" onClick={() => setShowModal(true)}>
                            Réserver
                        </button>
                       
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookingPersonalizedTour;


