import React, { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import eeWhite from '../../assets/Images/Home/logo_ee.png';
import guideeWhite from '../../assets/Images/Home/guidee logo.png';
import guideeBlack from '../../assets/Images/Home/guidee.png';
import searchWhite from '../../assets/Images/Home/icone search.png';
import searchBlack from '../../assets/Images/Home/icon search green.png';
import profileWhite from '../../assets/Images/Home/icone profile.png';
import profileBlack from '../../assets/Images/Home/icon profil green.png';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../features/user/userSlice';
import { useNavigate } from 'react-router-dom';

const navLinks = [
  { title: "Accueil", link: "/accueil" },
  { title: "Destinations", link: "/Destination" },
  { title: "Tours", link: "/Tour" },
  { title: "Activités", link: "/activité" },
  { title: "Blogs", link: "/blogs" },
  { title: "Contactez-Nous", link: "/ContactezNous" },
];

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [open, setOpen] = useState(false);
  const [logo, setLogo] = useState(guideeWhite);
  const [searchIcon, setSearchIcon] = useState(searchWhite);
  const [profileIcon, setProfileIcon] = useState(profileWhite);
  const isMounted = useRef(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      console.log('État du menu ouvert:', open);
    } else {
      isMounted.current = true;
    }
  }, [open]);

  useEffect(() => {
    setLogo(isScrolled ? guideeBlack : guideeWhite);
    setSearchIcon(isScrolled ? searchBlack : searchWhite);
    setProfileIcon(isScrolled ? profileBlack : profileWhite);
  }, [isScrolled]);

  const handleProfileClick = () => {
    const token = localStorage.getItem('authToken');
    if (token) {
      navigate("/profile");
    } else {
      navigate("/login");
    }
  };

  const handleSearchClick = async () => {
    console.log('Logging out...');
    await dispatch(logoutUser());
    console.log('Logged out, navigating to login...');
    navigate("/login");
  };

  return (
    <nav className={`fixed top-0 left-0 w-full z-50 ${isScrolled ? 'bg-white shadow-lg shadow-[rgb(59,176,174)]' : 'bg-transparent'} transition duration-300 ease-in-out pt-2 pl-8 pb-6 mb-6`}>
      <div className="max-w-6xl mx-auto px-5 sm:px-6 flex justify-between items-center h-16">
        <Link to="/" className="flex items-center">
          <img src={isMobile ? eeWhite : logo} alt="Logo" className="h-8 md:h-10" />
        </Link>
        <div className={`hidden md:flex items-center space-x-8 mr-14 mt-2 ${isScrolled ? 'text-[rgb(45,169,197)]' : 'text-white'}`}>
          {navLinks.map((link, index) => (
            <Link key={index} to={link.link} className="font-bold hover:text-gray-300">{link.title}</Link>
          ))}
          <img
            src={searchIcon}
            alt="search"
            className="text-xl pl-10 cursor-pointer"
            onClick={handleSearchClick}
          />
          <img
            src={profileIcon}
            alt="profile"
            className="text-xl cursor-pointer"
            onClick={handleProfileClick}
          />
        </div>
        <div className="md:hidden flex items-center">
          {isMobile && (
            <>
              <img src={searchIcon} alt="search" className="h-7 w-7 mr-3 cursor-pointer" onClick={handleSearchClick} />
              <img src={profileIcon} alt="profile" className="h-7 w-7 mr-6 cursor-pointer" onClick={handleProfileClick} />
            </>
          )}
          <button
            onClick={() => setOpen(!open)}
            className={`inline-flex items-center justify-center p-2 rounded-md text-white ${isScrolled && isMobile ? 'hover:bg-gray-200' : ''}`}
            style={{ color: isScrolled && isMobile ? 'rgb(45, 169, 197)' : 'white' }}
            aria-expanded={open}
            aria-label="menu principal"
          >
            {open ? <FaTimes style={{ color: isScrolled && isMobile ? 'rgb(45, 169, 197)' : 'white' }} className="text-2xl" /> : <FaBars style={{ color: isScrolled && isMobile ? 'rgb(45, 169, 197)' : 'white' }} className="text-2xl" />}
          </button>
        </div>
      </div>
      <div className={`md:hidden ${open ? "flex" : "hidden"} flex-col items-start space-y-1 sm:px-6 bg-white shadow-lg absolute w-full left-0 right-0`}>
        {navLinks.map((link, index) => (
          <Link
            key={index}
            to={link.link}
            className="text-gray-700 duration-400 hover:scale-105 block px-5 py-2 rounded-md text-base font-medium"
          >
            {link.title}
          </Link>
        ))}
      </div>
    </nav>
  );
};

export default Navbar;
