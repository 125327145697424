import React, { useState, useEffect } from 'react';
import 'react-input-range/lib/css/index.css';
import star from '../../../../assets/Images/Tour/star.png';
import map from '../../../../assets/Images/PersonalizedTour/equitation/Location.png';
import Heart from '../../../../assets/Images/PersonalizedTour/equitation/heartbtn.png';
import { useNavigate } from 'react-router-dom';

const TourChoices = ({ tourId }) => {
  const [choicesData, setChoicesData] = useState([]);
  const [currentChoicesData, setCurrentChoicesData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fonction pour récupérer les données spécifiques du tour en utilisant l'ID
    const fetchTourData = async (id) => {
      try {
        const response = await fetch(`http://localhost:8000/api/Personalizedtour/${id}`);
        const data = await response.json();
        setChoicesData(data.choix); 
      } catch (error) {
        console.error("Erreur lors de la récupération des données du tour :", error);
      }
    };

    if (tourId) {
      fetchTourData(tourId); // Récupérer les données du tour en utilisant l'ID
    }
  }, [tourId]);

  useEffect(() => {
    const handleResize = () => {
      const newChoicesData = choicesData.map(item => ({
        ...item,
        imageSrc: window.innerWidth >= 1024 ? item.largeImageSrc : item.imageSrc
      }));
      setCurrentChoicesData(newChoicesData);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Appeler également au montage pour initialiser l'état

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [choicesData]);

  const itemsPerPage = 3; // Number of elements per page
  const [currentPage, setCurrentPage] = useState(1); // Status to follow the current page (means that the initial value of the currentPage state is 1.)
  const totalPages = Math.ceil(choicesData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="p-8 flex flex-col ">
      <div>
        <h2 className=" text-2xl font-extrabold text-center mb-8 lg:mb-16 " style={{ fontFamily: 'Poppins, sans-serif' }}>
          <span className="text-[rgb(0,35,161)] lg:text-5xl">Equitation</span>
        </h2>
        <div className="grid grid-cols-1 gap-8 lg:gap-10">
          {currentChoicesData.slice(startIndex, endIndex).map((choice, index) => (
            <Card
              key={index}
              id={choice._id}
              imageSrc={`http://localhost:8000/${choice.image}`}
              title={choice.nom}
              price={choice.prix}
              location={choice.adresse}
              rating={choice.rating}
              notice="Très bien" 
              review={choice.reviews}
            />
          ))}
        </div>

        <div className="flex justify-center mt-8">
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              className={`mx-1 px-3 py-1 rounded-md ${currentPage === index + 1 ? 'bg-gray-800 text-white' : 'bg-gray-200 text-gray-800'}`}
              onClick={() => goToPage(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

const Card = ({ id, imageSrc, title, price, location, rating, notice, review }) => {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const handleCardClick = () => {
    navigate(`/ChoiceDetails/${id}`);
  };

  return (
    <div
      onClick={handleCardClick}
      className="flex items-center bg-white p-3 pt-2 pb-2 rounded-2xl min-w-screen shadow-lg lg:w-[1200px] lg:mx-auto transition-transform duration-300 ease-in-out cursor-pointer"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ transform: isHovered ? 'scale(1.05)' : 'scale(1)' }}
    >
      <div className="w-1/3 ">
        <img src={imageSrc} alt={title} className="object-cover lg:h-auto h-[110px] rounded-xl lg:rounded-none mt-4 lg:mt-0" />
      </div>

      <div className="flex flex-col ml-2 w-2/3 lg:ml-4">
        <div className="flex items-center mb-1 mt-3">
          <h3 className="text-[11px] font-bold lg:text-xl ">{title}</h3>
          <div className="flex flex-col lg:flex-row items-center justify-end ml-auto">
            {/* Affichage pour les petits écrans */}
         <p className="lg:hidden text-xs p-1 rounded-lg bg-[rgb(249,134,51)] text-white ">{price} Dt</p>
            <div className="hidden lg:flex flex-col ml-auto">
              <p className="text-[12px] text-gray-700">À partir de</p>
              <p className="text-2xl font-bold text-[rgb(249,134,51)] ml-5">{price}Dt</p>
            </div>
            <p className="hidden lg:block text-base font-bold text-[rgb(249,134,51)] mt-6">/Heure</p>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex items-center mb-1">
            <img src={map} className="w-3 h-3 lg:w-4 lg:h-4"></img>
            <p className="text-[9px] lg:text-sm ">{location}</p>
          </div>
          <div className="flex items-center gap-1 mb-1">
            <img src={star} alt="star" className="h-3 w-3" />
            <img src={star} alt="star" className="h-3 w-3" />
            <img src={star} alt="star" className="h-3 w-3" />
            <img src={star} alt="star" className="h-3 w-3" />
            <img src={star} alt="star" className="h-3 w-3" />
          </div>
          <div className="flex items-center ">
            <div className="flex items-center justify-center bg-white w-6 h-4 lg:w-10 lg:h-6 rounded-sm border border-[rgb(45,169,197)] p-2 mt-2">
              <p className="text-[9px] lg:text-xs font-semibold">{rating.toFixed(1)}</p>
            </div>
            <p className="text-[9px] lg:text-xs font-bold pl-1 pt-2">{notice}</p>
            <p className="text-[9px] lg:text-xs font-semibold pl-1 pt-2 ">{review}</p>
          </div>
          <hr className="h-6 mt-6 hidden lg:block" />

          <div className="flex flex-col lg:flex-row items-center">
            <div className="hidden lg:flex bg-white w-12 h-12 rounded-sm border border-[rgb(45,169,197)]">
              <div className="pt-3 pl-3 h-auto w-auto">
                <img src={Heart} alt="Heart Icon" />
              </div>
            </div>
            <button className="text-[9px] text-white bg-[rgb(45,169,197)] mt-2 p-1 rounded-sm lg:py-3 w-full lg:text-base lg:mt-0 lg:ml-4">Voir Plus</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourChoices;
