import React, { useState, useEffect } from 'react';
import backgroundFooter from '../../assets/Images/Home/footer.png';

const Subscribe = () => {
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 768);
    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const SubscribeStyle = {
        width: '180px', 
        height: '58px', 
        position: 'relative',
        backgroundColor: 'rgba(45, 169, 197, 1)',
        fontSize: '0.92rem',
        fontFamily: 'Poppins, sans-serif',
    };

    if (!isLargeScreen) {
        return null;
    }

    return (
        <div 
          className="text-white flex flex-col justify-center items-center pl-10 pr-10 pt-2"
          style={{
            backgroundImage: `url(${backgroundFooter})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center 100%',
            height: '400px',
          }}
        >
          <h1 className="text-5xl font-bold mb-12 uppercase" style={{ fontFamily: 'Stroke, sans-serif' }}>Abonnez-vous et soyez à jour</h1>

          <div className="max-w-6xl mx-auto pb-2 pt-20 pl-4 pr-4 sm:pb-3 sm:pt-3 sm:pl-3 sm:pr-3 bg-white bg-opacity-40 rounded-3xl backdrop-blur-xl">
            <div className="flex flex-col sm:flex-row justify-between gap-2 sm:gap-6 ml-12 mr-12 mt-3 mb-3">
                <div className="w-full">
                    <style>
                        {`
                        .Input::placeholder {
                            color: #ADADAD;
                            font-family: 'Stroke', sans-serif;
                            padding-left: 30px;
                        }
                        `}
                    </style>
                    <input
                        className="Input appearance-none rounded-2xl pl-3 pt-2 pb-2 focus:outline-none focus:shadow-outline w-full"
                        type="text"
                        placeholder="Veuillez entrer votre adresse e-mail pour recevoir les dernières mises à jour et offres"
                        style={{
                            width: '700px', 
                            height: '58px',
                            color: '#2DA9C5',
                        }}
                    />
                </div>
                <div className="w-full">
                    <button
                        className="bg-custom text-white font-bold py-2 px-2 rounded-2xl"
                        style={SubscribeStyle}
                        type="button"
                    >
                        S'inscrire
                    </button>
                </div>
            </div>
          </div>
        </div>
    );
};

export default Subscribe;
