import React from 'react';
import Header from '../layout/Header';
import SearchBarPersonalizedTour from '../common/PersonalizedTour/PersonalizedTourPage/SearchBar';
import PersonalizedTourCard from '../common/PersonalizedTour/PersonalizedTourPage/PersonalizedTourCard';
import Footer from '../layout/Footer';

function PersonalizedTour() {   
    return (
      <div>
       <Header page="activité" />
       <SearchBarPersonalizedTour/>
       <PersonalizedTourCard/>
       <Footer/>
      </div>
    );
}

export default PersonalizedTour;