import React from 'react';
import LogoIcon from '../../../src/assets/Images/Home/guidee logo.png'; 
import LogoIconmobile from '../../../src/assets/Images/Home/logo_ee.png'; 
import fb from '../../../src/assets/Images/Home/fb.png';
import insta from '../../../src/assets/Images/Home/instagram.png';

const Footer = () => {
  return (
    <>
     
      <div className="hidden lg:flex items-center justify-between text-white h-80 pt-20" style={{ backgroundColor: 'rgb(45,169,197)' }}>
        <div className="flex flex-col items-start ml-60">
          <div className="pb-12">
            <img src={LogoIcon} alt="Logo" />
          </div>
          <p className="leading-relaxed pb-20">
            <span className="block">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </span>
            <span className="block">Vestibulum ornare libero nec mauris laoreet maximus Lorem </span>
            <span className="block">ipsum dolor sit amet.</span>
          </p>
        </div>
        
        <div className="flex flex-col items-start leading-loose pb-20">
          <a href="/">Accueil</a>
          <a href="/tours">Tours</a>
          <a href="/destinations">Destinations</a>
          <a href="/blogs">Blogs</a>
        </div>
        <div className='flex flex-col items-start mr-60 pb-40'>
          <a href="/contacter" className="mb-4 text-2xl font-semibold">Contactez-nous</a>
          <div className="flex">
            <a href="https://www.instagram.com" className="mr-4">
              <img src={insta} alt="Instagram" />
            </a>
            <a href="https://www.facebook.com">
              <img src={fb} alt="Facebook" />
            </a>
          </div>
        </div>
      </div>

     
      <div className="lg:hidden text-white pl-6 pr-6 pb-8 pt-8" style={{ backgroundColor: 'rgb(45,169,197)' }}>
        <div className="mb-6">
          <img src={LogoIconmobile} alt="Logo" />
        </div>
        <p className="mb-6">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ornare libero nec mauris laoreet maximus Lorem ipsum dolor sit amet.
        </p>
        <div className="flex flex-col mb-6 leading-loose">
          <a href="/">Accueil</a>
          <a href="/tours">Tours</a>
          <a href="/destinations">Destinations</a>
          <a href="/page">Pages</a>
          <a href="/blogs">Blogs</a>
          <a href="/contacter">Contactez-nous</a>
        </div>
        <div className="flex">
          <a href="https://www.instagram.com" className="inline-block mr-3">
            <img src={insta} alt="Instagram" />
          </a>
          <a href="https://www.facebook.com" className="inline-block">
            <img src={fb} alt="Facebook" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
