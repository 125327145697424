import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Navigation } from 'swiper/modules';
import SwiperCore from 'swiper';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import './PopularCities.css';

import imageSrc1 from '../../../assets/Images/Home/city1.png';
import imageSrc2 from '../../../assets/Images/Home/city2.png';
import imageSrc3 from '../../../assets/Images/Home/city3.png';

import fleche from '../../../assets/Images/Home/fleche.png';
import flecheweb from '../../../assets/Images/Home/fleche popular.png';

import previous from '../../../assets/Images/Home/previous_btn.png';
import next from '../../../assets/Images/Home/next_btn.png';

SwiperCore.use([EffectCoverflow, Navigation]);

const MySwiper = () => {
  const [slidesPerView, setSlidesPerView] = useState(1.5);
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [swiper, setSwiper] = useState(null);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width >= 768) {
      setIsLargeScreen(true);
      setSlidesPerView(3);
    } else {
      setIsLargeScreen(false);
      setSlidesPerView(1.5);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const slidePrev = () => {
    if (swiper) swiper.slidePrev();
  };

  const slideNext = () => {
    if (swiper) swiper.slideNext();
  };

  const updateBlurEffect = (swiper) => {
    // Ajouter l'effet flou sur toutes les images sur les petits écrans
    if (!isLargeScreen) {
      swiper.slides.forEach((slide, index) => {
        const img = slide.querySelector('img');
        if (index !== swiper.activeIndex) {
          img.classList.add('image-blur');
        } else {
          img.classList.remove('image-blur');
        }
      });
    } else {
      // Supprimer l'effet flou sur toutes les images sur les grands écrans
      swiper.slides.forEach((slide) => {
        slide.querySelector('img').classList.remove('image-blur');
      });
    }
  };
  
  
  return (
    <div className="relative">
      <div className="absolute w-full h-1/2 bg-gray-100 top-[250px] lg:top-[400px] "></div>
      <section className="swiper-section relative z-10">
        <div className="text-center pl-5 pr-5 pb-5">
          <div className="flex lg:ml-80">
            <h1 className="hidden md:block text-2xl md:text-4xl lg:text-3xl pb-10 pt-10 lg:pt-1 text-[rgb(49,77,179)] mx-auto" style={{ letterSpacing: '2px', textShadow: '2px 2px 2px rgba(0,0,0,0.2)' }} >
              TOP VILLES
            </h1>
            <div className=" hidden md:flex  mr-40">
              <button className="mx-2 shadow-lg rounded-full" onClick={slidePrev}>
                <img src={previous} alt="previous" />
              </button>
              <button className="mx-2 shadow-lg rounded-full" onClick={slideNext}>
                <img src={next} alt="Next" />
              </button>
            </div>
          </div>
          <h2 className="text-2xl font-semibold text-center mb-7 lg:text-5xl lg:pb-8">
            <span style={{ fontFamily: 'Poppins, sans-serif', color: isLargeScreen ? 'rgb(33, 155, 207)' : 'rgb(33, 162, 217)' }}>VILLES</span>
            <span style={{ color: isLargeScreen ? 'rgb(49,77,179)' : 'rgb(33, 162, 217)' }}> POPULAIRES</span>
          </h2>
        </div>
        <Swiper
          centeredSlides={true}
          slidesPerView={slidesPerView}
          loop={true}
          spaceBetween={isLargeScreen ? 100 : 25} // Augmenter l'espacement sur les grands écrans
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          }}
          effect="coverflow"
          className="mySwiper"
          onSlideChange={(swiper) => updateBlurEffect(swiper)}
          onInit={(swiper) => {
            setSwiper(swiper);
            updateBlurEffect(swiper);
          }}
        >
          <SwiperSlide>
            <div className="image-wrapper" style={isLargeScreen ? { maxWidth: '75%', margin: '0 auto', transition: 'transform 0.3s', transform: 'scale(1)' } : {}}>
              <img src={imageSrc1} alt="Bizerte"/>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="image-wrapper" style={isLargeScreen ? { maxWidth: '75%', margin: '0 auto', transition: 'transform 0.3s', transform: 'scale(1)' } : {}}>
              <img src={imageSrc2} alt="Sud Tunisien"/>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="image-wrapper" style={isLargeScreen ? { maxWidth: '75%', margin: '0 auto', transition: 'transform 0.3s', transform: 'scale(1)' } : {}}>
              <img src={imageSrc3} alt="Sousse"/>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="image-wrapper" style={isLargeScreen ? { maxWidth: '75%', margin: '0 auto', transition: 'transform 0.3s', transform: 'scale(1)' } : {}}>
              <img src={imageSrc1} alt="Bizerte2"/>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="image-wrapper" style={isLargeScreen ? { maxWidth: '75%', margin: '0 auto', transition: 'transform 0.3s', transform: 'scale(1)' } : {}}>
              <img src={imageSrc2} alt="Sud Tunisien2"/>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="image-wrapper" style={isLargeScreen ? { maxWidth: '75%', margin: '0 auto', transition: 'transform 0.3s', transform: 'scale(1)' } : {}}>
              <img src={imageSrc3} alt="Sousse2"/>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="lg:pt-10">
        <div className={`explore-button-container ${isLargeScreen ? 'web-layout-class' : ''}`}>
          <button className="explore-button font-bold" style={{ fontFamily: 'Montserrat, sans-serif'}}>Plus de villes</button>
        </div>
        </div>
      </section>
      <div className="flex justify-end relative z-10">
        <img src={isLargeScreen ? flecheweb : fleche} alt="flèche" className="w-auto" />
      </div>
    </div>
  );
};

export default MySwiper;
