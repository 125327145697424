import React, { useEffect, useState } from 'react';
import personnalizedBG from '../../../../assets/Images/PersonalizedTour/personalizedTourBG.png'; 

const MainContentPersonalizedTour = () => {
  const [showGradient, setShowGradient] = useState();
  const [bgStyle, setBgStyle] = useState();
  
  const isLargeScreen = () => window.innerWidth >= 1024;

  useEffect(() => {
    const updateBackgroundStyle = () => {
      if (isLargeScreen()) { 
        setShowGradient(true); 
        setBgStyle({
          backgroundImage: `linear-gradient(rgba(0, 120, 120, 0.2), rgba(0, 0, 255, 0.2)), url(${personnalizedBG})`,
          backgroundSize: '100% auto', 
          backgroundPosition: 'center 10%', 
          backgroundRepeat: 'no-repeat', 
          height: '100vh'
        });
      } else { 
        setShowGradient(true);
        setBgStyle({
          backgroundImage: `linear-gradient(rgba(0, 120, 120, 0.2), rgba(0, 0, 255, 0.2)), url(${personnalizedBG})`, 
          backgroundSize: '100% auto', 
          backgroundPosition: 'top left', 
          backgroundRepeat: 'no-repeat', 
          height: '30vh'
        });
      }
    };

    window.addEventListener('resize', updateBackgroundStyle);
    updateBackgroundStyle(); 

  
    return () => window.removeEventListener('resize', updateBackgroundStyle);
  }, []);

  return (
    <div>
      {/* Container for main content */}
      <div 
        className="flex flex-col justify-center items-center relative"
        style={{ 
          ...bgStyle, 
        }}
      >
        {showGradient && <div className="absolute bottom-0 left-0 right-0 h-1/4 bg-gradient-to-t from-white/100 to-transparent z-1" />}
        <div className="text-white text-center pl-4 pr-4 pt-12 lg:pt-2 z-20">
          <h1 className="sm:text-4xl md:text-5xl lg:text-7xl xl:text-7xl mb-8 sm:mb-10 md:mb-12 leading-none sm:leading-tight md:leading-loose">
            <span className="text-xs leading-loose lg:text-2xl lg:leading-loose lg:font-bold">CHOISISSEZ VOTRE TOUR</span> 
            <span className="block sm:inline md:block font-bold text-xl lg:text-6xl">TOURS PERSONNALISES</span>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default MainContentPersonalizedTour; 
