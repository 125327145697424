import { configureStore } from '@reduxjs/toolkit';
import userReducer from './features/user/userSlice';
import toursReducer from './features/tour/tourSlice';
import contactReducer from './features/contact us/contactSlice';
import personalizedToursReducer from './features/personalizedTour/personalizedTourSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    tours: toursReducer,
    contact: contactReducer,
    personalizedTours: personalizedToursReducer
  },
});

export default store;