import React from 'react';
import HeaderTourDetails from '../layout/HeaderTourDetails';
import GlobalContent from '../common/TourDetailsPage/GlobalContent';
import Footer from '../layout/Footer';

function TourInformation() {   
    return (
      <div>
       <HeaderTourDetails/>
       <GlobalContent/>
       <Footer/>

      </div>
    );
}

export default TourInformation;