import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import backgroundImage from '../../../assets/Images/Home/background4.png';
import HomePageBG from '../../../assets/Images/Home/HomepageBG.png';


const MainContent = () => {

  const [selectedDate, setSelectedDate] = useState(null);

  const DateChange = (date) => {
      setSelectedDate(date);
  };

  const clearSelectedDate = () => {
      setSelectedDate(null);
  };

  const handleKeyDown = (event) => {
      if (event.key === 'Delete' || event.key === 'Backspace') {
          clearSelectedDate();
      }
  };

  const DatePickerCalender = ({ value, onClick }) => (
      <div className="relative">
          <input
              type="text"
              value={value}
              onClick={onClick}
              placeholder="Quand ? "
              className="custom-select text-sm rounded-2xl block w-full pl-3 font-bold"
              style={{ ...uniformStyle}}
              onKeyDown={handleKeyDown}
          />
      </div>
  );

  const uniformStyle = {
    width: '215px', 
    height: '58px', 
    position: 'relative',
    backgroundColor: 'white',
    fontSize: '0.99rem',
    paddingRight: '2rem', 
    backgroundRepeat: `no-repeat, repeat-x, repeat-x`,
    backgroundPosition: `right 0.75rem top 50%, right 0.25rem center, right 0.5rem center`,
    backgroundSize: `1.25em auto, calc(0.5em + 0.25rem) calc(0.5em + 0.25rem), calc(0.5em + 0.25rem) calc(0.5em + 0.25rem)`,
  };
  const stroke ={
    'color': '#fff',
    '-webkit-text-fill-color': 'transparent',
    '-webkit-text-stroke-width':'3px',
  }

  return (
    <div className="mb-15">
      {/* Web Version */}
      <div className="hidden lg:block">
        <div 
          className="flex flex-col justify-center items-center min-h-screen pt-20 relative"
          style={{ 
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center 20%',
          }}
        >
          <div className="text-white lg:text-center p-4   mt-2" style={{ fontFamily: 'Poppins, sans-serif' }}>
            <h1 className="text-2xl sm:text-4xl md:text-5xl lg:text-[70px] font-bold mb-8 sm:mb-10 md:mb-12 leading-none sm:leading-tight md:leading-none">
              VIVEZ L'EXPERIENCE A LA
              <span className="block sm:inline md:block leading-normal"> MANNIERE <span style={{...stroke}}>TUNISIENNE</span></span>
            </h1>
          </div>

          <div className="max-w-6xl mx-auto pb-2 pt-2 pl-4 pr-4 sm:pb-3 sm:pt-3 sm:pl-3 sm:pr-3 bg-white bg-opacity-40 rounded-3xl shadow-lg backdrop-blur-sm lg:mt-2">
            <div className="flex flex-col sm:flex-row justify-between gap-2 sm:gap-6 ml-2 mr-2">
              <div className="w-full sm:w-1/2 p-2">
                
                <DatePicker
                  selected={selectedDate}
                  onChange={DateChange}
                  customInput={<DatePickerCalender />}
                  dateFormat="dd/MM/yyyy"
                  
                  />
              </div>

              <div className="w-full sm:w-1/4 p-2">
                <form className="w-50">
                  <select id="countries1" 
                    className="custom-select bg-gray-50 border border-gray-300 text-sm rounded-2xl block w-full pl-3 font-bold"
                    style={{ ...uniformStyle, color: 'rgba(45, 169, 197, 1)' }}>
                    <option selected>Où ? </option>
                    <option value="US">Sousse</option>
                    <option value="US">Sousse</option>
                    <option value="US">Sousse</option>
                  </select>
                </form>
              </div>

              <div className="w-full sm:w-1/4 p-2">
                <form className="w-50">
                  <select id="countries1" 
                    className="custom-select bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-2xl block w-full pl-3 font-bold"
                    style={{ ...uniformStyle, color: 'rgba(45, 169, 197, 1)' }}>
                    <option selected>Sélectionner le type </option>
                    <option value="US">Sousse</option>
                  </select>
                </form>
              </div>

              <div className="w-full sm:w-1/4 p-2">
                <button
                  className="bg-custom hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-2xl text-sm sm:text-base"
                  style={{ ...uniformStyle, backgroundColor: 'rgba(45, 169, 197, 1)' }}
                  type="button">
                  Trouver maintenant
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Version */}
      <div className="lg:hidden">
      <div 
        className="flex flex-col justify-center items-center relative"
        style={{ 
          backgroundImage: `url(${HomePageBG})`,
          backgroundSize: '100% auto',
          backgroundPosition: 'top left', 
          height: '50vh',
      
        }}
      >
        <div className="absolute bottom-0 left-0 right-0 h-1/4 bg-gradient-to-t from-white/100 to-transparent z-1" />
        <div className="text-white text-center px-4 pt-10 mt-20 z-20">
         <h1 className="text-2xl lg:text-7xl font-bold sm:leading-snug lg:leading-snug mt-40 mb-8">
          <span className="block">VIVEZ L'EXPERIENCE A LA</span> 
          <span className="block">MANNIERE TUNISIENNE</span>
         </h1>  
        </div>


      <div class="flex justify-center items-center" >
      <div className="flex flex-col bg-white bg-opacity-50 rounded-3xl shadow-lg p-4 backdrop-blur">
      <div class="flex justify-between space-x-2">
      <select class="custom-select bg-white pl-3 pr-2 py-3 rounded-xl pt-1 text-[rgb(33,162,217)]" aria-label="Sélectionner le type">
        <option value="type">Sélectionner le type</option>
        <option value="type">Sélectionner le type</option>
        <option value="type">Sélectionner le type</option>

      </select>
      <select class="custom-select bg-white pl-3 pr-4 pt-2 py-3 rounded-xl  text-[rgb(33,162,217)]" aria-label="Quand ?">
        <option value="date">Quand ?</option>
        <option value="date">Quand ?</option>
        <option value="date">Quand ?</option>
        <option value="date">Quand ?</option>
      </select>
      </div>
      <button className="mt-4 w-full bg-[rgb(33,162,217)] hover:bg-[rgb(33,162,217)] text-white py-3 px-12 rounded-2xl transition duration-300 ease-in-out transform hover:-translate-y-1">
       Trouver maintenant
     </button>
  </div>
</div>
</div>
</div>
</div>
);
};

export default MainContent;
