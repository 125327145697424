import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../features/user/userSlice';
import { useNavigate } from 'react-router-dom';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import Account_bg from '../../assets/Images/Account/Account_bg.png';
import guidee_white_logo from '../../assets/Images/Account/guidee logo white.png';
import guideeLogo from '../../assets/Images/Account/guidee logo.png';
import mobileBackground from '../../assets/Images/Account/mobile_bg.png';
import { Link } from 'react-router-dom';
import Facebook_icon from '../../assets/Images/Account/facebook.png';
import google_icon from '../../assets/Images/Account/google.png';
import apple_icon from '../../assets/Images/Account/apple.png';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userStatus = useSelector(state => state.user.status);
  const userError = useSelector(state => state.user.error);
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    if (userStatus === 'login failed' && userError) {
      alert(`Échec de la connexion : ${userError.message || userError}`);
      setLoading(false);
    }
  }, [userStatus, userError]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isCheckboxChecked) {
      alert('Veuillez accepter les conditions de se souvenir de moi');
      return;
    }
    const email = event.target.email.value;
    const password = event.target.password.value;
    setLoading(true);
  
    try {
      const response = await dispatch(loginUser({ email, password })).unwrap();
      console.log('Login response:', response);
  
      // Navigate to profile on successful login
      setLoading(false);
      setTimeout(() => {
        navigate("/Profile");
      }, 2000);
    } catch (error) {
      console.error('Login error:', error); // Log the error for debugging
      alert(`Échec de la connexion : ${error.message}`);
      setLoading(false);
      // Ensure to clear any previously set tokens
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('userRole');
      localStorage.removeItem('userId');
    }
  };
  
  
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  if (isMobile) {
    // Version mobile
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-cover bg-center" 
           style={{ backgroundImage: `url(${mobileBackground})` }}>
        {loading && (
          <div className="absolute z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white"></div>
          </div>
        )}
        <div className="pb-20 pt-20">
          <img src={guideeLogo} alt="Guidee Registration Logo" />
        </div>
        <div className="w-full">
          <form className="bg-gray-700/40 backdrop-blur-[2px] rounded-t-[50px] px-16 pt-12 pb-8 min-h-screen"  onSubmit={handleSubmit}>
            <p className="flex justify-center text-2xl font-bold pb-6 text-white ">Se Connecter</p>
            <div className="mb-4">
              <label className="text-xs text-white">Adresse e-mail</label>
              <input
                className="shadow appearance-none border rounded w-full h-9 py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-400 text-xs" required
                id="email"
                type="email"
                name="email"
                placeholder="exemple@gmail.com"/>
            </div>
            <div className="mb-4 relative">
              <label className="text-xs text-white">Mot de passe</label>
              <div className="relative">
                <input
                  className="shadow appearance-none border rounded w-full h-9 py-2 px-2 pr-9 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-400 text-xs"
                  required
                  id="password"
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  placeholder="*************"
                />
                <button
                  className="absolute inset-y-0 right-0 px-3 flex items-center justify-center text-gray-400"
                  onClick={togglePasswordVisibility}
                  type="button"
                >
                  {passwordVisible ? <EyeOffIcon className="h-5 w-5" /> : <EyeIcon className="h-5 w-5" />}
                </button>
              </div>
            </div>
            <div className="mb-6 flex items-center">
              <input
                type="checkbox"
                checked={isCheckboxChecked}
                onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                className="mr-2 leading-tight"
                id="agree"/>
              <label htmlFor="agree" className="text-[10px] text-white">
                Se souvenir de moi
              </label>
              <span className="text-[10px] ml-auto" style={{ fontFamily: 'Poppins, sans-serif' }}><Link to="/ForgetPassword" className="text-[rgb(45,169,197)]"> Mot de passe oublié ?</Link></span>
            </div>
            <div className="flex items-center">
              <button className="bg-[rgb(49,77,179)] w-full text-xs text-white font-semibold py-3 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                Se connecter
              </button>
            </div>
            <div className="flex items-center justify-center pt-3 w-full pb-8"> 
              <span className="text-[10px] text-white text-center" style={{ fontFamily: 'Poppins, sans-serif' }}>
                Vous n'avez pas de compte ?  <Link to="/CreateAccount" className="text-[rgb(45,169,197)]">S'inscrire</Link>
              </span>
            </div>
            <div className="relative text-center pb-6 pt-4">
              <div className="flex items-center justify-center gap-x-1 ">
                <div className="flex-1 border-t border-gray-300"></div>
                <span className="px-2 text-[10px] text-gray-400 " style={{ fontFamily: 'Poppins, sans-serif', background: 'transparent' }}>
                  Ou connectez-vous avec
                </span> 
                <div className="flex-1 border-t border-gray-300"></div>
              </div>
            </div>
            <div className="flex justify-center">
              <div className="w-[75px] h-10 bg-white/50 backdrop-blur-sm rounded-[4px] flex items-center justify-center mx-1 border border-[rgb(45,169,197)]">
                <img src={Facebook_icon} alt='Facebook_icon' className="w-4 h-4"></img>
              </div>
              <div className="w-[75px] h-10 bg-white/50 backdrop-blur-sm rounded-[4px] flex items-center justify-center mx-1 border border-[rgb(45,169,197)]">
                <img src={google_icon}  alt='google_icon'className="w-4 h-4"></img>
              </div>
              <div className="w-[75px] h-10 bg-white/50 backdrop-blur-sm rounded-[4px] flex items-center justify-center  border border-[rgb(45,169,197)]">
                <img src={apple_icon} alt='google_icon' className="w-4 h-4"></img>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    // Version web
    return (
      <div className="flex justify-center items-center h-screen">
        {loading && (
          <div className="absolute z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white"></div>
          </div>
        )}
        <div className="w-1/2 bg-white justify-center ml-20 pl-20">
          <img src={guidee_white_logo} alt="Guidee Logo" className="pb-12 pt-20 w-auto"></img>
          <h2 className="text-4xl font-bold pb-4" style={{ fontFamily: 'Poppins, sans-serif' }}>Se Connecter</h2>
          <p className="text-base pb-8 text-[rgb(17,34,17)]" style={{ fontFamily: 'Poppins, sans-serif' }}>Connectez-vous pour accéder à votre compte Guidee</p>
          <form onSubmit={handleSubmit} className="w-full max-w-md">
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <div className="relative">
                  <input type="email" id="email" name="email" className="block w-[500px] px-3 py-[14px] border border-gray-700 rounded-[4px] focus:border-blue-500" required placeholder="Adresse email"/>
                  <span className="absolute top-[-12px] left-2 bg-white px-1 text-base text-[rgb(28,27,31)] font-normal">Adresse email</span>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <div className="relative">
                  <input type={passwordVisible ? "text" : "password"} id="password" name="password" className="block w-[500px] px-3 py-[14px] border border-gray-700 rounded-[4px] focus:border-blue-500" required placeholder="******************"/>
                  <button type="button" onClick={togglePasswordVisibility} className="absolute inset-y-0 right-[-40px] flex items-center ">
                    {passwordVisible ? <EyeOffIcon className="h-5 w-5 text-gray-400" /> : <EyeIcon className="h-5 w-5 text-gray-400" />}
                  </button>
                  <span className="absolute top-[-12px] left-2 bg-white px-1 text-base text-[rgb(28,27,31)] font-normal">Mot de passe</span>
                </div>
              </div>
            </div>
            <div className="mb-6 flex items-center w-[500px]">
              <input type="checkbox" checked={isCheckboxChecked} onChange={(e) => setIsCheckboxChecked(e.target.checked)} className="mr-2" />
              <span className="text-sm" style={{ fontFamily: 'Poppins, sans-serif' }}>Se souvenir de moi </span>
              <span className="text-sm ml-auto" style={{ fontFamily: 'Poppins, sans-serif' }}><Link to="/ForgetPassword" className="text-[rgb(45,169,197)]"> Mot de passe oublié ?</Link></span>
            </div>
            <button type="submit" className="block w-[500px] bg-[rgb(0,35,161)] text-white px-4 py-3 rounded-[4px] mb-3 font-normal">Se connecter</button>
          </form>
          <div className="w-full max-w-md">
            <div className="pb-6 flex items-center justify-center">
              <span className="text-[14px]" style={{ fontFamily: 'Poppins, sans-serif' }} >Vous n'avez pas de compte ?  <Link to="/CreateAccount" className="text-[rgb(45,169,197)]"> S'inscrire</Link></span>
            </div>
            <div className="relative text-center pb-6 w-[500px]">
              <hr className="my-6 border-gray-300" />
              <span className="absolute top-[-12px] left-1/2 transform -translate-x-1/2 bg-white px-1 text-[14px] text-gray-400 " style={{ fontFamily: 'Poppins, sans-serif' }}>Ou connectez-vous avec</span>
            </div>
            <div className="flex justify-center w-[500px]">
              <div className="w-80 h-16 bg-white rounded-[4px] flex items-center justify-center mx-1 border border-[rgb(45,169,197)]">
                <img src={Facebook_icon} alt='f_icon'></img>
              </div>
              <div className="w-80 h-16 bg-white rounded-[4px] flex items-center justify-center mx-1 border border-[rgb(45,169,197)]">
                <img src={google_icon} alt='g_icon'></img>
              </div>
              <div className="w-80 h-16 bg-white rounded-[4px] flex items-center justify-center mx-1 border border-[rgb(45,169,197)]">
                <img src={apple_icon} alt='a_icon'></img>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/2">
          <img src={Account_bg} alt="Background" className="w-full h-auto" />
        </div>
      </div>
    );
  }
};

export default Login;
