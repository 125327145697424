import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchAcceptedTours = createAsyncThunk(
  'tours/fetchAcceptedTours',
  async () => {
    const response = await fetch('http://localhost:8000/api/accepted-tours');
    const data = await response.json();
    return data.map(tour => ({
      imageSrc: `http://localhost:8000/${tour.images[0]}`,
      title: tour.titre,
      price: tour.prix,
      description: tour.description,
      rating: 5, 
      heureDepart: tour.heureDepart,
      heureArrivee: tour.heureArrivee,
      destinations: tour.destinations,
      nonInclus: tour.nonInclus,
      inclus: tour.inclus,
      dressCode: tour.dressCode,
      galerie: tour.galerie || [], 
    }));
  }
);

export const fetchToursByType = createAsyncThunk(
  'tours/fetchToursByType',
  async (type) => {
    const response = await fetch(`http://localhost:8000/api/tours/type?type=${type}`);
    const data = await response.json();
    return data.map(tour => ({
      imageSrc: `http://localhost:8000/${tour.images[0]}`,
      title: tour.titre,
      price: tour.prix,
      description: tour.description,
      rating: 5,
      heureDepart: tour.heureDepart,
      heureArrivee: tour.heureArrivee,
      destinations: tour.destinations,
      nonInclus: tour.nonInclus,
      inclus: tour.inclus,
      dressCode: tour.dressCode,
      galerie: tour.galerie || [],
    }));
  }
);

export const fetchToursByFilters = createAsyncThunk(
  'tours/fetchToursByFilters',
  async (filters) => {
    const queryString = new URLSearchParams(filters).toString();
    const response = await fetch(`http://localhost:8000/api/tours/filters?${queryString}`);
    const data = await response.json();
    return data.map(tour => ({
      imageSrc: `http://localhost:8000/${tour.images[0]}`,
      title: tour.titre,
      price: tour.prix,
      description: tour.description,
      rating: 5,
      heureDepart: tour.heureDepart,
      heureArrivee: tour.heureArrivee,
      destinations: tour.destinations,
      nonInclus: tour.nonInclus,
      inclus: tour.inclus,
      dressCode: tour.dressCode,
      galerie: tour.galerie || [],
    }));
  }
);

export const addFavoriteTour = createAsyncThunk(
  'tours/addFavoriteTour',
  async ({ tourId, userId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        'http://localhost:8000/api/add/favorite',
        { tourId, userId }, // Ensure the request body includes tourId and userId
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`, // Include the token in the headers
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({ message: 'An error occurred' });
      }
    }
  }
);








  const toursSlice = createSlice({
    name: 'tours',
    initialState: {
      tours: [],
      status: 'idle', 
      error: null
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
      //Accepted Tour
        .addCase(fetchAcceptedTours.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchAcceptedTours.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })
        .addCase(fetchAcceptedTours.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.tours = action.payload;
        })
        //fetch tour by type
        .addCase(fetchToursByType.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchToursByType.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })
        .addCase(fetchToursByType.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.tours = action.payload;
        })
        //fetch tour by filter
        .addCase(fetchToursByFilters.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchToursByFilters.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        })
        .addCase(fetchToursByFilters.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.tours = action.payload;
        })
        //add favorite tour
        .addCase(addFavoriteTour.fulfilled, (state, action) => {
          const { tourId, userId } = action.meta.arg;
          const tour = state.tours.find(tour => tour._id === tourId);
          if (tour) {
            tour.favorie.push(userId);
          }
        })
        .addCase(addFavoriteTour.rejected, (state, action) => {
          state.error = action.error.message;
        });
    }
  });
  

  
export default toursSlice.reducer;