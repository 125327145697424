import React from 'react';
import Header from '../layout/Header';
import Services from '../common/HomePage/ServiceContent';
import Carousel from '../common/HomePage/PopularCities';
import HowItWorks from '../common/HomePage/HowItWorks';
import PopularDestination from '../common/HomePage/PersonalizeExperience';
import TravelExperts from '../common/HomePage/TravelExperts';
import Subscribe from '../layout/Subscribe';
import Footer from '../layout/Footer';
function Home() {   
    return (
      <div className='home_container'>
       <Header page="home" />
       <HowItWorks/>
       <Carousel /> 
       <PopularDestination/>
       <TravelExperts/>
       <Services/>
       <Subscribe/>
       <Footer/>
      </div>
    );
}

export default Home;