import React from 'react';
import Header from '../layout/Header';
import SubCategoryChoiceDetail from '../common/PersonalizedTour/PersonalizedTourSubCategoryChoiceDetailsPage/SubCategoryChoiceDetails';


function SubCategoryChoiceDetails() {   
  
    return (
      <div>
       <Header page="activité" />
       <SubCategoryChoiceDetail/>
       
      </div>
    );
}

export default SubCategoryChoiceDetails;