import React from 'react';
import Navbar from './Navbar';
import MainContent from '../common/HomePage/MainContent';
import MainContentPC from '../common/DestinationPage/MainContentPC';
import MainContentTour from '../common/TourPage/MainContentTour';
import MainContentPersonalizedTour from '../common/PersonalizedTour/PersonalizedTourPage/MainContentPersonalisedTour';
import MainContentCU from '../common/ContactUsPage/MainContentCU';

function Header({ page }) {
  let mainContentComponent;

  switch (page) {
    case 'home':
      mainContentComponent = <MainContent />;
      break;
    case 'destination': 
      mainContentComponent = <MainContentPC />;
      break;
    case 'tour':
      mainContentComponent = <MainContentTour />;
      break;
    case 'activité':
        mainContentComponent = <MainContentPersonalizedTour />;
        break;  
    case 'contactez-nous':
        mainContentComponent = <MainContentCU />;
        break;  
    default:
      mainContentComponent = <MainContent />;
      break;
  }

  return (
    <div className='header_container'>
      <Navbar transparentBackground={true}/>
      {mainContentComponent}
    </div>
  );
}

export default Header;
