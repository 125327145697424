import React, { useState, useEffect } from 'react';
import Navbar from './Navbar'; 
import TourNavbar from './TourNavbar'; 
import MainContentTourInfo from '../common/TourDetailsPage/MainContentTourDetails';


function HeaderTour() {
    // State to track whether the screen size is desktop or not
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 600);

    // Effect hook to handle resizing of the window
    useEffect(() => {
        // Function to update the state based on window width
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 600);
        };

        // Event listener to listen for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup function to remove event listener when component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Rendering the header container with Navbar or TourNavbar based on screen size
    return (
        <div className='header_container'>
            {/* Conditional rendering: Navbar for desktop, TourNavbar for mobile */}
            {isDesktop ? <Navbar transparentBackground={true}/> : <TourNavbar transparentBackground={true}/>}
            <MainContentTourInfo/> 
        </div>
    );
}

export default HeaderTour;
