import React, { useEffect, useState } from 'react';
import map_image from '../../../assets/Images/TourLocation/map ghar el melh 1.png';
import map_image2 from '../../../assets/Images/TourLocation/map 2.png';

const mapImages = [map_image, map_image2]; // Liste des images pour chaque destination

const Location = () => {
  const [tourData, setTourData] = useState({ destinations: [] });

  useEffect(() => {
    const storedData = sessionStorage.getItem('tourData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      console.log("Parsed Data from Storage:", parsedData);
      setTourData(parsedData);
    }
  }, []);

  return (
    <div>
      <div className="">
        <h1 className="pt-20 pb-3 lg:pb-3 mr-4 text-2xl lg:text-4xl font-bold lg:text-2xl mt-0 mb-0 lg:pt-40 text-[rgb(24,30,75)]">Emplacement</h1>
      </div>
      
      {tourData.destinations.map((destination, index) => (
        <div key={index} className="flex flex-col lg:pb-4">
          <h1 className="lg:text-xl lg:font-bold text-sm font-semibold text-[rgb(24,30,75)] pb-4 lg:pb-0" style={{ fontFamily: 'Poppins, sans-serif'}}>
            Jour {index + 1} : {destination.titre}
          </h1>
          <p className="lg:text-base lg:pt-2 text-xs pb-2 hidden lg:block">{destination.description || 'Description non spécifiée'}</p>
          <img className="lg:mr-5 mr-2 w-auto h-auto" src={mapImages[index % mapImages.length]} alt={`map_image_${index + 1}`}></img>
        </div>
      ))}
    </div>
  );
};

export default Location;
