import React from 'react';
import Header from '../layout/Header';
import SubCategoryChoices from '../common/PersonalizedTour/PersonalizedTourSubCategoryChoicesPage/subCategoryChoices';
import { useParams } from 'react-router-dom';

function SubCategoryChoicesPage() {
    const { id } = useParams(); 

    return (
        <div>
            <Header page="activité" />
            <SubCategoryChoices tourId={id} /> 
        </div>
    );
}

export default SubCategoryChoicesPage;