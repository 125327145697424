import React, { useState, useEffect } from 'react';
import star from '../../../../assets/Images/Tour/star.png';
import Alaram from '../../../../assets/Images/PersonalizedTour/equitationDetails/Alarm.png';
import ticket from '../../../../assets/Images/PersonalizedTour/equitationDetails/icon_ticket.png';
import duration from '../../../../assets/Images/PersonalizedTour/equitationDetails/icon_duration.png';
import location from '../../../../assets/Images/PersonalizedTour/equitationDetails/icons_location.png';
import guide from '../../../../assets/Images/PersonalizedTour/equitationDetails/icon_guide.png';
import language from '../../../../assets/Images/PersonalizedTour/equitationDetails/icon_language.png';
import transport from '../../../../assets/Images/PersonalizedTour/equitationDetails/transport.png';
import BookingPersonalizedTour from './BookingPersonalizedTour';
import { useParams } from 'react-router-dom';



const SubCategoryChoiceDetails = () => {
  const { id } = useParams();
  const [choiceData, setChoiceData] = useState({});
  

  useEffect(() => {
    const fetchChoiceData = async (id) => {
      try {
        const response = await fetch(`http://localhost:8000/api/Personalizedtour/choice/${id}`);
        const data = await response.json();
        setChoiceData(data); 
      } catch (error) {
        console.error("Erreur lors de la récupération des données du choix :", error);
      }
    };

    if (id) {
      fetchChoiceData(id); 
    }
  }, [id]);

  return (
    <div className="max-w-screen-lg mx-auto bg-white rounded-t-3xl lg:rounded-none p-8 relative top-[-40px] left-0 right-0 mb-5 flex flex-col lg:flex-row lg:shadow-2xl lg:mt-20">
      <div className="lg:flex-1 lg:mt-10 lg:w-full">
        <div className="flex items-center px-4">
          <p className="text-base font-bold lg:text-3xl">{choiceData.nom}</p>
          <p className="text-[rgb(0,35,161)] font-bold ml-1 lg:text-2xl lg:mt-2">{choiceData.prix}Dt</p>
          <p className="text-xl text-gray-400 ml-1 lg:text-2xl lg:mt-2">/</p>
          <p className="text-[10px] text-gray-500 mt-1 lg:text-base lg:mt-3">Par Heure</p>
        </div>
        <div className="flex items-center">
          <div className="flex gap-1 pl-4">
            <img src={star} alt="star" className="h-3 w-3" />
            <img src={star} alt="star" className="h-3 w-3" />
            <img src={star} alt="star" className="h-3 w-3" />
            <img src={star} alt="star" className="h-3 w-3" />
            <img src={star} alt="star" className="h-3 w-3" />
          </div>
          <p className="text-[11px] pl-3 lg:text-base text-[rgb(94,98,130)]">(5 avis)</p>
        </div>
        <div className="grid grid-cols-1 gap-4 mt-8 mx-4">
          {choiceData.galerie && choiceData.galerie.length > 0 && (
            <>
              <div className="col-span-1">
                <img src={`http://localhost:8000/${choiceData.galerie[0]}`} alt={choiceData.nom} className="w-full object-cover" />
              </div>
              <div className="grid grid-cols-3 gap-4">
                {choiceData.galerie.slice(1, 4).map((img, index) => (
                  <div key={index} className="col-span-1">
                    <img src={`http://localhost:8000/${img}`} alt={`${choiceData.nom} ${index + 2}`} className="w-full object-cover" />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <p className="font-bold text-xl mt-6 mb-3 lg:text-2xl lg:mt-12">Détails</p>
        <p className="text-[13px] text-black leading-loose font-semibold lg:text-[16px] ">
          {choiceData.description}
        </p>
        
        <div className="flex flex-col lg:mt-8">
          <div className="flex items-center">
            <img className="w-auto h-auto mr-3" src={Alaram}></img>
            <p className="font-bold text-base mr-1">Horaires:</p>
            <div className="flex flex-col pt-1 pt-5">
              <p className="text-xs lg:text-sm lg:w-[300px] w-[155px] pt-8 lg:pt-0">{choiceData.horaires}</p>
            </div>
          </div>
          <div className="flex items-center mt-4">
            <img className="w-auto h-auto mr-3" src={ticket}></img>
            <p className="font-bold text-base mr-1">Frais d'entrée:</p>
            <div className="flex flex-col">
              <p className="text-xs lg:text-sm ">{choiceData.fraisEntree}</p>
            </div>
          </div>
          <div className="flex items-center mt-4">
            <img className="w-auto h-auto mr-3" src={duration}></img>
            <p className="font-bold text-base mr-1">Durée:</p>
            <p className="text-xs lg:text-sm">{choiceData.duree}h</p>
          </div>
          <div className="flex items-center mt-4">
            <img className="w-auto h-auto mr-3" src={location}></img>
            <p className="font-bold text-base mr-1">Emplacement:</p>
            <p className="text-xs lg:text-sm">{choiceData.emplacement}</p>
          </div>
          <div className="flex items-center mt-4">
            <img className="w-auto h-auto mr-3" src={guide}></img>
            <p className="font-bold text-base mr-1">Service De Guide:</p>
            <p className="text-xs lg:text-sm">{choiceData.serviceGuide ? "Inclus" : "Non inclus"}</p>
          </div>
          <div className="flex items-center mt-4">
            <img className="w-auto h-auto mr-3" src={language}></img>
            <p className="font-bold text-base mr-1">Langue:</p>
            <p className="text-xs lg:text-sm">{choiceData.langues && choiceData.langues.length > 0 ? choiceData.langues.join(", ") : "Non spécifié"}</p>
          </div>
          <div className="flex items-center mt-4">
            <img className="w-auto h-auto mr-3" src={transport}></img>
            <p className="font-bold text-base mr-1">Transport d'Entrée:</p>
            <p className="text-xs lg:text-sm">{choiceData.transport}</p>
          </div>
        </div>

        <p className="text-xl font-bold mt-10 lg:text-2xl">Localisation</p>
        <div className="flex justify-center items-center mt-4 lg:justify-start">
          <img src={`http://localhost:8000/${choiceData.localisation}`} alt="Description of map" />
        </div>
        {/* booking form */}
      </div>
      <BookingPersonalizedTour />
    </div>
  );
};

export default SubCategoryChoiceDetails;
