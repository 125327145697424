import axios from 'axios';
import store from '../store';
import { refreshToken } from '../../src/features/user/userSlice';

// Configurer un intercepteur pour rafraîchir le token
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      console.log('Token expired, attempting to refresh...');
      originalRequest._retry = true;
      try {
        const accessToken = await store.dispatch(refreshToken()).unwrap();
        console.log('Token refreshed:', accessToken);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
        originalRequest.headers['Authorization'] = 'Bearer ' + accessToken;
        return axios(originalRequest);
      } catch (refreshError) {
        console.error('Failed to refresh token:', refreshError);
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);
