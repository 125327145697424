import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { signupUser } from '../../features/user/userSlice';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import desktopBackground from '../../assets/Images/Account/Account_bg.png';
import mobileBackground from '../../assets/Images/Account/mobile_bg.png';
import guideeLogoWhite from '../../assets/Images/Account/guidee logo white.png';
import guideeLogo from '../../assets/Images/Account/guidee logo.png';
import Facebook from '../../assets/Images/Account/facebook.png';
import google from '../../assets/Images/Account/google.png';
import apple from '../../assets/Images/Account/apple.png';

const CreateAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userStatus = useSelector(state => state.user.status);
  const userError = useSelector(state => state.user.error);
  const [loading, setLoading] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    if (userStatus === 'succeeded') {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        navigate("/ActivateAccount");
      }, 3000);
    } else if (userStatus === 'failed' && userError) {
      alert(`Échec de la création du compte : ${userError}`);
    }
  }, [userStatus, userError, navigate]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isCheckboxChecked) {
      alert('Veuillez accepter les conditions et politiques de confidentialité avant de continuer.');
      return;
    }
    
    const formData = new FormData(event.target);
    
    // Envoyer les données via Redux
    dispatch(signupUser(formData));
  };
  
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  if (isMobile) {
    // Mobile version
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-cover bg-center" 
           style={{ backgroundImage: `url(${mobileBackground})` }}>
        {loading && (
          <div className="absolute z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white"></div>
          </div>
        )}
        <div className="pb-20 pt-20">
          <img src={guideeLogo} alt="Guidee Registration Logo" />
        </div>
        <form className="bg-gray-700/40 backdrop-blur-[2px] rounded-t-[50px] px-16 pt-6 pb-8 min-h-screen" onSubmit={handleSubmit}>
          <p className="flex justify-center text-2xl font-bold pb-6 text-white">Créer un compte</p>
          <div className="flex space-x-4 mb-4"> 
            <div className="flex-1">
              <label className="text-xs text-white">Prénom</label>
              <input
                className="shadow appearance-none border rounded w-full h-9 py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-400 text-xs"
                required
                id="prenom"
                type="text"
                name="prenom"
                placeholder="Lorem"
              />
            </div>
            <div className="flex-1">
              <label className="text-xs text-white">Nom</label>
              <input
                className="shadow appearance-none border rounded w-full h-9 py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-400 text-xs"
                required
                id="nom"
                type="text"
                name="nom"
                placeholder="Ipsum"
              />
            </div>
          </div>
          <div className="flex space-x-4 mb-4"> 
            <div className="flex-1">
              <label className="text-xs text-white">Adresse</label>
              <input
                className="shadow appearance-none border rounded w-full h-9 py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-400 text-xs"
                required
                id="adresse"
                type="text"
                name="adresse"
                placeholder="Tunis, rue 09"
              />
            </div>
            <div className="flex-1">
              <label className="text-xs text-white">Cin</label>
              <input
                className="shadow appearance-none border rounded w-full h-9 py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-400 text-xs"
                required
                id="cin"
                type="text"
                name="cin"
                placeholder="12547896"
              />
            </div>
          </div>
          <div className="flex space-x-4 mb-4"> 
            <div className="flex-1">
              <label className="text-xs text-white">Origine</label>
              <input
                className="shadow appearance-none border rounded w-full h-9 py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-400 text-xs"
                required
                id="origine"
                type="text"
                name="origine"
                placeholder="Tunisie"
              />
            </div>
            <div className="flex-1">
              <label className="text-xs text-white">Statut</label>
              <input
                className="shadow appearance-none border rounded w-full h-9 py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-400 text-xs"
                required
                id="statut"
                type="text"
                name="statut"
                placeholder="Célibataire"
              />
            </div>
          </div>
          <div className="mb-4">
            <label className="text-xs text-white">Adresse e-mail</label>
            <input
              className="shadow appearance-none border rounded w-full h-9 py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-400 text-xs"
              required
              id="email"
              type="email"
              name="email"
              placeholder="exemple@gmail.com"
            />
          </div>
          <div className="mb-4">
            <label className="text-xs text-white">Numéro de téléphone</label>
            <input
              className="shadow appearance-none border rounded w-full h-9 py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-400 text-xs"
              required
              id="numero_telephone"
              type="tel"
              name="numero_telephone"
              placeholder="+216 22333222"
            />
          </div>
          <div className="mb-4">
            <label className="text-xs text-white">Photo de profil</label>
            <input
              className="shadow appearance-none border rounded w-full h-9 py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-400 text-xs"
              id="profilePhoto"
              type="file"
              name="profilePhoto"
            />
          </div>
          <div className="mb-4">
            <label className="text-xs text-white">Photo de couverture</label>
            <input
              className="shadow appearance-none border rounded w-full h-9 py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-400 text-xs"
              id="coverPhoto"
              type="file"
              name="coverPhoto"
            />
          </div>
          <div className="mb-4 relative">
            <label className="text-xs text-white">Mot de passe</label>
            <div className="relative">
              <input
                className="shadow appearance-none border rounded w-full h-9 py-2 px-2 pr-9 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-400 text-xs"
                required
                id="password"
                type={passwordVisible ? "text" : "password"}
                name="password"
                placeholder="*************"
              />
              <button
                className="absolute inset-y-0 right-0 px-3 flex items-center justify-center text-gray-400"
                onClick={togglePasswordVisibility}
                type="button"
              >
                {passwordVisible ? <EyeOffIcon className="h-5 w-5" /> : <EyeIcon className="h-5 w-5" />}
              </button>
            </div>
          </div>
          <div className="mb-6">
            <input type="checkbox" checked={isCheckboxChecked} onChange={(e) => setIsCheckboxChecked(e.target.checked)} className="mr-2 leading-tight" id="agree" />
            <label htmlFor="agree" className="text-[9px] text-white">
              J'accepte toutes les conditions et politiques de confidentialité
            </label>
          </div>
          <div className="flex items-center">
            <button className="bg-[rgb(49,77,179)] w-full text-xs text-white font-semibold py-3 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
              Créer un compte
            </button>
          </div>
          <div className="flex items-center justify-center pt-3 w-full">
            <span className="text-[10px] text-white text-center" style={{ fontFamily: 'Poppins, sans-serif' }}>
              Vous avez déjà un compte? <Link to="/Login" className="text-[rgb(45,169,197)]">Se connecter</Link>
            </span>
          </div>
          <div className="relative text-center pb-6 pt-4">
            <div className="flex items-center justify-center gap-x-1">
              <div className="flex-1 border-t border-gray-300"></div>
              <span className="px-2 text-[10px] text-gray-400" style={{ fontFamily: 'Poppins, sans-serif', background: 'transparent' }}>
                Ou inscrivez-vous avec
              </span>
              <div className="flex-1 border-t border-gray-300"></div>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="w-[75px] h-10 bg-white/50 backdrop-blur-sm rounded-[4px] flex items-center justify-center mx-1 border border-[rgb(45,169,197)]">
              <img src={Facebook} alt='Facebook' className="w-4 h-4"></img>
            </div>
            <div className="w-[75px] h-10 bg-white/50 backdrop-blur-sm rounded-[4px] flex items-center justify-center mx-1 border border-[rgb(45,169,197)]">
              <img src={google} alt='google' className="w-4 h-4"></img>
            </div>
            <div className="w-[75px] h-10 bg-white/50 backdrop-blur-sm rounded-[4px] flex items-center justify-center border border-[rgb(45,169,197)]">
              <img src={apple} alt='apple' className="w-4 h-4"></img>
            </div>
          </div>
        </form>
      </div>
    );
  } else {
    // Web version
    return (
      <div className="flex justify-center items-center h-screen">
        {loading && (
          <div className="absolute z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white"></div>
          </div>
        )}
        <div className="w-1/2">
          <img src={desktopBackground} alt="Account background" className="w-full h-auto" />
        </div>
        <div className="w-1/2 bg-white p-12">
          <img src={guideeLogoWhite} alt="Guidee logo" className="pb-12 pt-60" />
          <form onSubmit={handleSubmit} className="w-full">
            <div className="flex flex-wrap -mx-3 pb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <div className="relative">
                  <input type="text" id="prenom" name="prenom" className="w-full px-3 py-[14px] border border-gray-700 rounded-[4px] focus:border-blue-500 placeholder-gray-400 text-[17px]" required placeholder="Lorem" />
                  <span className="absolute top-[-12px] left-2 bg-white px-1 text-base text-[rgb(28,27,31)] font-normal">Prénom</span>
                </div>
              </div>
              <div className="w-full md:w-1/2 px-3">
                <div className="relative">
                  <input type="text" id="nom" name="nom" className="w-full px-3 py-[14px] border border-gray-700 rounded-[4px] focus:border-blue-500 placeholder-gray-400 text-[17px]" required placeholder="Ipsum" />
                  <span className="absolute top-[-12px] left-2 bg-white px-1 text-base text-[rgb(28,27,31)] font-normal">Nom</span>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 pb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <div className="relative">
                  <input type="email" id="email" name="email" className="w-full px-3 py-[14px] border border-gray-700 rounded-[4px] focus:border-blue-500 placeholder-gray-400 text-[17px]" required placeholder="exemple@gmail.com" />
                  <span className="absolute top-[-12px] left-2 bg-white px-1 text-base text-[rgb(28,27,31)] font-normal">Adresse email</span>
                </div>
              </div>
              <div className="w-full md:w-1/2 px-3">
                <div className="relative">
                  <input type="tel" id="numero_telephone" name="numero_telephone" className="w-full px-3 py-[14px] border border-gray-700 rounded-[4px] focus:border-blue-500 placeholder-gray-400 text-[17px]" required placeholder="+216 22 222 222" />
                  <span className="absolute top-[-12px] left-2 bg-white px-1 text-base text-[rgb(28,27,31)] font-normal">Numéro de téléphone</span>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <div className="relative">
                  <input type="text" id="adresse" name="adresse" className="w-full px-3 py-[14px] border border-gray-700 rounded-[4px] focus:border-blue-500 placeholder-gray-400 text-[17px]" required placeholder="Tunis, rue 09" />
                  <span className="absolute top-[-12px] left-2 bg-white px-1 text-base text-[rgb(28,27,31)] font-normal">Adresse</span>
                </div>
              </div>
              <div className="w-full md:w-1/2 px-3">
                <div className="relative">
                  <input type="text" id="cin" name="cin" className="w-full px-3 py-[14px] border border-gray-700 rounded-[4px] focus:border-blue-500 placeholder-gray-400 text-[17px]" required placeholder="12548787" />
                  <span className="absolute top-[-12px] left-2 bg-white px-1 text-base text-[rgb(28,27,31)] font-normal">Cin</span>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <div className="relative">
                  <input type="text" id="origine" name="origine" className="w-full px-3 py-[14px] border border-gray-700 rounded-[4px] focus:border-blue-500 placeholder-gray-400 text-[17px]" required placeholder="Tunisie" />
                  <span className="absolute top-[-12px] left-2 bg-white px-1 text-base text-[rgb(28,27,31)] font-normal">Origine</span>
                </div>
              </div>
              <div className="w-full md:w-1/2 px-3">
                <div className="relative">
                  <input type="text" id="statut" name="statut" className="w-full px-3 py-[14px] border border-gray-700 rounded-[4px] focus:border-blue-500 placeholder-gray-400 text-[17px]" required placeholder="Célibataire" />
                  <span className="absolute top-[-12px] left-2 bg-white px-1 text-base text-[rgb(28,27,31)] font-normal">Statut</span>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <div className="relative">
                  <input type="file" id="profilePhoto" name="profilePhoto" className="w-full px-3 py-[14px] border border-gray-700 rounded-[4px] focus:border-blue-500 placeholder-gray-400 text-[17px]" />
                  <span className="absolute top-[-12px] left-2 bg-white px-1 text-base text-[rgb(28,27,31)] font-normal">Photo de profil</span>
                </div>
              </div>
              <div className="w-full md:w-1/2 px-3">
                <div className="relative">
                  <input type="file" id="coverPhoto" name="coverPhoto" className="w-full px-3 py-[14px] border border-gray-700 rounded-[4px] focus:border-blue-500 placeholder-gray-400 text-[17px]" />
                  <span className="absolute top-[-12px] left-2 bg-white px-1 text-base text-[rgb(28,27,31)] font-normal">Photo de couverture</span>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <div className="relative">
                  <input type={passwordVisible ? "text" : "password"} id="password" name="password" className="w-full px-3 py-[14px] border border-gray-700 rounded-[4px] focus:border-blue-500 placeholder-gray-400 text-[17px]" required placeholder="******************" />
                  <button type="button" onClick={togglePasswordVisibility} className="absolute inset-y-0 right-0 flex items-center pr-3">
                    {passwordVisible ? <EyeOffIcon className="h-5 w-5 text-gray-400" /> : <EyeIcon className="h-5 w-5 text-gray-400" />}
                  </button>
                  <span className="absolute top-[-12px] left-2 bg-white px-1 text-base text-[rgb(28,27,31)] font-normal">Mot de passe</span>
                </div>
              </div>
            </div>
            <div className="mb-6 flex items-center">
              <input type="checkbox" checked={isCheckboxChecked} onChange={(e) => setIsCheckboxChecked(e.target.checked)} className="mr-2" />
              <span className="text-[14px]" style={{ fontFamily: 'Poppins, sans-serif' }}>
                J'accepte toutes <span className="text-[rgb(45,169,197)]">les conditions</span> et <span className="text-[rgb(45,169,197)]">politiques de confidentialité</span>
              </span>
            </div>
            <button type="submit" className="bg-[rgb(0,35,161)] text-white px-4 py-3 rounded-[4px] mb-3 w-full font-normal">Créer un compte</button>
          </form>
          <div className="pb-3 flex items-center justify-center">
            <span className="text-[14px]" style={{ fontFamily: 'Poppins, sans-serif' }}>
              Vous avez déjà un compte? <Link to="/Login" className="text-[rgb(45,169,197)]">Se connecter</Link>
            </span>
          </div>
          <div className="relative text-center pb-6">
            <hr className="my-6 border-gray-300" />
            <span className="absolute top-[-12px] left-1/2 transform -translate-x-1/2 bg-white px-1 text-[14px] text-gray-400" style={{ fontFamily: 'Poppins, sans-serif' }}>Ou inscrivez-vous avec</span>
          </div>
          <div className="flex justify-center w-full">
            <div className="w-60 h-16 bg-white rounded-[4px] flex items-center justify-center mx-2 border border-[rgb(45,169,197)]">
              <img src={Facebook} alt='Face'></img>
            </div>
            <div className="w-60 h-16 bg-white rounded-[4px] flex items-center justify-center mx-2 border border-[rgb(45,169,197)]">
              <img src={google} alt='goo'></img>
            </div>
            <div className="w-60 h-16 bg-white rounded-[4px] flex items-center justify-center mx-2 border border-[rgb(45,169,197)]">
              <img src={apple} alt='app'></img>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default CreateAccount;
