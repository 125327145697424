import React from 'react';
import './App.css';
import Home from './components/pages/HomePage';

function App() {
  return (
    <>
      <Home />
    </>
  );
}

export default App;