import React from 'react';
import Header from '../layout/Header';
import TourCard from '../common/TourPage/TourCard';
import Footer from '../layout/Footer';

function Tour() {   
    return (
      <div>
       <Header page="tour" />
       <TourCard/>
       <Footer/>
      </div>
    );
}

export default Tour;