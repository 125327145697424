// features/personalizeTourSlice/personalizeTourSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  tours: [],
  status: 'idle',
  error: null,
};

export const fetchAcceptedPersonalizedTours = createAsyncThunk(
  'personalizedTours/fetchAcceptedPersonalizedTours',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch('http://localhost:8000/api/accepted/PersonalizedTour');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log("Données reçues du serveur :", data); // Ajoutez cette ligne pour vérifier les données
      return data.map(tour => ({
        _id: tour._id,
        imageSrc: `http://localhost:8000/${tour.image}`,
        name: tour.nom,
        hasSubCategories: tour.sousCategories && tour.sousCategories.length > 0,
      }));
    } catch (error) {
      console.error('Error fetching tours:', error);
      return rejectWithValue(error.message);
    }
  }
);

const personalizedToursSlice = createSlice({
  name: 'personalizedTours',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAcceptedPersonalizedTours.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAcceptedPersonalizedTours.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.tours = action.payload;
      })
      .addCase(fetchAcceptedPersonalizedTours.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default personalizedToursSlice.reducer;
