import React, { useEffect, useRef, useState } from 'react';
import PopularTourBG from '../../../assets/Images/Tour/PopularTourBGMobile.png'; 
import PopularTourweb from '../../../assets/Images/Tour/PopularTourBG.png'; 

const MainContentPC = () => {
  // State variables to manage the display of gradient and background style
  const [showGradient, setShowGradient] = useState(window.innerWidth < 1024);
  const [bgStyle, setBgStyle] = useState();
  
  // Function to check if the screen size is large (width >= 1024 pixels)
  const isLargeScreen = () => window.innerWidth >= 1024;

  useEffect(() => {
    // Function to update the background style based on screen size
    const updateBackgroundStyle = () => {
      if (isLargeScreen()) { // If screen size is large
        setShowGradient(true); // Show gradient
        // Set background style for large screens
        setBgStyle({
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${PopularTourweb})`, // Adding a linear gradient overlay to the background image
          backgroundSize: '100% auto', // Set background size to cover the entire container width and auto adjust the height
          backgroundPosition: 'center 10%', // Position the background image at the center horizontally and 10% from the top vertically
          backgroundRepeat: 'no-repeat', // Do not repeat the background image
          height: '100vh' // Set height of the container to 100% of viewport height
        });
      } else { // If screen size is small
        setShowGradient(true); // Show gradient
        // Set background style for small screens
        setBgStyle({
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${PopularTourBG})`, // Adding a linear gradient overlay to the background image
          backgroundSize: '100% auto', // Set background size to cover the entire container width and auto adjust the height
          backgroundPosition: 'top left', // Position the background image at the top left corner
          backgroundRepeat: 'no-repeat', // Do not repeat the background image
          height: '30vh' // Set height of the container to 30% of the viewport height
        });
      }
    };

    // Event listener to update background style on window resize
    window.addEventListener('resize', updateBackgroundStyle);
    updateBackgroundStyle(); // Initial call to set background style based on screen size

    // Cleanup function to remove event listener on component unmount
    return () => window.removeEventListener('resize', updateBackgroundStyle);
  }, []);

  return (
    <div>
      {/* Container for main content */}
      <div 
        className="flex flex-col justify-center items-center relative"
        style={{ 
          ...bgStyle, // Apply background style dynamically
        }}
      >
        {/* Conditional rendering of gradient overlay */}
        {showGradient && <div className="absolute bottom-0 left-0 right-0 h-1/4 bg-gradient-to-t from-white/100 to-transparent z-10" />}
        {/* Main content text */}
        <div className="text-white text-center pl-4 pr-4 pt-2 z-20">
          {/* Title */}
          <h1 className="sm:text-4xl md:text-5xl lg:text-7xl xl:text-7xl mb-8 sm:mb-10 md:mb-12 leading-none sm:leading-tight md:leading-loose">
            {/* Subtitle */}
            <span className="text-xs leading-loose lg:text-2xl lg:leading-loose lg:font-bold">CHOISISSEZ VOTRE TOUR</span> 
            {/* Main title */}
            <span className="block sm:inline md:block font-bold text-2xl lg:text-6xl">TOURS POPULAIRES</span>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default MainContentPC; 
