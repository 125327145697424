import React from 'react';
import Header from '../layout/Header';
import ContactForm from '../common/ContactUsPage/ContactForm';
import Subscribe from '../layout/Subscribe';
import Footer from '../layout/Footer';
function ContactezNous() {   
    return (
      <div>
       <Header page="contactez-nous" />
       <ContactForm/>
       <Subscribe/>
       <Footer/>
      </div>
    );
}

export default ContactezNous;