import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import serviceImage from '../../../assets/Images/Home/Nos service.png';
import personnalisable from '../../../assets/Images/Home/personnalisable.png';
import visites from '../../../assets/Images/Home/visites.png';
import responsible from '../../../assets/Images/Home/responsible.png';


const services = [
  { id: 'service-1', image: personnalisable, title: '100% personnalisable', description: 'Laissez votre hôte local adapter la visite \nentièrement à vos souhaits.' },
  { id: 'service-2', image: visites, title: 'Visites guidées privées', description: 'Aucun étranger lors de votre tour. C\'est juste vous et votre hôte local.' },
  { id: 'service-3', image: responsible, title: 'Responsible', description: 'Nos circuits sont conçus avec les gens, les liens et la planète à l\'esprit.' },
];

const Services = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [current, setCurrent] = useState(0);
  const widthOfOneItem = 100 / services.length; 
  const slideX = -(current * widthOfOneItem);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 768);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleDragEnd = (event, info) => {
    if (!info || !isMobile) return;

    const threshold = 50; 
    const direction = info.offset.x > 0 ? -1 : 1; 
    const dragDistance = Math.abs(info.offset.x);

    if (dragDistance > threshold) {
      const newIndex = (current + direction + services.length) % services.length;
      setCurrent(newIndex);
    }
  };

  return (
    <div>
      {isMobile ? (
        
        <div className="w-full overflow-hidden ">
          <h2 className="text-xl font-semibold text-center mb-10" 
          style={{fontFamily: 'Poppins, sans-serif', color: 'rgb(45, 169, 197)',letterSpacing: '2px'}}
          >NOS SERVICES</h2>
          <motion.div
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            onDragEnd={handleDragEnd}
            className="flex"
            initial={{ x: `${slideX}%` }}
            animate={{ x: `${slideX}%` }}
          >
            {services.map((service) => (
              <div key={service.id} className="flex flex-none flex-col items-center mx-4 mb-6" style={{ width: `${widthOfOneItem}%` }}>
                <img src={service.image} alt={service.title} className="w-16 h-16 mb-2" />
                <h3 className="text-xs font-extrabold text-center mt-2" style={{fontFamily: 'Poppins, sans-serif'}}>{service.title}</h3>
              </div>
            ))}
          </motion.div>
        </div>
      ) : (
        // Vue web 
        <div className="flex justify-between">
          <div className="ml-60 pt-20 pb-20 mr-20">
            <h2 className="text-xl mb-6 text-[rgb(0,35,161)]" style={{letterSpacing: '3px' }}>NOS OFFRES</h2>
            <h3 className="font-semibold mb-4" style={{ color: 'rgb(33, 161, 216)', fontFamily: 'Poppins, sans-serif', fontSize: '43.21px' }}>
              <span className="block leading-snug">Nous Offrons Les</span>
              <span>Meilleurs Services</span> </h3>
            <div className="space-y-4 mr-20"> 
              {services.map((service) => (
                <div key={service.id} className="flex items-center mb-4">
                  <div className="pr-2 mr-4 mb-7 mt-3">
                    <img src={service.image} alt={service.title} className="w-auto h-auto " />
                  </div>
                  <div className='pb-3'>
                    <h2 className="text-xl font-black text-gray-900 pb-1 " style={{ fontFamily: 'Poppins, sans-serif' }}>{service.title}</h2>
                    <p className="text-sm w-80" style={{ fontFamily: 'Alata, sans-serif', letterSpacing: '1px', color: 'rgb(140, 139, 146)' }}>{service.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex-1 pt-20 pb-20 pl-35">
            <img src={serviceImage} alt="Travel Services" className="w-auto h-auto" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Services;
