import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserProfile, updateUserProfile } from '../../../features/user/userSlice';
import profileImage from '../../../assets/Images/Profile/profile.png';
import backgroundImage from '../../../assets/Images/Profile/couverture.png';
import editIcon from '../../../assets/Images/Profile/edit.png';
import edit from '../../../assets/Images/Profile/Edit_icon.png';
import edit_white from '../../../assets/Images/Profile/Edit_white.png';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import ReservationHistory from './ReservationHistory';
import Trash from '../../../assets/Images/PersonalizedTour/equitationDetails/Trash.png';
import close from '../../../assets/Images/Profile/fermer.png';

//custom style when i open the profile photo
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: 'auto',
    maxHeight: '80vh',
    overflow: 'hidden',
    padding: '0',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    backdropFilter: 'blur(10px)',
  },
};

//custom style when i open the cover photo
const customStyles_cover = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height: 'auto',
    maxHeight: '80vh',
    overflow: 'hidden',
    padding: '0',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    backdropFilter: 'blur(10px)',
  },
};

const Profile = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.userInfo);

  const initialFormValues = {
    prenom: '',
    nom: '',
    email: '',
    newPassword: '',
    adresse: '',
    cin: '',
    numero_telephone: '',
    origine: '',
    statut: ''
  };

  const [formValues, setFormValues] = useState(initialFormValues);
  const [isEditing, setIsEditing] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [activeTab, setActiveTab] = useState('compte');
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isCoverModalOpen, setIsCoverModalOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  useEffect(() => {
    if (userInfo) {
      setFormValues({
        prenom: userInfo.prenom || '',
        nom: userInfo.nom || '',
        email: userInfo.email || '',
        newPassword: '',
        adresse: userInfo.adresse || '',
        cin: userInfo.cin || '',
        numero_telephone: userInfo.numero_telephone || '',
        origine: userInfo.origine || '',
        statut: userInfo.statut || ''
      });
    }
  }, [userInfo]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = () => {
    const newErrors = {};

    if (!formValues.prenom?.trim()) newErrors.prenom = 'Prénom ne peut pas être vide';
    if (!formValues.nom?.trim()) newErrors.nom = 'Nom ne peut pas être vide';
    if (!formValues.email?.trim()) newErrors.email = 'Email ne peut pas être vide';
    if (
      formValues.newPassword &&
      (formValues.newPassword.length < 8 ||
        !formValues.newPassword.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[!@#$%^&*()\-_=+{};:<>,.?]).*$/))
    ) {
      newErrors.newPassword =
        'Le mot de passe doit comporter au moins 8 caractères et inclure une majuscule, une minuscule, un chiffre et un caractère spécial';
    }
    if (!formValues.adresse?.trim()) newErrors.adresse = 'Adresse ne peut pas être vide';
    if (!formValues.cin?.trim()) newErrors.cin = 'Numéro Cin ne peut pas être vide';
    if (!formValues.numero_telephone?.trim()) newErrors.numero_telephone = 'Numéro de téléphone ne peut pas être vide';
    if (!formValues.origine?.trim()) newErrors.origine = 'Origine ne peut pas être vide';
    if (!formValues.statut?.trim()) newErrors.statut = 'Statut ne peut pas être vide';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSaveClick = async () => {
    if (!validate()) return;

    const updates = { ...formValues };
    if (!updates.newPassword) {
      delete updates.newPassword;
    }

    try {
      await dispatch(updateUserProfile(updates)).unwrap();
      setIsEditing(false);
      dispatch(fetchUserProfile());
    } catch (error) {
      setErrors({ server: error.message || 'Unauthorized' });
    }
  };

  const handleReturnClick = () => {
    setFormValues(userInfo);
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setFormValues(initialFormValues);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePhotoUpload = async (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('userId', userInfo._id);
    const token = localStorage.getItem('authToken');

    try {
      const response = await fetch(`http://localhost:8000/api/upload/${e.target.name}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });
      const data = await response.json();
      if (response.ok) {
        dispatch(fetchUserProfile());
      } else {
        console.error('Photo upload failed:', data);
      }
    } catch (error) {
      console.error('Error uploading photo:', error);
    }
  };

  const handleDeletePhoto = async () => {
    const token = localStorage.getItem('authToken');

    try {
      const response = await fetch(`http://localhost:8000/api/delete/profilePhoto/${userInfo._id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (response.ok) {
        dispatch(fetchUserProfile());
        closeProfileModal(); // Fermer la modale après suppression
      } else {
        console.error('Photo delete failed:', data);
      }
    } catch (error) {
      console.error('Error deleting photo:', error);
    }
  };

  const handleDeleteCoverPhoto = async () => {
    const token = localStorage.getItem('authToken');

    try {
      const response = await fetch(`http://localhost:8000/api/delete/coverPhoto/${userInfo._id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (response.ok) {
        dispatch(fetchUserProfile());
        closeProfileModal(); // Fermer la modale après suppression
      } else {
        console.error('Photo delete failed:', data);
      }
    } catch (error) {
      console.error('Error deleting photo:', error);
    }
  };

  

  const openProfileModal = () => {
    setIsProfileModalOpen(true);
  };

  const closeProfileModal = () => {
    setIsProfileModalOpen(false);
  };

  const openCoverModal = () => {
    setIsCoverModalOpen(true);
  };

  const closeCoverModal = () => {
    setIsCoverModalOpen(false);
  };

  if (!userInfo) {
    return <div>Loading...</div>;
  }

  return (
    <div className="relative w-full max-w-6xl mx-auto lg:mt-40 ">
       <div className="relative lg:h-64 h-[180px] w-full rounded-t-lg overflow-hidden">
        <img
          src={userInfo.coverPhoto ? `http://localhost:8000/${userInfo.coverPhoto}` : backgroundImage}
          alt="Background"
          className="w-full h-full rounded-r-2xl rounded-l-4xl cursor-pointer"
          onClick={openCoverModal}
        />
        {!(isCoverModalOpen || isProfileModalOpen) && ( // Hide edit button if either modal is open
          <button className="absolute bottom-4 right-4 z-30">
            <label htmlFor="coverPhotoUpload">
              <img src={editIcon} alt="Edit Background" className="lg:w-full lg:h-full w-8 h-8 cursor-pointer" />
            </label>
            <input
              type="file"
              id="coverPhotoUpload"
              name="coverPhoto"
              onChange={handlePhotoUpload}
              className="hidden"
            />
          </button>
        )}
      </div>

      <div className="relative w-full flex justify-center -mt-16">
        <div className="relative">
          <img
            src={userInfo.profilePhoto ? `http://localhost:8000/${userInfo.profilePhoto}` : profileImage}
            alt="Profile"
            className="lg:w-40 lg:h-40 w-[100px] h-[100px] rounded-full border-2 border-[rgb(45,169,197)] object-cover cursor-pointer"
            onClick={openProfileModal} // Add onClick to open the modal
          />
          {!(isCoverModalOpen || isProfileModalOpen) && (  // Hide edit button if either modal is open
            <button className="absolute bottom-0 right-0 z-30">
              <label htmlFor="profilePhotoUpload">
                <img src={editIcon} alt="Edit Profile" className="lg:w-full lg:h-full w-8 h-8 cursor-pointer" />
              </label>
              <input
                type="file"
                id="profilePhotoUpload"
                name="profilePhoto"
                onChange={handlePhotoUpload}
                className="hidden"
              />
            </button>
          )}
        </div>
      </div>

      <Modal
        isOpen={isProfileModalOpen}
        onRequestClose={closeProfileModal}
        contentLabel="Profile Photo Modal"
        style={customStyles}
      > 
        <div className="flex flex-col items-center">
          <img
            src={userInfo.profilePhoto ? `http://localhost:8000/${userInfo.profilePhoto}` : profileImage}
            alt="Profile"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="flex items-center justify-center absolute top-2 right-2 space-x-4 z-40"> 
        <button onClick={handleDeletePhoto} className="bg-white p-2 rounded-full w-10 h-10"> <img src={Trash}></img> </button>
       <button onClick={closeProfileModal} className="bg-white p-2 rounded-full w-10"><img src={close}></img></button>
       </div>
      </Modal>

      <Modal
        isOpen={isCoverModalOpen}
        onRequestClose={closeCoverModal}
        contentLabel="Cover Photo Modal"
        style={customStyles_cover}
      >
         <div className="flex items-center justify-center absolute top-2 right-2 space-x-4 z-40"> 
        <button onClick={handleDeleteCoverPhoto} className="bg-white p-2 rounded-full w-10 h-10"> <img src={Trash}></img> </button>
       <button onClick={closeCoverModal} className="bg-white p-2 rounded-full w-10"><img src={close}></img></button>
       </div>
        <img
          src={userInfo.coverPhoto ? `http://localhost:8000/${userInfo.coverPhoto}` : backgroundImage}
          alt="Cover"
          className="w-full h-full object-cover"
        />
      </Modal>

      <div className="text-center lg:mt-4 mt-2">
        <h2 className="lg:text-xl text-base font-bold">
          {userInfo.prenom} {userInfo.nom}
        </h2>
        <p className="text-gray-600 lg:text-xl text-sm">{userInfo.email}</p>
      </div>

      <div className="lg:mt-20 lg:mb-10 lg:p-6 lg:max-w-6xl mx-auto mt-10 ">
        <div className="flex justify-start items-center lg:rounded-2xl ml-12 mr-12 lg:ml-0 lg:mr-0 lg:shadow-xl shadow-lg shadow-gray-300 pb-4 mb-8 lg:space-x-80 space-x-20 lg:h-20 h-10">
          <div
            className={`lg:text-lg text-[10px] font-semibold text-black relative lg:pl-6 pl-2 lg:w-[210px] w-[60px] cursor-pointer`}
            onClick={() => setActiveTab('compte')}
          >
            Compte
            {activeTab === 'compte' && (
              <div className="absolute lg:left-6 right-0 lg:bottom-[-35px] bottom-[-22px] h-[4px] bg-[rgb(45,169,197)] w-full"></div>
            )}
          </div>

          <div className="flex items-center space-x-4">
            <div className="lg:h-12 h-6 border-l-2 border-gray-300 bottom-0"></div>
            <div
              className={`lg:text-lg text-[10px] font-semibold text-black relative cursor-pointer `}
              onClick={() => setActiveTab('historique')}
            >
              Historique des réservations
              {activeTab === 'historique' && (
                <div className="absolute left-0 right-0 lg:bottom-[-35px] bottom-[-22px] h-[4px] bg-[rgb(45,169,197)] "></div>
              )}
            </div>
          </div>
        </div>


        {activeTab === 'compte' ? (
          <>
            <h1 className="lg:text-4xl text-xl ml-12 lg:ml-0 font-bold mb-8 shadow-2xl lg:shadow-none">Compte</h1>

            <div className="bg-white rounded lg:shadow-lg shadow-2xl p-8 rounded-2xl lg:mb-0 mb-4 w-100 lg:w-auto ml-2 mr-2 lg:ml-0 lg:mr-0">
            {isEditing ? (
  <form>
    <div className="grid grid-cols-2 lg:gap-8 gap-4">
      <div>
        <label className="block text-black lg:text-xl text-xs font-semibold">Prénom</label>
        <input
          type="text"
          name="prenom"
          value={formValues.prenom}
          onChange={handleInputChange}
          className="w-full mt-1 p-2 border lg:rounded-lg rounded border-gray-500 border-2 text-gray-500 text-[10px] lg:text-base"
        />
        {errors.prenom && <p className="text-red-500 text-xs">{errors.prenom}</p>}
      </div>
      <div>
        <label className="block text-black lg:text-xl text-xs font-semibold">Nom</label>
        <input
          type="text"
          name="nom"
          value={formValues.nom}
          onChange={handleInputChange}
          className="w-full mt-1 p-2 border lg:rounded-lg rounded border-gray-500 border-2 text-gray-500 text-[10px] lg:text-base"
        />
        {errors.nom && <p className="text-red-500 text-xs">{errors.nom}</p>}
      </div>
      <div>
        <label className="block text-black lg:text-xl text-xs font-semibold">Adresse E-mail</label>
        <input
          type="email"
          name="email"
          value={formValues.email}
          onChange={handleInputChange}
          className="w-full mt-1 p-2 border lg:rounded-lg rounded border-gray-500 border-2 text-gray-500 text-[10px] lg:text-base"
        />
        {errors.email && <p className="text-red-500 text-xs">{errors.email}</p>}
      </div>

      <div>
        <label className="block text-black lg:text-xl text-xs font-semibold">Statut</label>
        <input
          type="text"
          name="statut"
          value={formValues.statut}
          onChange={handleInputChange}
          className="w-full mt-1 p-2 border lg:rounded-lg rounded border-gray-500 border-2 text-gray-500 text-[10px] lg:text-base"
        />
        {errors.statut && <p className="text-red-500 text-xs">{errors.statut}</p>}
      </div>
      
      <div>
        <label className="block text-black lg:text-xl text-xs font-semibold">Adresse</label>
        <input
          type="text"
          name="adresse"
          value={formValues.adresse}
          onChange={handleInputChange}
          className="w-full mt-1 p-2 border lg:rounded-lg rounded border-gray-500 border-2 text-gray-500 text-[10px] lg:text-base"
        />
        {errors.adresse && <p className="text-red-500 text-xs">{errors.adresse}</p>}
      </div>
      <div>
        <label className="block text-black lg:text-xl text-xs font-semibold">Numéro Cin</label>
        <input
          type="text"
          name="cin"
          value={formValues.cin}
          onChange={handleInputChange}
          className="w-full mt-1 p-2 border lg:rounded-lg rounded border-gray-500 border-2 text-gray-500 text-[10px] lg:text-base"
        />
        {errors.cin && <p className="text-red-500 text-xs">{errors.cin}</p>}
      </div>
      <div>
        <label className="block text-black lg:text-xl text-xs font-semibold">Numéro de téléphone</label>
        <input
          type="text"
          name="numero_telephone"
          value={formValues.numero_telephone}
          onChange={handleInputChange}
          className="w-full mt-1 p-2 border lg:rounded-lg rounded border-gray-500 border-2 text-gray-500 text-[10px] lg:text-base"
        />
        {errors.numero_telephone && <p className="text-red-500 text-xs">{errors.numero_telephone}</p>}
      </div>
      <div>
        <label className="block text-black lg:text-xl text-xs font-semibold">Origine</label>
        <input
          type="text"
          name="origine"
          value={formValues.origine}
          onChange={handleInputChange}
          className="w-full mt-1 p-2 border lg:rounded-lg rounded border-gray-500 border-2 text-gray-500 text-[10px] lg:text-base"
        />
        {errors.origine && <p className="text-red-500 text-xs">{errors.origine}</p>}
      </div>
      <div className="col-span-2 lg:col-span-1">
        <label className="block text-black lg:text-xl text-xs font-semibold">Nouveau mot de passe</label>
        <div className="relative">
          <input
            type={showPassword ? 'text' : 'password'}
            name="newPassword"
            value={formValues.newPassword}
            onChange={handleInputChange}
            className="w-full mt-1 p-2 border lg:rounded-lg rounded border-gray-500 border-2 text-gray-500 text-[10px] lg:text-base"
            placeholder="Laissez vide pour ne pas changer"
          />
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="absolute right-3 lg:top-4 top-3"
          >
            {showPassword ? <EyeOffIcon className="h-5 w-5 text-gray-500" /> : <EyeIcon className="h-5 w-5 text-gray-500" />}
          </button>
        </div>
        {errors.newPassword && <p className="text-red-500 text-xs">{errors.newPassword}</p>}
      </div>
      
    </div>
    <div className="flex justify-end mt-8 space-x-4">
      <button
        type="button"
        onClick={handleSaveClick}
        className="flex items-center bg-[rgb(49,77,179)] text-white text-xs lg:text-base px-4 py-3 lg:rounded-xl rounded shadow font-semibold"
      >
        <img src={edit} alt="edit" className="mr-2" />
        Enregistrer
      </button>

      <button
        type="button"
        onClick={handleCancelClick}
        className="flex items-center bg-white text-[rgb(49,77,179)] text-xs lg:text-base px-4 py-3 lg:rounded-xl rounded shadow border border-2 border-[rgb(49,77,179)] font-semibold"
      >
        <img src={edit_white} alt="edit_white" className="mr-2" />
        Annuler
      </button>

      <button
        type="button"
        onClick={handleReturnClick}
        className="flex items-center bg-white text-[rgb(49,77,179)] text-xs lg:text-base px-4 py-3 lg:rounded-xl rounded shadow border border-2 border-[rgb(49,77,179)] font-semibold"
      >
        Retour
      </button>
    </div>
    {errors.server && <p className="text-red-500 text-xs">{errors.server}</p>}
  </form>
) : (
                <>
                  <div className="lg:mb-8 mb-6">
                    <div>
                      <p className="lg:text-[18px] text-sm text-gray-600">Prénom</p>
                      <p className="text-lg font-semibold">{userInfo.prenom}</p>
                    </div>
                    
                  </div>
                  <div className="lg:mb-8 mb-6">
                    <div>
                      <p className="lg:text-[18px] text-sm text-gray-600">Nom</p>
                      <p className="text-lg font-semibold">{userInfo.nom}</p>
                    </div>
                    
                  </div>
                  <div className="lg:mb-8 mb-6">
                    <div>
                      <p className="lg:text-[18px] text-sm text-gray-600">Adresse e-mail</p>
                      <p className="text-lg font-semibold">{userInfo.email}</p>
                    </div>
                    
                  </div>
                  <div className="lg:mb-8 mb-6">
                    <div>
                      <p className="lg:text-[18px] text-sm text-gray-600">Mot de passe</p>
                      <div className="relative">
                        <input
                          type="password"
                          value="********"
                          readOnly
                          className="w-full mt-1"
                        />
                      </div>
                    </div>
                   
                  </div>
                  <div className="lg:mb-8 mb-6">
                    <div>
                      <p className="lg:text-[18px] text-sm text-gray-600">Adresse</p>
                      <p className="text-lg font-semibold">{userInfo.adresse}</p>
                    </div>
                    
                  </div>
                  <div className="lg:mb-8 mb-6">
                    <div>
                      <p className="lg:text-[18px] text-sm text-gray-600">Numéro Cin</p>
                      <p className="text-lg font-semibold">{userInfo.cin}</p>
                    </div>
                   
                  </div>
                  <div className="lg:mb-8 mb-6">
                    <div>
                      <p className="lg:text-[18px] text-sm text-gray-600">Numéro de téléphone</p>
                      <p className="text-lg font-semibold">{userInfo.numero_telephone}</p>
                    </div>
                    
                  </div>
                  <div className="lg:mb-8 mb-6">
                    <div>
                      <p className="lg:text-[18px] text-sm text-gray-600">Origine</p>
                      <p className="text-lg font-semibold">{userInfo.origine}</p>
                    </div>
                    
                  </div>
                  <div className="lg:mb-8 mb-6">
                    <div>
                      <p className="lg:text-[18px] text-sm text-gray-600">Statut</p>
                      <p className="text-lg font-semibold">{userInfo.statut}</p>
                    </div>
                  </div>
                  <div className="flex lg:justify-end justify-center lg:mt-0 mt-12" >
                  <button
                      onClick={handleEditClick}
                      className="flex items-center bg-[rgb(49,77,179)] text-white lg:px-8 lg:py-3 px-4 py-2 rounded-lg lg:text-base text-xs lg:rounded-xl shadow">
                      <img src={edit} alt="edit9" className="mr-2" /> Modifier
                    </button>
                  </div>
                  
                </>
              )}
            </div>
          </>
        ) : (
          <ReservationHistory />
        )}
      </div>
    </div>
  );
};

export default Profile;
