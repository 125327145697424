import React, { useState, useEffect } from 'react';
import current from '../../../assets/Images/TourGallery/current_btn.png';
import next from '../../../assets/Images/TourGallery/next_btn.png';

const Gallery = () => {
  const [tourData, setTourData] = useState({});

  useEffect(() => {
    const storedData = sessionStorage.getItem('tourData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      console.log("Parsed Data from Storage:", parsedData);
      setTourData(parsedData);
    }
  }, []);

  const [startIndex, setStartIndex] = useState(0);

  const nextImages = () => {
    setStartIndex((prevIndex) => prevIndex + 3);
  };

  const prevImages = () => {
    setStartIndex((prevIndex) => prevIndex - 3);
  };

  if (!Array.isArray(tourData.galerie)) {
    console.error("Galerie is not an array:", tourData.galerie);
    return <div>No images available</div>;
  }

  return (
    <div>
      <div className={`mt-40 ${startIndex === 0 ? '' : 'hidden'}`}>
        <div className="grid gap-4 grid-cols-2">
          {tourData.galerie.slice(0, 3).map((image, index) => (
            <div key={index} className={`col-span-${index === 2 ? 2 : 1}`}>
              <img 
                className="w-auto h-full rounded-2xl" 
                src={`http://localhost:8000/${image}`} 
                alt={`gallery${index + 1}`} 
                onError={(e) => {
                  console.error(`Failed to load image: http://localhost:8000/${image}`);
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <div className={`mt-40 ${startIndex === 3 ? '' : 'hidden'}`}>
        <div className="grid gap-4 grid-cols-2">
          {tourData.galerie.slice(3, 6).map((image, index) => (
            <div key={index} className={`col-span-${index === 2 ? 2 : 1}`}>
              <img 
                className="w-auto h-full rounded-2xl" 
                src={`http://localhost:8000/${image}`} 
                alt={`gallery${index + 4}`} 
                onError={(e) => {
                  console.error(`Failed to load image: http://localhost:8000/${image}`);
                }}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center mt-4 space-x-4">
        <img 
          onClick={prevImages} 
          src={current} 
          alt="Previous" 
          className={`cursor-pointer ${startIndex === 0 ? 'hidden' : ''}`} 
        />
        <img 
          onClick={nextImages} 
          src={next} 
          alt="Next" 
          className={`cursor-pointer ${startIndex + 3 >= tourData.galerie.length ? 'hidden' : ''}`} 
        />
      </div>
    </div>
  );
};

export default Gallery;
