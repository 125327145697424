import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Navigation } from 'swiper/modules';
import SwiperCore from 'swiper';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import './PopularCities.css';

import imageSrc1 from '../../../assets/Images/Home/expert1.png';
import imageSrc2 from '../../../assets/Images/Home/expert2.png';
import imageSrc3 from '../../../assets/Images/Home/expert3.png';

import fleche_travel from '../../../assets/Images/Home/fleche TravelExperts.png';
import prev from '../../../assets/Images/Home/prev_btn.png';
import nex from '../../../assets/Images/Home/next_btn_green.png';
import flecheweb from '../../../assets/Images/Home/fleche popular.png';

SwiperCore.use([EffectCoverflow, Navigation]);


const TravelExperts = () => {
  const [slidesPerView, setSlidesPerView] = useState(1.5); 
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [swiper, setSwiper] = useState(null);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width >= 768) {
      setIsLargeScreen(true);
      setSlidesPerView(3); 
    } else {
      setIsLargeScreen(false);
      setSlidesPerView(1.5);
    }
  };

  const updateBlurEffect = (swiper) => {
    // Ajouter l'effet flou sur toutes les images sur les petits écrans
    if (!isLargeScreen) {
      swiper.slides.forEach((slide, index) => {
        const img = slide.querySelector('img');
        if (index !== swiper.activeIndex) {
          img.classList.add('image-blur');
        } else {
          img.classList.remove('image-blur');
        }
      });
    } else {
      // Supprimer l'effet flou sur toutes les images sur les grands écrans
      swiper.slides.forEach((slide) => {
        slide.querySelector('img').classList.remove('image-blur');
      });
    }
  };

  
useEffect(() => {
  handleResize();
  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
}, []);

const slidePrev = () => {
  if (swiper) swiper.slidePrev();
};

const slideNext = () => {
  if (swiper) swiper.slideNext();
};
  
  return (
    <div className="relative">
      <div className="absolute w-full h-1/2 bg-[rgb(49,77,179)]"></div>
    <section className="swiper-section relative z-10">

    <div className="text-center pl-5 pr-5 pb-10 pt-20">
  <div className="flex flex-col items-center lg:flex-row lg:items-start lg:justify-center lg:ml-40">
    <h1 className="text-2xl md:text-4xl lg:text-4xl pb-10 pt-10 lg:pt-1 font-bold text-[rgb(255,255,255)] mx-auto">
      NOS EXPERTS EN VOYAGE
    </h1>
    <div className="hidden md:flex mr-20">
      <button className="mx-2 rounded-full" onClick={slidePrev}>
        <img src={prev} alt="previous" />
      </button>
      <button className="mx-2 rounded-full" onClick={slideNext}>
        <img src={nex} alt="Next" />
      </button>
    </div>
  </div>
</div>

      <Swiper
        centeredSlides={true}
        slidesPerView={slidesPerView} 
        loop={false}
        spaceBetween={25}
        initialSlide={1}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
        effect="coverflow"
        className="mySwiper"
        onSlideChange={(swiper) => updateBlurEffect(swiper)}
        onInit={(swiper) => {
          setSwiper(swiper);
          updateBlurEffect(swiper);}}
      
      >
        
        <SwiperSlide>
        <div className="image-wrapper" style={isLargeScreen ? { maxWidth: '75%', margin: '0 auto' } : {}}>
          <img src={imageSrc1} alt="Bizerte"/>
          </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="image-wrapper" style={isLargeScreen ? { maxWidth: '75%', margin: '0 auto' } : {}}>
          <img src={imageSrc2} alt="Sud Tunisien"/>
          </div>
        </SwiperSlide>

        <SwiperSlide>
        <div className="image-wrapper" style={isLargeScreen ? { maxWidth: '75%', margin: '0 auto' } : {}}>
          <img src={imageSrc3} alt="Sousse"/>
          </div>
        </SwiperSlide>
      </Swiper>
      <div className={`explore-button-container ${isLargeScreen ? 'web-layout-class' : ''}`}>
        <button className="explore-button font-bold" style={{ fontFamily: 'Montserrat, sans-serif'}}>VOIR PLUS </button>
      </div>
     
    </section>

    <div className="flex justify-end relative z-10">
        <img src={isLargeScreen ? flecheweb : fleche_travel} alt="flèche" className="w-auto" />
      </div>

    </div>
  );
};

export default TravelExperts;