// ActivateAccount component
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { activateAccount, resendActivationCode } from '../../features/user/userSlice';
import desktopBackground from '../../assets/Images/Account/Account_bg.png';
import mobileBackground from '../../assets/Images/Account/mobile_bg.png';
import guidee_white_logo from '../../assets/Images/Account/guidee logo white.png';
import guideeLogo from '../../assets/Images/Account/guidee logo.png';
import return_icon from '../../assets/Images/Account/return_icon.png';

const ActivateAccount = () => {
    const isMobile = window.innerWidth < 768; // Checking if the screen width is less than 768px
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [activationCode, setActivationCode] = useState('');

    const handleSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);
  
      const startTime = Date.now();
  
      dispatch(activateAccount({ email, activationCode }))
          .unwrap()
          .then(() => {
              const duration = Date.now() - startTime;
              const delay = Math.max(2000 - duration, 0); // Ensure spinner shows for at least 2 seconds
  
              setTimeout(() => {
                  navigate("/Login");
              }, delay);
          })
          .catch((error) => {
              console.error('Activation failed:', error);
              const duration = Date.now() - startTime;
              const delay = Math.max(2000 - duration, 0); // Also delay the error handling appropriately
  
              setTimeout(() => {
                  if (error.message.includes("expired")) {
                      alert("Le code d'activation est expiré. Veuillez demander un nouveau code.");
                  } else {
                      alert("Échec de l'activation. Veuillez vérifier vos informations et réessayer.");
                  }
              }, delay);
          })
          .finally(() => {
              const duration = Date.now() - startTime;
              const delay = Math.max(2000 - duration, 0);
  
              setTimeout(() => {
                  setLoading(false);
              }, delay);
          });
    };

    const handleResend = () => {
        dispatch(resendActivationCode({ email }))
            .unwrap()
            .then(() => {
                alert("Un nouveau code d'activation a été envoyé à votre email.");
            })
            .catch((error) => {
                console.error('Failed to resend activation code:', error);
                alert("Échec de l'envoi du code d'activation. Veuillez réessayer.");
            });
    };

    if (isMobile) {
        // Mobile version
        return (
            <div className="min-h-screen flex flex-col items-center justify-center bg-cover bg-center" 
                 style={{ backgroundImage: `url(${mobileBackground})` }}>
                {loading && (
                    <div className="absolute z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50">
                        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white"></div>
                    </div>
                )}
                <div className="pb-20 pt-20">
                    <img src={guideeLogo} alt="Guidee Registration Logo" />
                </div>
                <form className="bg-gray-700/40 backdrop-blur-[2px] rounded-t-[50px] pt-6 pb-8 px-16 w-full min-h-screen" onSubmit={handleSubmit}>
                    <p className="text-2xl font-bold text-white text-center pb-16">Activer le compte</p>

                    <div className="mb-6 flex items-center">
                    <Link to="/CreateAccount" className="flex items-center text-[rgb(45,169,197)]">
                    <img src={return_icon} className="mr-2" alt="return_icon"/>
                    <span className="text-[10px] text-white" style={{ fontFamily: 'Poppins, sans-serif'}}>
                     Retour à l'inscription
                    </span>
                    </Link>
                    </div>

                    <div className="mb-4">
                        <label className="text-xs text-white">Adresse e-mail</label>
                        <input
                            className="shadow appearance-none border rounded w-full h-9 py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-400 text-xs"
                            id="email"
                            type="email"
                            name="email"
                            placeholder="exemple@gmail.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            />
                    </div>
                    <div className="mb-8">
                        <label className="text-xs text-white">Code d'activation</label>
                        <input
                            className="shadow appearance-none border rounded w-full h-9 py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-400 text-xs"
                            id="activationCode"
                            type="text"
                            name="activationCode"
                            placeholder="87549658"
                            value={activationCode}
                            onChange={(e) => setActivationCode(e.target.value)}
                            />
                    </div>
                    <div className="flex items-center">
                        <button className="bg-[rgb(49,77,179)] w-full text-xs text-white font-semibold py-3 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
                            Envoyer
                        </button>
                    </div>
                    <div className="mt-4 text-center">
                        <button className="text-xs text-blue-500" type="button" onClick={handleResend}>
                            Envoyer de nouveau
                        </button>
                    </div>
                </form>
            </div>
        );
    } else {
        // Web version
        return (
            <div className="flex justify-center items-center h-screen">
                {loading && (
                    <div className="absolute z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50">
                        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white"></div>
                    </div>
                )}
                <div className="w-1/2 bg-white justify-center ml-20 pl-20">
                    <img src={guidee_white_logo} alt="Guidee Logo" className="pb-12 pt-20 w-auto"></img>
                <div className="mb-6 flex items-center">
                  <span>
                    <Link to="/CreateAccount" className="flex items-center text-[rgb(45,169,197)]">
                     <img src={return_icon} className="mr-2" alt="return_icon"/>
                     <span className="text-[14px] text-black" style={{ fontFamily: 'Poppins, sans-serif' }}>Retour à l'inscription</span>
                    </Link>
                  </span>
                </div>
                    <h2 className="text-4xl font-bold pb-8" style={{ fontFamily: 'Poppins, sans-serif' }}>Activer le compte</h2>
                    <p className="text-base pb-12 text-[rgb(67,97,67)] " style={{ fontFamily: 'Poppins, sans-serif' }}>Un code d'activation a été envoyé à votre email.</p>
                    <form onSubmit={handleSubmit} className="w-full max-w-md">
            <div className="flex flex-wrap mb-6">
                  <div className="relative">
                    <input type="email" id="email" name="email" className="w-[500px] px-3 py-[14px] border border-gray-700 rounded-[4px] focus:border-blue-500" required placeholder="exemple@gmail.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <span className="absolute top-[-12px] left-2 bg-white px-1 text-base text-[rgb(28,27,31)] font-normal">Adresse email</span>
                  </div>
            </div>
    
            <div className="flex flex-wrap mb-6">
                <div className="relative">
                  <input type="text" id="activationCode" name="activationCode" className="block w-[500px] px-3 py-[14px] border border-gray-700 rounded-[4px] focus:border-blue-500" required placeholder="123456" value={activationCode} onChange={(e) => setActivationCode(e.target.value)} />
                  <span className="absolute top-[-12px] left-2 bg-white px-1 text-base text-[rgb(28,27,31)] font-normal">Code d'activation</span>
                </div>
            </div>
            <button type="submit" className="block w-[500px] bg-[rgb(0,35,161)] text-white px-4 py-3 rounded-[4px] mb-12 font-normal">Envoyer</button>
            <div className="mt-4 text-center">
                        <button className="text-lg font-bold text-[rgb(0,35,161)]" type="button" onClick={handleResend}>
                            Envoyer de nouveau
                        </button>
                    </div>
          </form>
    
        </div>
        <div className="w-1/2">
          <img src={desktopBackground} alt="Background" className="w-full h-auto" />
        </div>
      </div>
      
      );
    }
};

export default ActivateAccount;
