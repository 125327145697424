import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow } from 'swiper/modules';
import SwiperCore from 'swiper/core';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import TunisiaMap from './tunisiamap';

// Import des images
import mapImage from '../../../assets/Images/Home/map.png';
import destination1 from '../../../assets/Images/Home/Quad.png';
import destination2 from '../../../assets/Images/Home/equitation.png';
import destination3 from '../../../assets/Images/Home/Tour.png';

import f from '../../../assets/Images/Home/fleche tour.png';

import dest1 from '../../../assets/Images/Home/QuadMobile.png';
import dest2 from '../../../assets/Images/Home/equitationMobile.png';
import dest3 from '../../../assets/Images/Home/TourMobile.png';
// Configuration de Swiper
SwiperCore.use([EffectCoverflow]);

const PopularDestination = () => {
    const [isMobile, setIsMobile] = useState(false);
    //map
    const [selectedRegion, setSelectedRegion] = useState('Tunis');
    const [selectedRegionDescription, setSelectedRegionDescription] = useState('Le gouvernement de Tunis en Tunisie offre aux touristes une expérience unique mêlant histoire, culture et détente. Située sur la côte est du pays, cette région est réputée pour ses plages de sable doré et son patrimoine historique riche.');
    

    const getDescriptionForRegion = (regionName) => {
        const descriptions = {
            'Tunis': 'Le gouvernement de Tunis en Tunisie offre aux touristes une expérience unique mêlant histoire, culture et détente .Située sur la côte est du pays, cette région est réputée pour ses plages de sable doré et son patrimoine historique riche .',
            'L\'Ariana': 'Le gouvernement de L\'Ariana en Tunisie offre aux touristes une expérience unique mêlant histoire, culture et détente .Située sur la côte est du pays, cette région est réputée pour ses plages de sable doré et son patrimoine historique riche .',
            'Ben Arous': 'Le gouvernement de Ben Arous en Tunisie offre aux touristes une expérience unique mêlant histoire, culture et détente .Située sur la côte est du pays, cette région est réputée pour ses plages de sable doré et son patrimoine historique riche .',
            'La Manouba': 'Le gouvernement de La Manouba en Tunisie offre aux touristes une expérience unique mêlant histoire, culture et détente .Située sur la côte est du pays, cette région est réputée pour ses plages de sable doré et son patrimoine historique riche .',
            'Nabeul': 'Le gouvernement de Nabeul en Tunisie offre aux touristes une expérience unique mêlant histoire, culture et détente .Située sur la côte est du pays, cette région est réputée pour ses plages de sable doré et son patrimoine historique riche .',
            'Zaghouan': 'Le gouvernement de Zaghouan en Tunisie offre aux touristes une expérience unique mêlant histoire, culture et détente .Située sur la côte est du pays, cette région est réputée pour ses plages de sable doré et son patrimoine historique riche .',
            'Bizerte': 'Le gouvernement de Bizerte en Tunisie offre aux touristes une expérience unique mêlant histoire, culture et détente .Située sur la côte est du pays, cette région est réputée pour ses plages de sable doré et son patrimoine historique riche .',
            'Béja': 'Le gouvernement de Béja en Tunisie offre aux touristes une expérience unique mêlant histoire, culture et détente .Située sur la côte est du pays, cette région est réputée pour ses plages de sable doré et son patrimoine historique riche .',
            'Jendouba': 'Le gouvernement de Jendouba en Tunisie offre aux touristes une expérience unique mêlant histoire, culture et détente .Située sur la côte est du pays, cette région est réputée pour ses plages de sable doré et son patrimoine historique riche .',
            'Le Kef': 'Le gouvernement de Le Kef en Tunisie offre aux touristes une expérience unique mêlant histoire, culture et détente .Située sur la côte est du pays, cette région est réputée pour ses plages de sable doré et son patrimoine historique riche .', 
            'Siliana': 'Le gouvernement de Siliana en Tunisie offre aux touristes une expérience unique mêlant histoire, culture et détente .Située sur la côte est du pays, cette région est réputée pour ses plages de sable doré et son patrimoine historique riche .',
            'Kairouan': 'Le gouvernement de Kairouan en Tunisie offre aux touristes une expérience unique mêlant histoire, culture et détente .Située sur la côte est du pays, cette région est réputée pour ses plages de sable doré et son patrimoine historique riche .',
            'Kassérine': 'Le gouvernement de Kassérine en Tunisie offre aux touristes une expérience unique mêlant histoire, culture et détente .Située sur la côte est du pays, cette région est réputée pour ses plages de sable doré et son patrimoine historique riche .',
            'Sidi Bouzid': 'Le gouvernement de Sidi Bouzid en Tunisie offre aux touristes une expérience unique mêlant histoire, culture et détente .Située sur la côte est du pays, cette région est réputée pour ses plages de sable doré et son patrimoine historique riche .',
            'Sousse': 'Le gouvernement de Sousse en Tunisie offre aux touristes une expérience unique mêlant histoire, culture et détente .Située sur la côte est du pays, cette région est réputée pour ses plages de sable doré et son patrimoine historique riche .',
            'Monastir': 'Le gouvernement de Monastir en Tunisie offre aux touristes une expérience unique mêlant histoire, culture et détente .Située sur la côte est du pays, cette région est réputée pour ses plages de sable doré et son patrimoine historique riche .',
            'Mahdia': 'Le gouvernement de Mahdia en Tunisie offre aux touristes une expérience unique mêlant histoire, culture et détente .Située sur la côte est du pays, cette région est réputée pour ses plages de sable doré et son patrimoine historique riche .',
            'Sfax': 'Le gouvernement de Sfax en Tunisie offre aux touristes une expérience unique mêlant histoire, culture et détente .Située sur la côte est du pays, cette région est réputée pour ses plages de sable doré et son patrimoine historique riche .',
            'Gafsa': 'Le gouvernement de Gafsa en Tunisie offre aux touristes une expérience unique mêlant histoire, culture et détente .Située sur la côte est du pays, cette région est réputée pour ses plages de sable doré et son patrimoine historique riche .',
            'Tozeur': 'Le gouvernement de Tozeur en Tunisie offre aux touristes une expérience unique mêlant histoire, culture et détente .Située sur la côte est du pays, cette région est réputée pour ses plages de sable doré et son patrimoine historique riche .',
            'Kébili': 'Le gouvernement de Kébili en Tunisie offre aux touristes une expérience unique mêlant histoire, culture et détente .Située sur la côte est du pays, cette région est réputée pour ses plages de sable doré et son patrimoine historique riche .',
            'Gabès': 'Le gouvernement de Gabès en Tunisie offre aux touristes une expérience unique mêlant histoire, culture et détente .Située sur la côte est du pays, cette région est réputée pour ses plages de sable doré et son patrimoine historique riche .',
            'Médenine': 'Le gouvernement de Médenine en Tunisie offre aux touristes une expérience unique mêlant histoire, culture et détente .Située sur la côte est du pays, cette région est réputée pour ses plages de sable doré et son patrimoine historique riche .',
            'Tataouine': 'Le gouvernement de Tataouine en Tunisie offre aux touristes une expérience unique mêlant histoire, culture et détente .Située sur la côte est du pays, cette région est réputée pour ses plages de sable doré et son patrimoine historique riche .',
    };

    
        return descriptions[regionName] || 'Description introuvable';
    };
    
    const updateDescription = (regionName) => {
        setSelectedRegion(regionName);
        // Vous devez définir la logique pour récupérer la description de la région
        const description = getDescriptionForRegion(regionName); // Cette fonction est hypothétique
        setSelectedRegionDescription(description);
    };

    useEffect(() => {
        const checkMobile = () => {
            const match = window.matchMedia("(max-width: 768px)").matches;
            setIsMobile(match);
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);

        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    if (isMobile) {
        return (
            <div className="flex flex-col mb-40 relative">
                <div className="flex-grow">
                    <div className="text-center py-6">
                        
                            <h1 className="leading-loose text-[rgb(49,77,179)] text-xl"style={{fontFamily: 'Poppins, sans-serif' }}>Tours</h1>   
                            <h1 className="leading-loose text-2xl font-bold" style={{fontFamily: 'Poppins, sans-serif' }}>
                                <span className=" text-[rgb(33,155,207)]">PERSONNALISER  </span><span className=" text-[rgb(49,77,179)]">VOTRE</span></h1>
                            <h1 className=" text-[rgb(49,77,179)] text-2xl font-bold" style={{ fontFamily: 'Poppins, sans-serif' }}> EXPERIENCE</h1>
                       
                    </div>
                    <img src={mapImage} alt="Map" className="w-full object-cover" />
                </div>
                <div className="bg-white  p-4 absolute" style={{ bottom: '-20%', borderTopLeftRadius: '50px', borderTopRightRadius: '50px'}}>
                    <h3 className="font-bold text-xl pb-6 pt-5 pl-8 " style={{ color: 'rgb(45,169,197)', fontFamily: 'Poppins, sans-serif' }}>MAHDIA</h3>
                    <div className="w-25 h-1 bg-[rgb(207,231,255)] mb-4"></div>
                    <p className="text-gray-600 px-2 pb-10" style={{ fontSize: '17px', fontFamily: 'Poppins, sans-serif' }}>
                        Le gouvernement de Mahdia en Tunisie offre aux touristes une expérience unique mêlant histoire, culture et détente.Située sur la cote est du pays, cette région est réputée pour
                        ses plages de sable doré et son patrimoine historique riche.
                    </p>
                
                    <Swiper effect={'coverflow'} grabCursor={true} centeredSlides={true} slidesPerView={3} initialSlide={1} 
                    coverflowEffect={{ rotate: 0, stretch: 0, depth: 100, modifier: 1, slideShadows: false }}>
                        <SwiperSlide><img src={dest1} alt="Corniche" className="w-full h-auto rounded-md" /></SwiperSlide>
                        <SwiperSlide><img src={dest3} alt="Medina" className="w-full h-auto rounded-md" /></SwiperSlide>
                        <SwiperSlide><img src={dest2} alt="El Jem" className="w-full h-auto rounded-md" /></SwiperSlide>
                    </Swiper>
                   
                    <div className="px-1 pb-6 flex justify-center">
                        <button className="mt-4 py-3 text-white rounded-xl shadow-lg w-80 font-medium bg-[rgb(33,162,217)]" style={{ fontFamily: 'Poppins, sans-serif'}}>
                            CHOISIR VOTRE EXPERIENCE
                        </button>
                    </div>
                </div>
            </div>
        );
    } else {
        //web
        return (
            <div>
                <div className="text-center">
                    <h1 className="text-2xl text-[rgb(49,77,179)]" style={{ fontFamily: 'Stroke, sans-serif' }}>
                       Tours
                    </h1>
                    <h1 className="leading-loose text-4xl font-bold" style={{fontFamily: 'Poppins, sans-serif' }}>
                    <span className=" text-[rgb(33,155,207)]">PERSONNALISER  </span><span className=" text-[rgb(49,77,179)]">VOTRE</span></h1>
                    <h1 className=" text-[rgb(49,77,179)] text-4xl font-bold" style={{ fontFamily: 'Poppins, sans-serif' }}> EXPERIENCE</h1>
                       
                </div>
                <section className="container flex justify-center gap-60 py-12 px-6">
                <div >
                <TunisiaMap onRegionClick={updateDescription}/>
                </div>
               
                    <div className="bg-white rounded-3xl p-6 max-w-2xl mt-20 border-4 border-[rgb(197,221,246)]">
                        <h2 className="text-2xl font-bold mb-4 text-[rgb(45,169,197)] pl-10 pt-5 relative inline-block">{selectedRegion}</h2>
                        <div className="w-25 h-1 bg-[rgb(207,231,255)] ml-10 mr-5 mb-4"></div>
                        <p className="text-xl mb-6 pl-10 text-[rgb(94,98,130)]" style={{ fontFamily: 'Stroke, sans-serif' }}>
                        {selectedRegionDescription}
                        </p>
                        <button className="bg-[rgb(45,169,197)] text-white text-xs py-2 px-8 rounded-base hover:bg-[rgb(45,169,197)] transition duration-300 ml-10 mb-10"
                        style={{ boxShadow: '0 4px 6px -1px rgba(45, 169, 197, 0.5)' }}>
                            Choisir votre experience
                        </button>
                       
                    <Swiper
                         centeredSlides={true}
                         slidesPerView={3}
                         loop={false}
                         spaceBetween={10}
                         initialSlide={1}  
                         coverflowEffect={{
                         rotate: 0,
                         stretch: 100,
                         depth: 100,
                         modifier: 1,
                         slideShadows: false,
                        }}
                         effect="coverflow"
                         className="mySwiper"
                        >
                        <SwiperSlide><img src={destination1} alt="Corniche" className="rounded-lg" /></SwiperSlide>
                        <SwiperSlide><img src={destination2} alt="El Jem" className="rounded-lg" /></SwiperSlide>
                       <SwiperSlide><img src={destination3} alt="Medina" className="rounded-lg" /></SwiperSlide>
                    </Swiper>

                    </div>
                </section>
                <div className="flex justify-start mt-[-250px]">
                  <img src={f} alt="flèche" className="w-auto" />
                </div>
            </div>
        );
    }
};

export default PopularDestination;
