import React, { useState } from 'react';
import no_reservation from '../../../assets/Images/Profile/no_reservation.png';
import CustomTours from './CustomTours'; 

const ReservationHistory = () => {
  const [activeTab, setActiveTab] = useState('Réservations des tours standars');

  return (
    <div>
      <div className="flex justify-start items-center lg:rounded-2xl ml-12 mr-12 lg:ml-0 lg:mr-0 lg:shadow-xl shadow-lg shadow-gray-300 pb-4 mb-8 lg:space-x-80 space-x-20 lg:h-20 h-10">
          <div
            className={`lg:text-lg text-[10px] font-semibold text-black relative lg:pl-6 pl-2 lg:w-[210px] w-[60px] cursor-pointer`}
            onClick={() => setActiveTab('Réservations des tours standars')}
          >
            Réservations des tours standars
            {activeTab === 'Réservations des tours standars' && (
              <div className="absolute lg:left-6 right-0 lg:bottom-[-20px] bottom-[-6px] h-[4px] bg-[rgb(45,169,197)] w-full"></div>
            )}
          </div>

          <div className="flex items-center space-x-4">
            <div className="lg:h-12 h-6 border-l-2 border-gray-300 bottom-0"></div>
            <div
              className={`lg:text-lg text-[10px] font-semibold text-black relative cursor-pointer `}
              onClick={() => setActiveTab('Réservations des tours personnalisés')}
            >
              Réservations des tours personnalisés
              {activeTab === 'Réservations des tours personnalisés' && (
                <div className="absolute left-0 right-0 lg:bottom-[-35px] bottom-[-14px] h-[4px] bg-[rgb(45,169,197)] "></div>
              )}
            </div>
          </div>
        </div>

      {activeTab === 'Réservations des tours standars' ? (
        <div className="flex flex-col items-center justify-center ">
          <img src={no_reservation} alt="Historique des réservations" className="mb-8 w-60 h-60 lg:w-auto lg:h-auto" />
          <p className="lg:text-2xl text-xs w-60 lg:w-full mb-10 lg:mb-0 text-center text-black">Pas encore réservé ? Planifiez votre prochaine expérience dès maintenant !</p>
        </div>
      ) : (
        <CustomTours />
      )}
    </div>
  );
};

export default ReservationHistory;
