import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAcceptedTours, fetchToursByType, fetchToursByFilters, addFavoriteTour } from '../../../features/tour/tourSlice';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import star from '../../../assets/Images/Tour/star.png';
import heartEmpty from '../../../assets/Images/Tour/heart.png';
import heartFilled from '../../../assets/Images/Tour/heartFilled.png';
import calendarIcon from '../../../assets/Images/Tour/calendarIcon.png';
import SearchBar from './SearchBar';

const TourCard = () => {
  const dispatch = useDispatch();
  const tours = useSelector((state) => state.tours.tours);
  const tourStatus = useSelector((state) => state.tours.status);
  const error = useSelector((state) => state.tours.error);
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedDate, setSelectedDate] = useState(null);
  const [priceRange, setPriceRange] = useState({ min: 50, max: 500 });
  const [filter, setFilter] = useState('Tout');
  const [type, setType] = useState('');
  const [location, setLocation] = useState('');

  // Retrieve the userId from local storage
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    if (tourStatus === 'idle') {
      dispatch(fetchAcceptedTours());
    }
  }, [tourStatus, dispatch]);

  const handleSearch = (type) => {
    if (type && type !== 'Tout') {
      dispatch(fetchToursByType(type));
    } else {
      dispatch(fetchAcceptedTours());
    }
  };

  if (tourStatus === 'loading') return <div>Loading...</div>;
  if (tourStatus === 'failed') return <div>Error: {error}</div>;

  const filteredTours = filter === 'Tout' ? tours : tours.filter(tour => tour.title === filter);

  const itemsPerPage = 6;
  const totalPages = Math.ceil(filteredTours.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;

  const handleSearchCard = () => {
    const filters = {
      date: selectedDate ? selectedDate.toISOString() : '',
      type,
      title: location,
      minPrice: priceRange.min,
      maxPrice: priceRange.max,
    };
    dispatch(fetchToursByFilters(filters));
  };

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const handleCardClick = (tour) => {
    const tourData = {
      title: tour.title,
      imageSrc: tour.imageSrc,
      price: tour.price,
      description: tour.description,
      rating: tour.rating,
      heureDepart: tour.heureDepart,
      heureArrivee: tour.heureArrivee,
      destinations: tour.destinations,
      nonInclus: tour.nonInclus,
      inclus: tour.inclus,
      dressCode: tour.dressCode,
      galerie: tour.galerie
    };

    console.log('Storing tour data:', tourData);
    sessionStorage.setItem('tourData', JSON.stringify(tourData));
    navigate('/TourDetailsPage');
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const clearSelectedDate = () => {
    setSelectedDate(null);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      clearSelectedDate();
    }
  };

  const CustomDatePickerInput = ({ value, onClick }) => (
    <div className="relative">
      <input
        type="text"
        value={value}
        onClick={onClick}
        placeholder="Quand?"
        className="mt-2 mb-2 px-2 py-3 border border-none rounded-md text-sm lg:text-xl lg:placeholder-gray-400 lg:py-8 h-[48px] w-[200px] lg:w-[280px]"
        onKeyDown={handleKeyDown}
      />
      <img
        src={calendarIcon}
        alt="calendar"
        className="absolute top-1/2 right-1 transform -translate-y-1/2 w-auto h-auto cursor-pointer lg:w-5 lg:h-5"
        onClick={onClick}
      />
    </div>
  );

  const currentTourData = filteredTours.slice(startIndex, endIndex);

  return (
    <div>
      <SearchBar 
        onFilterChange={(title) => {
          setFilter(title);
        }}
        onSearchByType={(type) => {
          handleSearch(type);
        }}
      />
      <div className="p-8 flex flex-col lg:flex-row justify-between lg:ml-40">
        <div className=" ">
          <h2 className=" text-2xl font-extrabold text-center mb-10 lg:text-4xl" style={{ fontFamily: 'Poppins, sans-serif' }}>
            <span className="text-[rgb(0,35,161)]">Nos tours</span> <span className="text-[rgb(45,169,197)]">Standards</span>
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-2 gap-2 lg:gap-10">
            {currentTourData.length > 0 ? (
              currentTourData.map((tour, index) => (
                <Card 
                  key={index}
                  tour={tour}
                  handleClick={() => handleCardClick(tour)}
                  userId={userId} // Pass userId to Card component
                />
              ))
            ) : (
              <div className="text-center col-span-2">Désolé, pas de tours de ce type.</div>
            )}
          </div>
          <div className="flex justify-center mt-8">
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                className={`mx-1 px-3 py-1 rounded-md ${
                  currentPage === index + 1 ? 'bg-gray-800 text-white' : 'bg-gray-200 text-gray-800'
                }`}
                onClick={() => goToPage(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>

        <div className="relative mt-16 lg:w-80 w-60 mx-auto lg:mt-3 lg-mr-40"> 
          <div className="bg-gray-100 rounded-lg p-5 mx-auto">
            <div className="absolute top-0 right-0 font-bold bg-[rgb(49,77,179)] text-white py-5 rounded-t-lg w-full">
              <h2 className="text-center lg:text-2xl">Planifiez votre voyage</h2>
            </div>
            <div className="mt-20 flex flex-col items-center">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                customInput={<CustomDatePickerInput />}
                dateFormat="dd/MM/yyyy"
                className="hidden"
              />
              <select
                className="mb-2 px-2 py-3 border border-none rounded-md text-sm text-gray-400 lg:text-xl lg:text-gray-400 h-[48px] w-[200px] lg:w-[280px] lg:h-[64px]"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
              >
                <option value="" disabled>Où?</option>
                <option value="Sousse">Sousse</option>
                <option value="Bizerte">Bizerte</option>
                <option value="Mahdia">Mahdia</option>
              </select>
              <select
                className="mb-2 px-2 py-3 border border-none rounded-md text-sm text-gray-400 lg:text-xl lg:text-gray-400 h-[48px] w-[200px] lg:w-[280px] lg:h-[64px]"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option value="" disabled>Sélectionner le type</option>
                <option value="Best-seller">Best-seller</option>
                <option value="Cite">Cite</option>
                <option value="Nature">Nature</option>
                <option value="Saisonniaire">Saisonniaire</option>
              </select>
            </div>
            <div className="flex flex-col">
              <div className="font-bold text-xs lg:text-base pr-9 pt-2 mb-2"> 
                <h2 style={{ letterSpacing: '1px' }}>Filtrer Par Prix</h2>
              </div>
              <style>
                {`
                  .input-range__label-container {
                    display: none !important;}
                  .input-range {
                    width: 94%;
                    margin: 0 auto;
                  }
                `}
              </style>
              <InputRange
                minValue={50}
                maxValue={500}
                formatLabel={value => `${value}Dt`}
                value={priceRange}
                onChange={value => setPriceRange(value)}
              />
              <div className="pr-20 mt-2 text-gray-400 lg:text-gray-600 ">
                <p className="text-xs lg:text-base">Prix: {priceRange.min}Dt - {priceRange.max}Dt</p>
              </div>
            </div>
            <div className="flex justify-center mt-5 lg:mb-5">
              <button className="bg-[rgb(49,77,179)] rounded-lg text-white px-4 py-3 text-xs lg:text-xl lg:py-4 lg:px-7 font-semibold"
              onClick={handleSearchCard}>
                Trouver maintenant
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Card = ({ tour, handleClick, userId }) => {
  const [isLiked, setIsLiked] = useState(tour.favorie ? tour.favorie.includes(userId) : false);
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();

  const toggleLike = async (e) => {
    e.stopPropagation();
    if (!userId) {
      alert('You need to be authenticated to add favorites');
      return;
    }
  
    setIsLiked(!isLiked);
    dispatch(addFavoriteTour({ tourId: tour._id, userId }));
  };
  
  return (
    <div onClick={handleClick}
      className={`relative lg:flex lg:items-center cursor-pointer ${isHovered ? 'transform scale-105' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="bg-white p-4 rounded-2xl shadow-lg lg:mx-auto ">
        <div className="relative">
          <img src={tour.imageSrc} alt={tour.title} className="w-full h-full lg:w-[300px] lg:h-[300px] object-cover rounded-2xl" />
          <img 
            src={isLiked ? heartFilled : heartEmpty} 
            alt="heart" 
            className="h-auto w-auto lg:h-6 lg:w-6 absolute top-2 right-2 cursor-pointer rounded-full bg-gray-300 py-1 px-1" 
            onClick={toggleLike}
          />
        </div>
        <h3 className="text-base font-bold mt-1 lg:text-3xl lg:mt-3">{tour.title}</h3>
        <div className="flex items-center mt-1 lg:mt-2">
          <p className="text-xs lg:text-base font-semibold">{tour.price} Dt</p>
          <div className="ml-1 mr-1">
            <img src={star} alt="star" className="h-auto w-auto" />
          </div>
          <p className="text-xs lg:text-base font-semibold">{tour.rating.toFixed(1)}</p>
        </div>
      </div>
    </div>
  );
};

export default TourCard;
