import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createContact } from '../../../features/contact us/contactSlice';
import face from '../../../assets/Images/ContactUs/facebook.png';
import insta from '../../../assets/Images/ContactUs/instagram.png';
import phone from '../../../assets/Images/ContactUs/phone.png';
import email from '../../../assets/Images/ContactUs/email.png';

const ContactForm = () => {
  const dispatch = useDispatch();
  const contactStatus = useSelector((state) => state.contact.status);
  const contactError = useSelector((state) => state.contact.error);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(createContact(formData));
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-white space-x-10 ">
      <div className="p-6 mb-16">
        <h2 className="text-2xl font-bold mb-8 ">Parlez avec nous</h2>
        <p className="mb-8 w-[360px] text-[rgb(1,19,52)] text-[17px]">
          Des questions, des commentaires ou des suggestions ? Remplissez simplement le formulaire et nous vous contacterons sous peu.
        </p>
        <div className="mb-4">
          <p className="flex items-center mb-8"><img src={phone} alt="phone icon"/><i className="fas fa-phone-alt mr-2"></i> +216 22 222 224</p>
          <p className="flex items-center mb-8"><img src={email} alt="email icon"/><i className="fas fa-envelope mr-2"></i> guidee@gmail.com</p>
          <p className="flex items-center mb-8"><img src={insta} alt="instagram icon"/><i className="fab fa-instagram mr-2"></i> Instagram</p>
          <p className="flex items-center mb-8"><img src={face} alt="facebook icon"/><i className="fab fa-facebook mr-2"></i> Facebook</p>
        </div>
      </div>
      <div className="w-[600px] p-6 bg-white shadow-2xl rounded-lg">
        <h2 className="text-2xl font-bold mb-12">Formulaire de contact</h2>
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-4">   
            <div className="relative">
              <input
                type="text"
                id="firstName"
                name="firstName"
                className="w-full px-3 py-[10px] border border-gray-700 rounded-[4px] placeholder-gray-400 text-[14px]"
                required
                placeholder="Lorem"
                value={formData.firstName}
                onChange={handleChange}
              />
              <span className="absolute top-[-12px] left-2 bg-white px-1 text-sm text-[rgb(28,27,31)] font-normal">Prénom</span>
            </div>
            <div className="relative">
              <input
                type="text"
                id="lastName"
                name="lastName"
                className="w-full px-3 py-[10px] border border-gray-700 rounded-[4px] placeholder-gray-400 text-[14px]"
                required
                placeholder="Ipsum"
                value={formData.lastName}
                onChange={handleChange}
              />
              <span className="absolute top-[-12px] left-2 bg-white px-1 text-sm text-[rgb(28,27,31)] font-normal">Nom</span>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="relative">
              <input
                type="email"
                id="email"
                name="email"
                className="w-full px-3 py-[10px] border border-gray-700 rounded-[4px] placeholder-gray-400 text-[14px]"
                required
                placeholder="exemple@gmail.com"
                value={formData.email}
                onChange={handleChange}
              />
              <span className="absolute top-[-12px] left-2 bg-white px-1 text-sm text-[rgb(28,27,31)] font-normal">Adresse email</span>
            </div>
            <div className="relative">
              <input
                type="tel"
                id="phone"
                name="phone"
                className="w-full px-3 py-[10px] border border-gray-700 rounded-[4px] placeholder-gray-400 text-[14px]"
                required
                placeholder="+216 22 222 222"
                value={formData.phone}
                onChange={handleChange}
              />
              <span className="absolute top-[-12px] left-2 bg-white px-1 text-sm text-[rgb(28,27,31)] font-normal">Numéro de téléphone</span>
            </div>
          </div>
          <div className="relative">
            <textarea
              required
              className="w-full px-3 border border-gray-700 rounded-[4px] placeholder-gray-400 text-[14px] py-[10px]"
              placeholder="Écrire un message"
              rows="6"
              name="message"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
            <span className="absolute top-[-12px] left-2 bg-white px-1 text-sm text-[rgb(28,27,31)] font-normal ">Message</span>
          </div>
          <div>
            <button
              type="submit"
              className="w-full bg-[rgb(45,169,197)] text-white py-3 px-4 rounded-[4px] text-xs mb-12"
              disabled={contactStatus === 'loading'}
            >
              {contactStatus === 'loading' ? 'Envoi en cours...' : 'Envoyer'}
            </button>
            {contactStatus === 'failed' && (
              <p className="text-red-500">Erreur : {contactError}</p>
            )}
            {contactStatus === 'succeeded' && (
              <p className="text-green-500">Message envoyé avec succès!</p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
