import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import searchicon from '../../../assets/Images/Home/li_search.png';

const buttonTour = [
  { id: 'button-1', label: 'Tout' },
  { id: 'button-2', label: 'Sousse' },
  { id: 'button-3', label: 'Mahdia' },
  { id: 'button-4', label: 'Djerba' },
  { id: 'button-5', label: 'Tabarka' },
  { id: 'button-6', label: 'Bizerte' },
  { id: 'button-7', label: 'Sud Tunisien' }
];

const widthOfOneItem = 100; 

const SearchBar = ({ onFilterChange, onSearchByType }) => {
  const containerRef = useRef(null);
  const [maxDragX, setMaxDragX] = useState(0);
  const [selectedButton, setSelectedButton] = useState('Tout'); 
  const [searchType, setSearchType] = useState('');

  const isLargeScreen = () => window.innerWidth >= 1024;

  useEffect(() => {
    const calculateMaxDrag = () => {
      const buttonMarginRight = 16;
      const containerPadding = 90;
      const totalButtonsWidth = buttonTour.length * (widthOfOneItem + buttonMarginRight) - buttonMarginRight;
      const containerWidth = containerRef.current ? containerRef.current.offsetWidth : 0;
      const maxDrag = containerWidth - totalButtonsWidth - containerPadding;
      setMaxDragX(Math.min(maxDrag, 0));
    };

    calculateMaxDrag();
    window.addEventListener('resize', calculateMaxDrag);

    return () => window.removeEventListener('resize', calculateMaxDrag);
  }, []);

  const handleButtonClick = (label) => {
    setSelectedButton(label);
    onFilterChange(label);
  };

  const handleSearchClick = () => {
    onSearchByType(searchType);
  };

  return (
    <div>
      {!isLargeScreen() && (
        <div className="flex items-center bg-white rounded-2xl border-2 border-gray-200 w-60 mx-auto mb-4">
          <input
            className="rounded-l-full w-48 py-3 px-4 text-black focus:outline-none placeholder-gray-700 font-semibold text-sm"
            id="search"
            type="text"
            placeholder="Rechercher..."
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
          />
          <button className="rounded-full text-black hover:bg-gray-100 focus:outline-none w-10 h-10 flex items-center justify-center" onClick={handleSearchClick}>
            <img src={searchicon} className="w-4 h-4" alt="Search" />
          </button>
        </div>
      )}
      <div className="col-span-full md:col-span-2 lg:col-span-4">
        <div ref={containerRef} className="overflow-hidden mt-4 mb-8">
          {isLargeScreen() ? (
            <div className='flex items-center justify-center '>
              <div className="flex cursor-grab select-none lg:mt-10">
                {buttonTour.map((button, index) => (
                  <div
                    key={button.id}
                    className={`flex-none ${index === 0 ? 'ml-16' : 'ml-2'}`}
                    style={{ minWidth: '80px' }}
                  >
                    <button
                      className={`py-2 px-4 rounded-full text-sm border border-gray-300 ${selectedButton === button.label ? 'bg-[rgb(0,35,161)] text-white' : 'bg-[rgb(33,155,207)] hover:bg-[rgb(0,35,161)] text-white'}`}
                      onClick={() => handleButtonClick(button.label)}
                    >
                      {button.label}
                    </button>
                  </div>
                ))}
              </div>
              <div className='mt-12 ml-40'>
                <div className="flex items-center bg-white rounded-none border-b-2 border-gray-300 w-80 mx-auto mb-4">
                  <input
                    className="rounded-none w-64 py-2 px-2 text-black focus:outline-none placeholder-gray-400 text-base border-none"
                    id="search"
                    type="text"
                    placeholder="Rechercher par type..."
                    value={searchType}
                    onChange={(e) => setSearchType(e.target.value)}
                  />
                  <div>
                    <button className="rounded-full text-black hover:bg-gray-100 focus:outline-none w-10 h-10 flex items-center justify-center" onClick={handleSearchClick}>
                      <img src={searchicon} className="w-4 h-4" alt="Search" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <motion.div
              drag="x"
              dragConstraints={{ right: 0, left: maxDragX }}
              className="flex cursor-grab select-none"
            >
              {buttonTour.map((button, index) => (
                <motion.div
                  key={button.id}
                  className={`flex-none ${index === 0 ? 'ml-16' : 'ml-2'}`}
                  style={{ minWidth: '80px' }}
                >
                  <button
                    className={`py-2 px-6 rounded-full text-xs ${selectedButton === button.label ? 'bg-[rgb(0,35,161)] text-white' : 'bg-[rgb(33,155,207)] hover:bg-[rgb(0,35,161)] text-white'}`}
                    onClick={() => handleButtonClick(button.label)}
                  >
                    {button.label}
                  </button>
                </motion.div>
              ))}
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
