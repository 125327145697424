// YourComponentFile.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchAcceptedPersonalizedTours } from '../../../../features/personalizedTour/personalizedTourSlice';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-input-range/lib/css/index.css';
import heartEmpty from '../../../../assets/Images/Tour/heart.png';
import heartFilled from '../../../../assets/Images/Tour/heartFilled.png';

const PersonalizedTourCard = () => {
  const dispatch = useDispatch();
  const { tours, status, error } = useSelector((state) => state.personalizedTours);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8; // Number of elements per page

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchAcceptedPersonalizedTours());
    }
  }, [status, dispatch]);

  useEffect(() => {
    console.log("Tours:", tours); // Ajoutez cette ligne pour vérifier les données des tours
  }, [tours]);

  // Calculation of the total number of pages
  const totalPages = Math.ceil(tours.length / itemsPerPage);
  // Calculating the starting and ending index for the elements to display on the 'current page'
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;
  // Extracting data to display on the current page using Array.slice()
  const currentTourData = tours.slice(startIndex, endIndex);

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="p-8 flex flex-col lg:flex-row justify-between lg:ml-40 mt-10">
      <div>
        <h2 className="text-2xl font-extrabold text-center mb-10 lg:hidden" style={{ fontFamily: 'Poppins, sans-serif' }}>
          <span className="text-[rgb(0,35,161)]">Personnaliser </span>
          <span className="text-[rgb(45,169,197)]">Votre Tour</span>
        </h2>
        {status === 'loading' && <p>Loading...</p>}
        {status === 'failed' && <p>Error: {error}</p>}
        {status === 'succeeded' && (
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 lg:gap-10">
            {currentTourData.map((tour, index) => (
              <Link
                key={index}
                to={tour.hasSubCategories ? `/PersonalizedTourSubCategory/${tour._id}` : `/PersonalizedTourChoices/${tour._id}`}
              >
                <Card imageSrc={tour.imageSrc} title={tour.name} />
              </Link>
            ))}
          </div>
        )}
        <div className="flex justify-center mt-8">
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              className={`mx-1 px-3 py-1 rounded-md ${currentPage === index + 1 ? 'bg-gray-800 text-white' : 'bg-gray-200 text-gray-800'}`}
              onClick={() => goToPage(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

const Card = ({ imageSrc, title }) => {
  const [isLiked, setIsLiked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const toggleLike = () => {
    setIsLiked(!isLiked);
  };

  return (
    <div
      className={`relative lg:flex lg:items-center cursor-pointer overflow-hidden rounded-2xl ${isHovered ? 'transform scale-100' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ transition: 'transform 0.3s ease' }}
    >
      <div className="bg-white p-4 rounded-2xl shadow-lg lg:mx-auto">
        <div className="relative transition-transform duration-300 ease-in-out" style={{ transform: isHovered ? 'translateY(5%)' : 'translateY(0)' }}>
          {imageSrc ? (
            <img src={imageSrc} alt={title} className="w-full h-auto object-cover rounded-2xl" />
          ) : (
            <div className="w-full h-48 bg-gray-200 rounded-2xl flex items-center justify-center">
              <span className="text-gray-500">No Image</span>
            </div>
          )}
          <img
            src={isLiked ? heartFilled : heartEmpty}
            alt="heart"
            className="h-auto w-auto lg:h-6 lg:w-6 absolute top-2 right-2 cursor-pointer rounded-full bg-gray-300 py-1 px-1"
            onClick={toggleLike}
          />
          <h3 className={`text-base font-bold lg:text-3xl absolute bottom-0 left-0 pl-8 pb-6 w-full ${isHovered ? 'text-white' : 'hidden'}`} style={{ transition: 'opacity 0.3s ease-in-out' }}>{title}</h3>
        </div>
      </div>
    </div>
  );
};

export default PersonalizedTourCard;
