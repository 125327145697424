import React from 'react';
//import Header2 from '../layout/Header2';
import Header from '../layout/Header';
import Gallery from '../common/DestinationPage/GaleryCity';
import Subscribe from '../layout/Subscribe';
import Footer from '../layout/Footer';

function PopularCities() {   
    return (
      <div>
       <Header page="destination" />
       <Gallery/>
       <Subscribe></Subscribe>
       <Footer></Footer>
      </div>
    );
}

export default PopularCities;