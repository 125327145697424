import React, { useEffect, useState } from 'react';
import plan1_icon from '../../../assets/Images/TourPlan/Plan1.png';
import plan2_icon from '../../../assets/Images/TourPlan/Plan2.png';
import plan3_icon from '../../../assets/Images/TourPlan/Plan3.png';

const icons = [plan1_icon, plan2_icon, plan3_icon]; 

const Plan = () => {
  const [tourData, setTourData] = useState({ destinations: [] });

  useEffect(() => {
    const storedData = sessionStorage.getItem('tourData');
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      console.log("Parsed Data from Storage:", parsedData);
      setTourData(parsedData);
    }
  }, []);

  return (
    <div className="lg:w-400 lg:mb-10">
      <div className="flex items-center">
        <h1 className="pt-20 pb-3 lg:pb-7 mr-4 text-2xl lg:text-4xl font-bold lg:text-2xl mt-0 mb-0 lg:pt-40">Votre Plan</h1>
      </div>
      {tourData.destinations.map((destination, index) => (
        <div key={index} className="flex flex-col lg:mt-10">
          <div className="flex items-center">
            <img className="lg:mr-5 mr-2 w-7 h-7 lg:w-auto lg:h-auto" src={icons[index % icons.length]} alt="Icône du plan"></img>
            <h1 className="lg:text-xl lg:font-bold lg:pt-2 text-sm font-semibold text-[rgb(24,30,75)]" style={{ fontFamily: 'Poppins, sans-serif' }}>
              Jour {index + 1} : {destination.titre}
            </h1>
          </div>
          <div className="flex flex-col lg:ml-10 lg:pl-6 pl-9 text-[rgb(24,30,75)]" style={{ fontFamily: 'Poppins, sans-serif' }}>
            <p className="lg:text-base lg:pt-2 text-xs pb-2">&#8226; Matin : {destination.planDetails ? destination.planDetails[0] : 'Activité non spécifiée'}</p>
            <p className="lg:text-base lg:pt-2 text-xs pb-2">&#8226; Après-midi : {destination.planDetails ? destination.planDetails[1] : 'Activité non spécifiée'}</p>
            <p className="lg:text-base lg:pt-2 text-xs pb-2">&#8226; Soirée : {destination.planDetails ? destination.planDetails[2] : 'Activité non spécifiée'}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Plan;
