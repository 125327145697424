import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { forgetPasswordThunk } from '../../features/user/userSlice';
import Account_bg from '../../assets/Images/Account/Account_bg.png';
import guidee_white_logo from '../../assets/Images/Account/guidee logo white.png';
import guideeLogo from '../../assets/Images/Account/guidee logo.png';
import return_icon from '../../assets/Images/Account/return_icon.png';
import mobileBackground from '../../assets/Images/Account/mobile_bg.png';
import { useNavigate, Link } from 'react-router-dom'; 

const ForgetPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const isMobile = window.innerWidth < 768;

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true); 
        try {
            const actionResult = await dispatch(forgetPasswordThunk({ email }));
            if (forgetPasswordThunk.fulfilled.match(actionResult)) {
                window.alert('Un e-mail de réinitialisation de mot de passe a été envoyé à votre adresse e-mail. Veuillez vérifier votre boîte de réception et suivre les instructions pour réinitialiser votre mot de passe.');
                setTimeout(() => {
                    setLoading(false); 
                }, 2000); 
            } else {
                throw new Error(actionResult.payload || 'Erreur inconnue lors de l’envoi du courriel de réinitialisation');
            }
        } catch (error) {
            window.alert('Erreur : ' + error.message);
            console.error('Error sending password reset email:', error);
            setLoading(false);
        }
    };

    if (isMobile) {
        // Mobile version
        return (
          <div className="min-h-screen flex flex-col items-center justify-center bg-cover bg-center" 
               style={{ backgroundImage: `url(${mobileBackground})` }}>
                {loading && (
              <div className="absolute z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50">
                <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white"></div>
              </div>
            )}
            <div className="pb-20 pt-20">
              <img src={guideeLogo} alt="Guidee Registration Logo" />
            </div>
            <div className="w-full">
        <form onSubmit={handleSubmit} className="bg-gray-700/40 backdrop-blur-[2px] rounded-t-[50px] px-16 pt-12 pb-8 min-h-screen" >
           <p className="flex justify-center text-xl font-bold pb-12 text-white ">Réinitialiser mot de passe</p>
           <div className="mb-6 flex items-center">
                    <Link to="/login" className="flex items-center text-[rgb(45,169,197)]">
                    <img src={return_icon} className="mr-2" alt="return_icon"/>
                    <span className="text-[10px] text-white" style={{ fontFamily: 'Poppins, sans-serif'}}>
                     Retour à la connexion
                    </span>
                    </Link>
                    </div>
          <div className="mb-4">
          <label className="text-xs text-white">Adresse e-mail</label>
          <input
                  className="shadow appearance-none border rounded w-full h-9 py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder-gray-400 text-xs" required
                  id="email"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="exemple@gmail.com"/>
            </div>
            <div className="flex items-center">
              <button className="bg-[rgb(49,77,179)] w-full text-xs text-white font-semibold py-3 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
              Envoyer
              </button>
            </div>
          </form>
          </div>
          </div>
        );
      } else {

    return (
        <div className="flex justify-center items-center h-screen">
            {loading && (
                <div className="absolute z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white"></div>
                </div>
            )}
            <div className="w-1/2 bg-white justify-center ml-20 pl-20"> 
                <img src={guidee_white_logo} alt="Guidee Logo" className="pb-12 pt-20 w-auto"></img> 
                <div className="mb-6 flex items-center">
                  <span>
                    <Link to="/login" className="flex items-center text-[rgb(45,169,197)]">
                     <img src={return_icon} className="mr-2" alt="return_icon"/>
                     <span className="text-[14px] text-black" style={{ fontFamily: 'Poppins, sans-serif' }}>Retour à la connexion</span>
                    </Link>
                  </span>
                </div>
                <h2 className="text-4xl font-bold pb-8" style={{ fontFamily: 'Poppins, sans-serif' }}>Mot De Passe Oublié ?</h2>
                <p className="text-base pb-12 text-[rgb(67,97,67)] " style={{ fontFamily: 'Poppins, sans-serif' }}>
                    Pas de souci ! Entrez votre email pour récupérer votre mot de passe.
                </p>
                <form onSubmit={handleSubmit} className="w-full max-w-md"> 
                    <div className="flex flex-wrap -mx-3 mb-8">
                        <div className="w-full px-3">
                            <div className="relative">
                                <input type="email" id="email" name="email" className="block w-[500px] px-3 py-[14px] border border-gray-700 rounded-[4px] focus:border-blue-500" required placeholder="exemple@gmail.com"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <span className="absolute top-[-12px] left-2 bg-white px-1 text-base text-[rgb(28,27,31)] font-normal">Adresse email</span>
                            </div>
                        </div>
                    </div>
                    <button type="submit" className="block w-[500px] bg-[rgb(0,35,161)] text-white px-4 py-3 rounded-[4px] mb-12 font-normal">Envoyer</button>
                </form>
            </div>
            <div className="w-1/2">
                <img src={Account_bg} alt="Background" className="w-full h-auto" />
            </div>
        </div>
    );
}
};

export default ForgetPassword;
