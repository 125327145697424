import React from 'react';
// Importation des images pour le web
import ellipseWeb from '../../../assets/Images/Home/How_it_works1.png';
import ellipse2Web from '../../../assets/Images/Home/How_it_works2.png';
import ellipse3Web from '../../../assets/Images/Home/How_it_works3.png';

import flecheWeb from '../../../assets/Images/Home/pngegg.png';
import f2Web from '../../../assets/Images/Home/pngeggrand.png';
import fleche from '../../../assets/Images/Home/fleche HowItWorks.png';

// Importation des images pour mobile
import f1 from '../../../assets/Images/Home/f1.png';
import f2 from '../../../assets/Images/Home/f2.png';
import f3 from '../../../assets/Images/Home/f3.png';

const CommentFonctionne = () => {
  return (
    <div className="mt-20">
      <div className="text-center p-5 md:pl-5 md:pr-5 md:pb-5 md:pt-10">
        {/* Responsive titles for mobile and web */}
        <h1 className="md:block text-base md:text-4xl lg:text-3xl leading-loose lg:leading-loose pt-5 text-[rgb(49,77,179)]" >
          COMMENT ÇA FONCTIONNE
        </h1>
        <span className="text-xl font-bold md:text-3xl lg:text-4xl text-[rgb(33,155,207)]">
          COMMENCER?  </span> 
        <span className="text-xl font-bold md:text-3xl lg:text-4xl text-[rgb(49,77,179)]">
          C'EST SIMPLE
        </span>
      </div>
      
      {/* Steps section for web */}
      <div className="hidden md:flex items-center justify-center pl-10 pb-8 pt-20 ">

        <div className='flex flex-col'>
           <img src={ellipseWeb} alt="Choisissez votre destination" className="w-20 md:w-40 lg:w-60" />
           <p className='font-extrabold text-sm pt-5'>CHOISISSEZ VOTRE DESTINATION </p>
        </div>
        <div className="flex items-center mx-4 md:mx-8 lg:mx-12">
           <img className="w-10 md:w-20 lg:w-24" src={flecheWeb} alt="Fleche" srcSet={`${flecheWeb} 1x, ${f2Web} 2x`} media="(min-width: 1024px)"></img>
        </div>

        <div className='flex flex-col'>
           <img src={ellipse2Web} alt="Choisissez votre guide" className="w-20 md:w-40 lg:w-60" />
           <p  className='font-extrabold text-sm pt-5 pl-5'>CHOISISSEZ VOTRE GUIDE </p>
        </div>
        <div className="flex items-center mx-4 md:mx-8 lg:mx-12">
            <img className="w-10 md:w-20 lg:w-24" src={flecheWeb} alt="Fleche" srcSet={`${flecheWeb} 1x, ${f2Web} 2x`} media="(min-width: 1024px)"></img>
        </div>

        <div className='flex flex-col'>
          <img src={ellipse3Web} alt="Profitez de votre tour" className="w-20 md:w-40 lg:w-60" />
          <p  className='font-extrabold text-sm pt-5 pl-5'>PROFITEZ DE VOTRE TOUR </p>
        </div>
      </div>
      <div className="hidden md:flex mt-[-80px] ml-[-30px]">
        <img  src={fleche} alt="Fleche-another-section"></img>
      </div>

      {/* Section des étapes adaptées pour le mobile */}
      <div className="md:hidden">
      <div className="flex flex-col items-center pt-3 pl-2 pr-2">
        <img src={ellipseWeb} alt="Icone personnalisable" className="w-50 h-40" />
        <p className='font-extrabold text-xs pt-4'>CHOISISSEZ VOTRE DESTINATION </p>

        <img src={f2} alt="Icone personnalisable" className="w-auto h-auto mt-6 mr-8" />
       
        <img src={ellipse2Web} alt="Icone personnalisablee" className="w-50 h-40" />
        <p  className='font-extrabold text-xs pt-4'>CHOISISSEZ VOTRE GUIDE </p>
       
        <img src={f1} alt="Icone personnalisable2" className="w-auto h-auto mt-6 mr-8" />
        
        <img src={ellipse3Web} alt="Icone personnalisableee" className="w-50 h-40" />
        <p  className='font-extrabold text-xs pt-4'>PROFITEZ DE VOTRE TOUR </p>
      </div>
        <img src={f3} alt="f3" className="w-auto mt-[-120px]" />
      </div>
    </div>
  );
};

export default CommentFonctionne;
