import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import heartEmpty from '../../../../assets/Images/Tour/heart.png';
import heartFilled from '../../../../assets/Images/Tour/heartFilled.png';

const SubCategory = () => {
  const { tourId } = useParams();
  const [subCategories, setSubCategories] = useState([]);
  const [status, setStatus] = useState('idle');
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSubCategories = async () => {
      setStatus('loading');
      try {
        const response = await axios.get(`http://localhost:8000/api/Personalizedtour/${tourId}/sousCategories`);
        console.log(response.data);
        setSubCategories(response.data);
        setStatus('succeeded');
      } catch (err) {
        setError(err.message);
        setStatus('failed');
      }
    };

    fetchSubCategories();
  }, [tourId]);

  return (
    <div className="p-8 flex flex-col lg:flex-row justify-between lg:ml-40 mt-10">
      <div>
        <h2 className="text-2xl font-extrabold text-center mb-10 lg:hidden" style={{ fontFamily: 'Poppins, sans-serif' }}>
          <span className="text-[rgb(0,35,161)]">Sous-catégories </span>
          <span className="text-[rgb(45,169,197)]">de votre Tour</span>
        </h2>
        {status === 'loading' && <p>Loading...</p>}
        {status === 'failed' && <p>Error: {error}</p>}
        {status === 'succeeded' && (
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 lg:gap-10">
            {subCategories.map((subCategory, index) => (
              <Link key={index} to={`/PersonalizedTourChoices/${tourId}/${subCategory._id}`}>
                <Card imageSrc={subCategory.image} title={subCategory.nom} />
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const Card = ({ imageSrc, title }) => {
  const [isLiked, setIsLiked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const toggleLike = () => {
    setIsLiked(!isLiked);
  };

  return (
    <div
      className={`relative lg:flex lg:items-center cursor-pointer overflow-hidden rounded-2xl ${isHovered ? 'transform scale-100' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ transition: 'transform 0.3s ease' }}
    >
      <div className="bg-white p-4 rounded-2xl shadow-lg lg:mx-auto">
        <div className="relative transition-transform duration-300 ease-in-out" style={{ transform: isHovered ? 'translateY(5%)' : 'translateY(0)' }}>
        {imageSrc ? (
            <img src={`http://localhost:8000/${imageSrc}`} alt={title} className="w-full h-auto object-cover rounded-2xl" />
          ) : (
            <div className="w-full h-48 bg-gray-200 rounded-2xl flex items-center justify-center">
              <span className="text-gray-500">No Image</span>
            </div>
          )}
          <img
            src={isLiked ? heartFilled : heartEmpty}
            alt="heart"
            className="h-auto w-auto lg:h-6 lg:w-6 absolute top-2 right-2 cursor-pointer rounded-full bg-gray-300 py-1 px-1"
            onClick={toggleLike}
          />
          <h3 className={`text-base font-bold lg:text-3xl absolute bottom-0 left-0 pl-8 pb-6 w-full ${isHovered ? 'text-white' : 'hidden'}`} style={{ transition: 'opacity 0.3s ease-in-out' }}>{title}</h3>
        </div>
      </div>
    </div>
  );
};

export default SubCategory;
